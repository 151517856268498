/*
 * @Descripttion: 订阅发布模式
 * @Author: zhanghang
 * @Date: 2021-07-29 15:22:32
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-11-22 17:47:16
 */
class Emit {
  constructor() {
    this.events = {}// 订阅的事件
  }
  $on(event, cb, tag) {
    let events = this.events[event] ? this.events[event] : []
    let r = events.filter((d) => d[0] != tag)
    r.push([tag, cb])
    this.events[event] = r
  }
  $emit(event,data) {
    this.events[event].forEach((item) => {
      item[1].call(item[0],data)
    })
  }
  $hasEvent(event,tag){
    return this.events[event]&&this.events[event].findIndex((d) => d[0] === tag)>-1
  }
  $remove(event, tag) {
    let index = this.events[event].findIndex((d) => d[0] === tag)
    index>-1 && this.events[event].splice(index, 1)
  }

}
export default new Emit()