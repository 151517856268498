let flexible = function (domWidth) {
  /* 设计图文档宽度 */
  var docWidth = domWidth || 750;
  var minPx = 320;
  var baseFontSize = 100;

  var doc = window.document,
    docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';

  // var recalc = (function refreshRem() {

  // })();

  var recalc = function () {
    // debugger;
    var clientWidth = docEl.getBoundingClientRect().width;
    /* 
    8.55：小于320px，
    11.2：大于420px
    */
    // docEl.style.fontSize = Math.max(Math.min(20 * (clientWidth / docWidth), 20), 8.55) * 5 + 'px';
    // return refreshRem;
    // 如果比例大于设计稿尺寸不再放大
    if (clientWidth >= docWidth) {
      docEl.style.fontSize = baseFontSize + 'px';
    }
    else if (clientWidth <= minPx) {
      docEl.style.fontSize = baseFontSize * (minPx / docWidth) + 'px';
    }
    else {
      docEl.style.fontSize = baseFontSize * (clientWidth / docWidth) + 'px';
    }
  }

  /* 添加倍屏标识，安卓为1 */
  docEl.setAttribute('data-dpr', window.navigator.appVersion.match(/iphone/gi) ? window.devicePixelRatio : 1);
  if(dsf.client.info.os=='iOS'){
    /* 添加IOS标识 */
    doc.documentElement.classList.add('ios');
    /* IOS8以上给html添加hairline样式，以便特殊处理 */
    if (parseInt(window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)[1], 10) >= 8)
      doc.documentElement.classList.add('hairline');
  }

  if (!doc.addEventListener) return;
  window.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
}

export default flexible;