const x_pi = 3.14159265358979324 * 3000.0 / 180.0;
// 高德、腾讯  转  百度
export function gcj02ToBd09ii(gcj02_point) {
  var baidu_point = { lng: 0, lat: 0 };
  var x = gcj02_point.lng;
  var y = gcj02_point.lat;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
  baidu_point.lng = z * Math.cos(theta) + 0.0065;
  baidu_point.lat = z * Math.sin(theta) + 0.006;
  return baidu_point;
}