export default {
  ctrlCaption: "流程信息",
  props: {
    caption: {
      type: String,
      default() {
        return "wfinfo";
      },
    },
    chooseFlow: {
      type: Object,
      default() {
        return {};
      },
    },
    sWFID: {
      type: String,
      default() {
        return "";
      },
    },
    sObjectID: {
      type: String,
      default() {
        return "";
      },
    },
    sObjectName: {
      type: String,
      default() {
        return "";
      },
    },
    sPID: {
      type: String,
      default() {
        return "";
      },
    },
    sCurLinkID: {
      type: String,
      default() {
        return "";
      },
    },
    sCurNodeID: {
      type: String,
      default() {
        return "";
      },
    },
    sOpinion: {
      type: String,
      default() {
        return "";
      },
    },
    sExInfos: {
      type: String,
      default() {
        return "";
      },
    },
    sLineIDs: {
      type: String,
      default() {
        return "";
      },
    },
    sReceivers: {
      type: String,
      default() {
        return "";
      },
    },
    eventName: {
      type: String,
      default() {
        return "";
      },
    },
    countDown: {
      type: String,
      default() {
        return "3";
      },
    },
    linkData: {
      type: Array,
      default() {
        return [];
      },
    },
    forceHidden: {
      type: Boolean,
      default: true
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("flow-info-meta-data");
      },
    },
  },
  data() {
    return {
      _RequestID: null,
      flowSendOverEvent: "",
      owner: null,
      dialog: null,
      isFristSend: false,
      BackLineID: "",
    };
  },
  watch: {
    chooseFlow(val) {
      const value = {
        wf_id: val.ID,
        wf_name: val.name,
      };
      this.emitValueChange(value);
    },
  },
  computed: {},
  mounted() {
    if (this.$vm && this.$vm.flows) {
      this.$vm.flows.flowInfo = this;
    }
  },
  destroyed() { },
  methods: {
    linkSetChooseFlow() {
      document.querySelector("[rel='linkData'] .ds-button")?.click();
    },
    flowSend(res, targetButton, owner, Objects) {
      const { line, flowSendOverEvent, completed } = targetButton;
      this.dialog = null;
      this.flowSendOverEvent = flowSendOverEvent;
      this.owner = owner;
      // 流程发送按钮
      let sWFID = this.chooseFlow.dsfa_rm_id;
      if (sWFID) {
        const sObjectName = this.$replace(this.sObjectName, this.$vm);
        sWFID = this.$replace(this.sWFID, this.$vm) || sWFID;
        const sCurNodeID = this.$replace(this.sCurNodeID, this.$vm);
        const sPID = this.$replace(this.sPID, this.$vm);
        const sCurLinkID = this.$replace(this.sCurLinkID, this.$vm);
        let sOpinion = this.$replace(this.sOpinion, this.$vm);
        let sLineIDs = this.$replace(this.sLineIDs, this.$vm);
        let sExInfos = this.$replace(this.sExInfos, this.$vm);
        let sReceivers = this.$replace(this.sReceivers, this.$vm);
        sExInfos = !sExInfos ? '' : eval('(' + sExInfos + ')');
        sOpinion = !sOpinion ? '' : eval('(' + sOpinion + ')');
        sLineIDs = !sLineIDs ? '' : eval('(' + sLineIDs + ')');
        // 增加 sExInfos
        sExInfos = sExInfos || Object.assign({}, dsf.removePoint(this.$vm.$viewData), this.$route.query);
        // 开始发送
        let url = "wfr/startSend",
          param = {
            sWFID: sWFID,
            sObjectID: sCurLinkID || res._id,
            sObjectName: sObjectName || this.$vm.$refs[this.eventName]?.value || "",
            sCurNodeID: sCurNodeID || "",
            sExInfos: JSON.stringify(sExInfos),
            sReceivers: sReceivers
          };

        //继续发送
        if (Objects || this.$route.query.sPID || this.queryString.sPID) {
          url = "wfr/send";
          param = {
            sPID: sPID || Objects?.sPID || this.$route.query?.sPID || this.queryString?.sPID,
            sCurLinkID: sCurLinkID || Objects?.sCurLinkID || this.$route.query?.sCurLinkID || this.queryString?.sCurLinkID,
            sExInfos: JSON.stringify(sExInfos),
            sReceivers: sReceivers
          };
          this.isFristSend = false;
        } else {
          this.isFristSend = true;
        }

        if (sOpinion) {
          param.sOpinion = JSON.stringify(sOpinion);
        } else {
          // 发送时有处理意见
          if (this.$vm?.flows?.disposeIdea?.sOpinion) {
            param.sOpinion = JSON.stringify(this.$vm.flows.disposeIdea.sOpinion);
          }
          // 发送时表单意见框有处理意见
          if (this.$vm?.flows?.sOpinion?.Content || this.$vm?.flows?.sOpinion?.Attachments?.length > 0) {
            param.sOpinion = JSON.stringify(this.$vm.flows.sOpinion);
          }
        }
        if (sLineIDs) {
          param.sLineIDs = sLineIDs;
        } else {
          if (line) {
            param.sLineIDs = line.LineID;
          }
        }

        const config = {
          param: param,
          url: url,
        };

        this.$http
          .post(config.url, config.param)
          .then((response) => {
            this.handler(response, false, completed);
          })
          .catch((response) => {
            this.$message("发送异常", false);
            this._RequestID = null;
          })
          .finally(() => {
            // 防止网络请求很快的情况
            setTimeout(() => {
              targetButton.disabled = false;
            }, 200);
          });
      } else {
        this.$message("请先配置流程信息控件的'选择流程'", false);
      }
    },
    handler(response, isShowBack, completed) {
      if (this.dialog) {
        this.dialog.close();
        this.dialog = null;
      }
      if (response.data.state == 20000) {
        let data = response.data.data;
        this._RequestID = data.RequestID;
        if (data.Code == 20000 || data.Code == 20001 || data.Code == 20003 || data.Code == 20004 || data.Code == 20005) {
          // 发送成功 || 发送到汇总节点 || 发送到汇总环节并激活汇总环节 || 办结当前节点 || 办结当前节点，并办结整个流程
          if (this.flowSendOverEvent) {
            this.owner.$dispatch(this.flowSendOverEvent, data);
          } else {
            this.directlySend(data, completed);
          }
        } else if (data.Code == 81000) {
          // 或选择
          this.chooseSendNode(data);
        } else if (data.Code == 82000) {
          // 与选择
          this.chooseSendTree(data, isShowBack);
        } else {
          let msg = data.Message;
          if (data.EsMessage) {
            msg = msg + "." + data.EsMessage;
          }
          this.$message(msg || "未知流程类型发送", false);
        }
      } else {
        let data = response.data;
        this._RequestID = null;
        let msg = data?.data?.Message || data.message;
        if (data.EsMessage) {
          msg = msg + "." + data.EsMessage;
        }
        this.$message(msg || "发送异常", false);
      }
    },
    directlySend(data, completed) {
      let list = [],
        _this = this;
      if (data.Code == 20000) {
        data.Links.forEach((line, index) => {
          if (line.Status == 0 && line.LinkUser) {
            let linkUser = line.LinkUser;
            let type = line.LinkUser.Type;
            if (type == 1) {
              // 直接选人
              list.push(linkUser.UserName);
            } else if (type == 2) {
              let DeptName = linkUser.DeptName;
              // let GlobalDeptName = linkUser.GlobalDeptName.split(",");
              // let DeptName = GlobalDeptName.length >= 3 ? GlobalDeptName[2] : linkUser.DeptName;
              // 部门+角色
              list.push(`${DeptName}-${linkUser.RoleName}`);
            } else if (type == 3) {
              // 岗位
              list.push(linkUser.JobName);
            } else if (type == 4) {
              // 人+关系
              list.push(`${linkUser.UserName}-${linkUser.RelationName}`);
            } else if (type == 5) {
              // 机器人
              list.push(linkUser.RobotName);
            }
          }
        });
        this.$openDialog({
          type: "dialog",
          title: "流程已发送到以下人员：",
          width: "300px",
          height: "auto",
          overflow: "auto",
          content: "SendFlowDialog",
          footerAlign: "center",
          showClose: false,
          params: {
            list: _.uniq(list),
            showCountDown: this.countDown == -1 ? false : !this.$vm?.$dialog,
            countDownTime: this.countDown,
          },
          btns: [
            {
              text: "确定",
              class: `sendFlow ${dsf.global.$isMobile ? "van-dialog__confirm" : ""}`,
              handler: (component) => {
                this.closeOpener();
                return true;
              },
            },
          ],
        });
      } else {
        let msg = "发送成功",
          code = data.Code;
        switch (code) {
        case 20004:
          msg = completed || "文件已办结";
          break;
        case 20005:
          msg = completed || "文件已办结";
          break;
        }
        this.$openDialog({
          type: "dialog",
          title: "提示",
          width: "300px",
          height: "120px",
          content: "SendFlowDialog",
          footerAlign: "center",
          showClose: false,
          params: {
            msg: msg,
            showCountDown: this.countDown == -1 ? false : !this.$vm?.$dialog,
            countDownTime: this.countDown,
          },
          btns: [
            {
              text: "确定",
              class: `sendFlow ${dsf.global.$isMobile ? "van-dialog__confirm" : ""}`,
              handler: (component) => {
                this.closeOpener();
                return true;
              },
            },
          ],
        });
      }
    },
    getSendBackLink(args, targetButton) {
      let param = {
        sPID: args[0] || null,
        sCurLinkID: args[1] || null,
      };
      this.$http
        .post("wfr/getSendBackLink", param)
        .then((res) => {
          if (res.data.success && res.data.data && res.data.data.BackLinks) {
            let BackLinks = res.data.data.BackLinks;
            if (BackLinks.length == 1) {
              this.sendBack(args, BackLinks[0].ID);
            } else {
              this.chooseBackNode(BackLinks, args);
            }
          } else {
            this.$message(res.data.data.Message || "退回异常", false);
          }
        })
        .catch((res) => {
          this.$message("退回异常", false);
        })
        .finally(() => {
          // dsf.layer.close(loadingIndex);
          // 防止网络请求很快的情况
          setTimeout(() => {
            targetButton.disabled = false;
          }, 200);
        });
    },
    validateInput (input) {
      if (input && input.length >= 1000) {
        return '退回意见长度不能超过1000';
      }
      return true;
    },
    sendBack(args, id) {
      dsf.layer
        .prompt({
          message: "退回原因",
          inputPlaceholder: "请输入退回原因",
          inputType: "textarea",
          inputPattern: /\S/,
          inputErrorMessage: "退回原因不能为空",
          confirmButtonText: "确定",
          inputValidator: this.validateInput()
        })
        .then(({ value }) => {
          if(value && value.length >= 1000) {
            this.$message("退回意见长度不能超过1000", false);
            return false;
          }
          let param = {
            sPID: args[0] || null,
            sCurLinkID: args[1] || null,
            sReason: value || null,
            sBackLinkID: id,
          };
          this.$http
            .post("wfr/sendBack", param)
            .then((res) => {
              if (res.data.success && res.data.data && res.data.data.Code == "21000") {
                this.$message("退回成功", true);
                this.closeOpener();
              } else {
                this.$message(res.data.data.Message || "退回异常", false);
              }
            })
            .catch((res) => {
              console.error(res)
              this.$message("退回异常", false);
            });
        });
    },
    // 选择退回节点
    chooseBackNode(data, args) {
      const chooseNode = dsf.global.$isMobile ? (line)=> {
        this.sendBack([line.PID, args[1]], line.ID)
      } : dsf.noop;
      const hideHeader = dsf.global.$isMobile;
      this.$openDialog({
        type: "dialog",
        title: "请选择要退回的环节：",
        width: "300px",
        height: "auto",
        overflow: "auto",
        footerAlign: "center",
        content: (dsf.global.$isMobile ? "DsfMobile" : "") + "ChooseSendNode",
        params: {
          lines: data,
          chooseNode: chooseNode,
          hideHeader: hideHeader,
          callback: (arr, id) => { this.sendBack([arr[0], args[1]], id) },
          requestID: this._RequestID,
          type: "back",
        },
        btns: [
          // {
          //   text: "确定",
          //   class: `hide ${window.$isMobile ? "van-dialog__confirm" : ""}`,
          //   handler: (component) => {
          //     if (component.validate()) {
          //       const line = component.yes();
          //       this.sendBack(args);
          //       return true;
          //     }
          //     return false;
          //   },
          // },
          {
            class: `close-send-node ${dsf.global.$isMobile ? "van-dialog__cancel" : ""}`,
            text: "取消",
            handler: () => {
              this.dialog = null;
              return true;
            },
          },
        ],
      });
    },
    chooseSendNode(data) {
      this.dialog = this.$openDialog({
        type: "dialog",
        title: "请选择要发送的环节：",
        width: "300px",
        height: "auto",
        overflow: "auto",
        footerAlign: "center",
        content: (dsf.global.$isMobile ? "DsfMobile" : "") + "ChooseSendNode",
        params: {
          lines: data.SendParams.Lines,
          callback: this.handler,
          requestID: this._RequestID,
          type: "next",
        },
        btns: [
          {
            text: "返回",
            class: `${data.SendParams.Lines && data.SendParams.Lines[0] && data.SendParams.Lines[0].SendToLineID ? "" : "hide"}`,
            handler: (component) => {
              let param = {
                sRequestID: this._RequestID,
                sLineID: data.SendParams.Lines && data.SendParams.Lines[0] && data.SendParams.Lines[0].BackLineID,
              };
              this.$http.post("wfr/setLineToBack", param).then((res) => {
                if (res.data.state == 20000) {
                  this.handler(res);
                }
              });
              if (this.dialog) {
                this.dialog.close();
              }
              return true;
            },
          },
          {
            text: "确定",
            class: "hide",
            handler: (component) => {
              if (component.validate()) {
                const line = component.yes();
                let param = {
                  sRequestID: this._RequestID,
                  sLineID: line.LineID,
                };
                this.$http.post("wfr/sendToLine", param).then((res) => {
                  if (res.data.state == 20000) {
                    this.handler(res);
                  } else {
                    this.$message(res.data.Message || "发送异常", false);
                  }
                });
                return true;
              }
              return false;
            },
          },
          {
            class: "close-send-node",
            text: "取消",
            handler: () => {
              this.dialog = null;
              return true;
            },
          },
        ],
      });
    },
    chooseSendTree(data, isShowBack) {
      this.dialog = this.$openDialog({
        title: "请选择办理人",
        width: "900px",
        height: "420px",
        overflow: "hidden",
        content: "ChooseSendTree",
        params: {
          lines: data.SendParams.Lines,
        },
        btns: [
          {
            text: "返回",
            class: `choose-send-node ${isShowBack ? "" : "hide"}`,
            handler: (component) => {
              let param = {
                sRequestID: this._RequestID,
                sLineID: data.SendParams.Lines && data.SendParams.Lines[0] && data.SendParams.Lines[0].SendToLineID,
              };
              this.$http.post("wfr/setLineToBack", param).then((res) => {
                if (res.data.state == 20000) {
                  this.handler(res);
                }
              });
              if (this.dialog) {
                this.dialog.close();
              }
              return true;
            },
          },
          {
            text: "选择模板",
            handler: (component) => {
              let param = {
                sLineIDs: LineID,
              };
              this.$http.post("wfr/getHandlerTemplateList", param).done((res) => {
                if (res.success) {
                  const data = res.data;
                  data.forEach(item => {
                    item.id = item.dsfa_wf_link_handler_template;
                    item.label = item.template_name
                  });
                  this.$openDialog({
                    title: "选择模板",
                    width: "330px",
                    height: "420px",
                    overflow: "hidden",
                    content: "DsfFlowSelectTree",
                    params: {
                      treeData: data,
                      showDel: true,
                      delUrl: "wfr/delHandlerTemplate"
                    },
                    btns:[
                      {
                        text: "确定",
                        handler: (com) => {
                          let nodes = com.$refs.treeForm.getCheckedNodes();
                          nodes = nodes.filter((node) => !node.disabled);
                          let ids = [];
                          for (const node of nodes) {
                            let receivers = node.receiver.split(";");
                            for (const receiver of receivers) {
                              ids.push(...receiver.replace(/.*\:/, "").split(","));
                              const rec = receiver.split(":");
                              const LineID = rec[0];
                              for (const line of  component.linesData) {
                                if (line.LineID === LineID) {
                                  line.checked = rec[1].split(",");
                                }
                              }
                            }
                          }
                          return true;
                        },
                      },
                    ]
                  });
                }
              });
              return false;
            },
          },
          {
            text: "保存模板",
            handler: (component) => {
              if (component.validate()) {
                dsf.layer
                  .prompt({
                    message: "模板名称",
                    inputPlaceholder: "请输入模板名称",
                    inputType: "textarea",
                    inputPattern: /\S/,
                    inputErrorMessage: "模板名称不能为空",
                    confirmButtonText: "确定",
                    inputValidator: (input) => {
                      if (input && input.length > 50) {
                        return '模板名称长度不能超过50';
                      }
                      return true;
                    },
                  })
                  .then(({ value }) => {
                    const result = component.yes();
                    let sReceivers = this.$replace(this.sReceivers, this.$vm);
                    sReceivers = !sReceivers ? '' : eval('(' + sReceivers + ')');
                    let param = {
                      templateName: value,
                      sLineIDs: LineID,
                      sReceivers: sReceivers || result,
                    };
                    this.$http.post("wfr/saveHandlerTemplate", param).done((res) => {
                      if (res.success) {
                        this.$message("保存成功", true);
                      } else {
                        this.$message(res.message, false);
                      }
                    });
                  });
                return false;
              }
              return true;
            },
          },
          {
            text: "确定",
            handler: (component) => {
              if (component.validate()) {
                const result = component.yes();
                let sReceivers = this.$replace(this.sReceivers, this.$vm);
                sReceivers = !sReceivers ? '' : eval('(' + sReceivers + ')');
                let param = {
                  sRequestID: this._RequestID,
                  sReceivers: sReceivers || result,
                };
                this.$http.post("wfr/sendToReceivers", param).then((res) => {
                  if (res.data.state == 20000) {
                    this.handler(res);
                  }
                });
                const closebtn = document.querySelector(".close-send-node");
                closebtn && closebtn.click();
                return true;
              }
              return false;
            },
          },
          {
            text: "取消",
            handler: () => {
              this.dialog = null;
              return true;
            },
          },
        ],
      });
    },
    closeOpener() {
      if (dsf.global.$isMobile) {
        if (window.sendFlowTimer) {
          clearTimeout(window.sendFlowTimer)
          delete window.sendFlowTimer
        }
        this.$router.go(-1);
        return true;
      }
      if (this?.$vm?.$dialog) {
        this.$vm.$dialog?.openerVm?.reloadData?.(true);
        this.$vm.$dialog?.close?.();
      } else if (this?.$vm?.$openerVm) {
        this.$vm.$openerVm?.reloadData?.(true);
        this.$vm?.$closeWindow?.();
      } else if (window?.opener) {
        window?.opener?.reloadData?.(true);
        window.close();
      }
    }
  },
};
