export default {
  topSafeArea: false,
  bottomSafeArea: false,
  topSafeAreaAdapter(el) {
    let els = el?.querySelectorAll?.("[top-safe-area]");
    _.each(els, (it) => {
      let rect = it.getBoundingClientRect();
      let top = rect.top || rect.y;
      let device = dsf.client.info.os.toLowerCase();
      //距离顶部5像素内且高度不为0的认为是需要设置安全距离的元素
      if (top < 5 && rect.height > 0) {
        it.classList.add(device + "-top-safe-area");
      } else {
        it.classList.remove(device + "-top-safe-area");
      }
    });
  }
}