function mergeExecCycleFunctions(vm, fnName, args, promiseFn) {
  let mixins = vm.$options.mixins;
  let fns = [];
  _.each(mixins, (it) => {
    if (dsf.isFunction(it[fnName])) {
      let fn = it[fnName];
      fns.push(fn);
    }
  });
  if (dsf.isFunction(vm.$options[fnName])) {
    let fn = vm.$options[fnName];
    fns.push(fn);
  }
  let index = 0;
  let cb = function (resolve, reject) {
    if (fns[index]) {
      let fn = fns[index];
      if (promiseFn) {
        fn = promiseFn(fns[index]).bind(vm);
      }
      fn.apply(vm, args)
        .then(res => {
          index++;
          cb(resolve, reject);
        })
        .catch((ex) => {
          reject(ex)
        })
    }
    else {
      resolve();
    }
  }
  return new Promise((resolve, reject) => {
    cb(resolve, reject);
  })
}

function postPromiseFn(fn) {
  return function () {
    let _array = Array.prototype.slice.call(arguments);
    return new Promise((resolve, reject) => {
      if (fn) {
        let next = (mark) => {
          if (dsf.isUnDef(mark) || mark === true) {
            resolve();
          } else {
            reject(mark);
          }
        };
        _array = _array || [];
        if (fn) {
          fn && fn.apply(this, [next, ..._array]);
        }
      } else {
        resolve();
      }
    });
  }
}

function loadPromiseFn(fn){
  return function () {
    let _array = Array.prototype.slice.call(arguments);
    let self = this;
    return new Promise((resolve, reject) => {
      if (fn) {
        let next = (mark) => {
          if (mark === false) {
            reject("next返回了false,停止执行");
          } else {
            if (dsf.isError(mark)) {
              reject(mark);
            }
            else {
              resolve(_array);
            }
          }
        };
        fn.apply(self, _array.concat([next]));
      }
      else{
        resolve();
      }
    });
  };
}

function waitViewUpdated(vm) {
  return new Promise(resolve => {
    vm.$nextTick(() => {
      resolve();
    })
  })
}

export {
  mergeExecCycleFunctions,
  postPromiseFn,
  loadPromiseFn,
  waitViewUpdated
}