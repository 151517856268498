
import url from "./url";
import vueHelper from "./vueHelper";

let hasConsole = true; //typeof console === 'object';
let getProto = Object.getPrototypeOf;
let $types = [
  "Boolean",
  "Number",
  "String",
  "Function",
  "Array",
  "Date",
  "RegExp",
  "Object",
  "Error",
  "Symbol",
];
let class2type = {};
let hasOwn = class2type.hasOwnProperty;
let fnToString = hasOwn.toString;
let ObjectFunctionString = fnToString.call(Object);
let toString = class2type.toString;
let _console = window.console;
const debug = url.queryString('__debug');
// if (process.env.NODE_ENV === "production" && !debug) {
//   _console.log = () => { }
//   _console.info = () => { };
//   _console.debug = () => { };
// }
let _setInterval = window.setInterval;
let _clearInterval = window.clearInterval;
window.setInterval = (fn, time) => {
  let timerId = _setInterval(fn, time);
  dsf.warn("启动了setInterval" + timerId);
  return timerId;
};
window.clearInterval = (timerId) => {
  dsf.warn("关闭了setInterval" + timerId);
  _clearInterval(timerId);
};

window.consoleOn = () => {
  hasConsole = true;
};

$types.forEach((elem) => {
  class2type["[object " + elem + "]"] = elem.toLowerCase();
});

let uuidCharts = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");

let $files = [{
  absolutePath: "",
  contentType: "",
  id: "",
  name: "",
  originalFileName: "",
  relativePath: "",
  size: 0,
  suffix: "",
  uploadDate: "",
},];
let hasOwnProperty = Object.prototype.hasOwnProperty;

let sessionTimeoutConfirmOpen = false;
function sessionTimoutHandler() {
  if (!sessionTimeoutConfirmOpen) {
    sessionTimeoutConfirmOpen = true;
    dsf.layer.alert("会话已失效，为了安全请重新登录")
      .then(() => {
        let topWin = dsf.getTopWindow();
        let root = topWin.__multiPageVm__ ? topWin : window;
        let api = dsf.api.getAPI();
        api.loginOut()
          .then((res) => {
            if (dsf.global.$isMobile) {
              root.location.replace(dsf.url.getMobileLogin());
            } else if (res && res.data && res.data.outUrl) {
              root.location.replace(res.data.outUrl);
            } else {
              root.location.replace(dsf.config.setting_public_pc_login_page);
            }
          })
          .catch(() => {
            if (dsf.global.$isMobile) {
              root.location.replace(dsf.url.getMobileLogin());
            } else {
              root.location.replace(dsf.config.setting_public_pc_login_page);
            }
          })
          .finally(() => {
            dsf.cookies.remove("authorization_token");
            dsf.storage.session.remove('authorization_token');
            dsf.cookies.remove("user_name");
            dsf.cookies.remove("isLogin");
          })
      })
      .catch(() => { })
      .finally(() => {
        sessionTimeoutConfirmOpen = false;
      })
  }
}

let topWindow = null;

export default {
  version: "5.0",
  //是否是dsf5.0版
  isDsf5(dsf) {
    let version = "5.0"
    if (!dsf) {
      return false;
    }
    if (typeof (dsf) == 'object') {
      version = dsf?.version || ""
    }
    else if (typeof (dsf) == 'string') {
      version = dsf || ""
    }
    let v = version.split ? version.split(".") : [];
    return v[0] == '5'
  },
  noop: function () { },
  noopPromise: toPromise,
  //获取唯一标识
  uuid: function (len, radix) {
    var chars = uuidCharts,
      uuid = [],
      i;
    radix = radix || chars.length;
    len = len || 16;
    if (len) {
      // Compact form
      for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
    } else {
      // rfc4122, version 4 form
      var r;

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = "";
      uuid[14] = "4";

      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
        }
      }
    }

    return uuid.join("");
  },
  //验证是否不为undefind或者null
  isDef: function (obj) {
    return obj !== undefined && obj != null;
  },
  //验证是否为undefind或者null
  isUnDef: function (obj) {
    return obj === undefined || obj === null;
  },
  //获取对象类型
  type: function (obj) {
    if (obj == null) {
      return obj + "";
    }
    // Support: Android <=2.3 only (functionish RegExp)
    return typeof obj === "object" || typeof obj === "function" ?
      class2type[toString.call(obj)] || "object" :
      typeof obj;
  },
  hasOwn: function (obj, key) {
    return hasOwnProperty.call(obj, key);
  },
  //是否原生支持的方法
  isNative: function (Ctor) {
    return typeof Ctor === "function" && /native code/.test(Ctor.toString());
  },
  //是否多页应用
  isMultiPage() {
    let multiPagePath = dsf.config.setting_public_multipagename;
    if (window.location.pathname.indexOf(multiPagePath) > 0) {
      return true;
    }
    return false;
  },
  //获取dsf5.0支持的最顶级窗口
  getTopWindow: function () {
    if (topWindow) {
      return topWindow;
    }
    let self = window.self;
    let current = self;
    while (current?.parent != current) {
      try {
        let isCrossDomain = false;
        let currUrl = "", parentUrl = "";
        try {
          currUrl = url.parse(current.location.href);
          parentUrl = url.parse(current.parent.location.href);
          if (currUrl.host != parentUrl.host) {
            isCrossDomain = true;
          }
        } catch (ex) {
          this.error(ex);
          isCrossDomain = true;
        }
        if (!isCrossDomain && current.parent && current.parent?.dsf?.version == '5.0') {
          current = current.parent;
        }
        else {
          break;
        }
      }
      catch (ex) {
        this.error(ex);
      }
    }
    topWindow = current;
    return current;
  },
  kebabCase: function (str, joinChar) {
    let hyphenateRE = /\B([A-Z])/g;
    return str.replace(hyphenateRE, joinChar + "$1").toLowerCase();
  },
  camelCase: function (str, joinChar) {
    var camelizeRE = new RegExp("\\" + joinChar + "(\\w)", "g");
    return str.replace(camelizeRE, function (_, c) {
      return c ? c.toUpperCase() : "";
    });
  },
  //验证是否是个数字
  isNumber: function (val) {
    if (this.isUnDef(val) || val === "") {
      return false;
    } else {
      if (dsf.type(val) == 'boolean') {
        return false;
      }
      return !isNaN(val);
    }
  },
  isElementEditable(elm) {
    elm = $(elm);
    if (elm.get(0).tagName == 'INPUT') {
      if ((elm.attr('type') == 'text' || elm.attr('type') == 'password') && !elm.prop('readonly') && !elm.prop('disabled')) {
        return true;
      }
    }
    else if (elm.get(0).tagName == 'TEXTAREA') {
      if (!elm.prop('readonly') && !elm.prop('disabled')) {
        return true;
      }
    }
    else if (elm.prop('contenteditable') && elm.prop('contenteditable') === 'true') {
      return true;
    }
    else {
      //如果元素在可编辑元素内
      let c = elm.closest('[contenteditable=true]');
      if (c.length > 0) {
        return true;
      }
    }
    return false;
  },
  pxToRem(size) {
    let designEl = document.querySelector(".ds-designer");
    let isMobile = document.querySelector(".mobile-app") || (designEl ? designEl.matches(".mobile") : false);
    if (this.type(size) == "string") {
      if (this.isNumber(size)) {
        return parseFloat(size) / 50 + "rem";
      } else if (size.endsWith("px")) {
        return parseFloat(size) / 50 + "rem";
      } else if (size.endsWith("vw")) {
        if (designEl && isMobile) {
          return (375 * (parseFloat(size) / 100)) / 50 + "rem";
        } else {
          return size;
        }
      } else if (size.endsWith("vh")) {
        if (designEl && isMobile) {
          return (667 * (parseFloat(size) / 100)) / 50 + "rem";
        } else {
          return size;
        }
      } else {
        return size;
      }
    } else if (this.type(size) == "number") {
      return size / 50 + "rem";
    } else {
      return size;
    }
  },
  px(v) {
    if (this.isNumber(v)) {
      return v + "px"
    } else {
      return v;
    }
  },
  escapeFilter(str) {
    if (str == null) return "";
    return String(str)
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;");
  },
  radom() {

  },
  unescapeHTML(html) {
    return String(html)
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&");
  },
  //深度对比两个对象是否相同b
  isEqualObject(a, b) {
    var _this=this;
    if (a === b) { return true }
    var isObjectA = a ? typeof (a) == 'object' : false;
    var isObjectB = b ? typeof (b) == 'object' : false;
    if (isObjectA && isObjectB) {
      try {
        var isArrayA = _this.type(a) == 'array'
        var isArrayB = _this.type(b) == 'array'
        if (isArrayA && isArrayB) {
          return a.length === b.length && a.every(function (e, i) {
            return _this.isEqualObject(e, b[i])
          })
        } else if (a instanceof Date && b instanceof Date) {
          return a.getTime() === b.getTime()
        } else if (!isArrayA && !isArrayB) {
          var keysA = Object.keys(a);
          var keysB = Object.keys(b);
          return keysA.length === keysB.length && keysA.every(function (key) {
            return _this.isEqualObject(a[key], b[key])
          })
        } else {
          /* istanbul ignore next */
          return false
        }
      } catch (e) {
        /* istanbul ignore next */
        return false
      }
    } else if (!isObjectA && !isObjectB) {
      return String(a) === String(b)
    } else {
      return false
    }
  },
  //验证是否为一个空对象
  isEmptyObject: function (obj) {
    var name;
    for (name in obj) {
      return false;
    }
    return true;
  },
  //验证是否是一个纯粹的对象
  isPlainObject: function (obj) {
    var proto, Ctor;
    if (!obj || toString.call(obj) !== "[object Object]") {
      return false;
    }

    proto = getProto(obj);
    if (!proto) {
      return true;
    }
    Ctor = hasOwn.call(proto, "constructor") && proto.constructor;
    return (
      typeof Ctor === "function" &&
      fnToString.call(Ctor) === ObjectFunctionString
    );
  },
  //验证类型是否为函数
  isFunction(fn) {
    return this.type(fn) == "function";
  },
  isArray(val) {
    return this.type(val) == "array";
  },
  isObject(val) {
    return this.type(val) == 'object';
  },
  isString(val) {
    return this.type(val) == "string";
  },
  isBoolean(val) {
    return this.type(val) == "boolean";
  },
  isDate(val) {
    return this.type(val) == "date";
  },
  isError(val) {
    return this.type(val) == "error";
  },
  //http错误统一处理
  httpError(error, opts) {
    opts = this.isDef(opts) ? opts : {};
    let isSilent = this.isDef(opts.isSilent) ? opts.isSilent : false;
    const SESSION_TIMEOUT = "sessionTimeout";
    let errorMessage = ""
    if (this.isString(error)) {
      errorMessage = error;
    }
    else if (this.isObject(error) && error.message) {
      errorMessage = error.message;
    }
    else if (this.isError(error)) {
      errorMessage = error.message;
    }
    else if (opts && opts.message) {
      errorMessage = opts.message;
    }
    if (errorMessage == SESSION_TIMEOUT) {
      sessionTimoutHandler();
      this.error(error);
    }
    else {
      !isSilent && this.layer.message(errorMessage, false);
      this.error(error)
      opts.callback && opts.callback(error)
    }
  },
  //处理平台的文件url格式
  getFilePath(str, isAbsolute, index) {
    index = this.isDef(index) ? index : 0;
    let files = null;
    if (str && this.type(str) == 'string') {
      try {
        let result = JSON.parse(str);
        if (this.type(result) == "array") {
          files = result;
        } else if (this.type(result) == "object") {
          files = [result];
        }
      }
      catch (ex) {
        return str;
      }
    }
    else {
      return ""
    }
    if (isAbsolute) {
      return files[index].absolutePath;
    }
    else {
      return dsf.url.getWebPath(files[index].relativePath)
    }
  },
  getFileInfo(str, index) {
    let files = [];
    if (str) {
      let result = JSON.parse(str);
      if (this.type(result) == "array") {
        files = result;
      } else if (this.type(result) == "object") {
        files = [result];
      }
    }
    if (this.isDef(index)) {
      return files[index];
    }
    return files;
  },
  //代理console.log
  log: function () {
    if (hasConsole) {
      Function.apply.call(_console.log, _console, arguments);
    }
  },
  //代理console.time
  time: function () {
    if (hasConsole) {
      var method = _console.time;
      Function.apply.call(method, _console, arguments);
    }
  },
  //代理console.timeEnd
  timeEnd: function () {
    if (hasConsole) {
      var method = _console.timeEnd;
      Function.apply.call(method, _console, arguments);
    }
  },
  //代理console.warn
  warn: function () {
    if (hasConsole) {
      var method = _console.warn || _console.log;
      Function.apply.call(method, _console, arguments);
    }
  },
  //代理console.error
  error: function () {
    if (hasConsole) {
      var method = _console.error || _console.log;
      Function.apply.call(method, _console, arguments);
    }
  },
  //类似jQuery.extend方法，可用于浅拷贝，深拷贝
  mix: function () {
    var options,
      name,
      src,
      copy,
      copyIsArray,
      clone,
      target = arguments[0] || {},
      i = 1,
      length = arguments.length,
      deep = false;

    // Handle a deep copy situation
    if (typeof target === "boolean") {
      deep = target;
      // Skip the boolean and the target
      target = arguments[i] || {};
      i++;
    }

    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== "object" && !this.isFunction(target)) {
      target = {};
    }
    if (i === length) {
      target = this;
      i--;
    }
    for (; i < length; i++) {
      // Only deal with non-null/undefined values
      if ((options = arguments[i]) != null) {
        // Extend the base object
        for (name in options) {
          copy = options[name];
          // Prevent Object.prototype pollution
          // Prevent never-ending loop
          if (name === "__proto__" || target === copy) {
            continue;
          }
          // Recurse if we're merging plain objects or arrays
          if (
            deep &&
            copy &&
            (this.isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))
          ) {
            src = target[name];
            // Ensure proper type for the source value
            if (copyIsArray && !Array.isArray(src)) {
              clone = [];
            } else if (!copyIsArray && !this.isPlainObject(src)) {
              clone = {};
            } else {
              clone = src;
            }
            copyIsArray = false;
            // Never move original objects, clone them
            target[name] = this.mix(deep, clone, copy);

            // Don't bring in undefined values
          } else if (copy !== undefined) {
            target[name] = copy;
          }
        }
      }
    }

    // Return the modified object
    return target;
  },
  //获取元素某个计算后的css属性
  getCss: function (curEle, attr) {
    var val = null,
      reg = null;
    if ("getComputedStyle" in window) {
      val = window.getComputedStyle(curEle, null)[attr];
    } else {
      //ie6~8不支持上面属性
      //不兼容
      if (attr === "opacity") {
        val = curEle.currentStyle["filter"]; //'alpha(opacity=12,345)'
        reg = /^alphaopacity=(\d+(?:\.\d+)?)opacity=(\d+(?:\.\d+)?)$/i;
        val = reg.test(val) ? reg.exec(val)[1] / 100 : 1;
      } else {
        val = curEle.currentStyle[attr];
      }
    }
    return val;
  },
  getUploadFileInfo(str, index) {
    let files = $files;
    if (str) {
      let result = JSON.parse(str);
      if (this.type(result) == "array") {
        files = result;
      } else if (this.type(result) == "object") {
        files = [result];
      }
    }
    if (this.isDef(index)) {
      return files[index];
    }
    return files;
  },
  getParse() {
    //获取一个被str的 json或者对象的属性值
    let str = arguments[0];
    if (str) {
      let result = JSON.parse(str);
      if (this.isDef(result)) {
        for (let i = 1; i < arguments.length; i++) {
          result = result[arguments[i]];
        }
      }
      return result;
    }
    return str;
  },
  //获取cookie值
  getCookie: function (cookie_name) {
    // var allcookies = document.cookie;
    // //索引长度，开始索引的位置
    // var cookie_pos = allcookies.indexOf(cookie_name);
    // // 如果找到了索引，就代表cookie存在,否则不存在
    // if (cookie_pos != -1) {
    //   //把cookie_pos放在值的开始，只要给值加1即可
    //   //计算取cookie值得开始索引，加的1为“=”
    //   cookie_pos = cookie_pos + cookie_name.length + 1;
    //   //计算取cookie值得结束索引
    //   var cookie_end = allcookies.indexOf(";", cookie_pos);
    //   if (cookie_end == -1) {
    //     cookie_end = allcookies.length;
    //   }
    //   //得到想要的cookie的值
    //   var value = unescape(allcookies.substring(cookie_pos, cookie_end));
    // }
    // return value;
    return dsf.cookies.get(cookie_name)
  },
  setCookie: function (cookie_name, value) {
    dsf.cookies.get(cookie_name, value);
  },
  toPromise: function (fn, ...params) {
    return toPromise(fn, ...params);
  },
  getToken() {
    return dsf.cookies.get('authorization_token') || dsf.storage.session.get('authorization_token') || "";
  },
  getDefaultHttpHeader() {
    return {
      "client": dsf.global.$isMobile ? "mobile" : "pc",
      ...this.getTokenHttpHeader()
    }
  },
  getTokenHttpHeader() {
    return {
      'authorization_token': this.getToken()
    }
  },
  // numberAmountConversion: function (amount) {
  //   // 汉字的数字
  //   const cnNums = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  //   // 基本单位
  //   const cnIntRadice = ["", "拾", "佰", "仟"];
  //   // 对应整数部分扩展单位
  //   const cnIntUnits = ["", "万", "亿", "兆"];
  //   // 对应小数部分单位
  //   const cnDecUnits = ["角", "分", "毫", "厘"];
  //   // 整数金额时后面跟的字符
  //   const cnInteger = "整";
  //   // 整型完以后的单位
  //   const cnIntLast = "元";
  //   // 最大处理的数字
  //   const maxNum = 9999999999999999.99;
  //   // 金额整数部分
  //   let integerNum;
  //   // 金额小数部分
  //   let decimalNum;
  //   // 输出的中文金额字符串
  //   let chineseStr = "";
  //   // 分离金额后用的数组，预定义
  //   let parts;
  //   if (amount === "") {
  //     return "";
  //   }
  //   amount = parseFloat(amount);
  //   if (amount >= maxNum) {
  //     // 超出最大处理数字
  //     return "";
  //   }
  //   if (amount === 0) {
  //     chineseStr = cnNums[0] + cnIntLast + cnInteger;
  //     return chineseStr;
  //   }
  //   // 转换为字符串
  //   amount = amount.toString();
  //   if (amount.indexOf(".") === -1) {
  //     integerNum = amount;
  //     decimalNum = "";
  //   } else {
  //     parts = amount.split(".");
  //     integerNum = parts[0];
  //     decimalNum = parts[1].substr(0, 4);
  //   }
  //   // 获取整型部分转换
  //   if (parseInt(integerNum, 10) > 0) {
  //     let zeroCount = 0;
  //     const IntLen = integerNum.length;
  //     for (let i = 0; i < IntLen; i++) {
  //       const n = integerNum.substr(i, 1);
  //       const p = IntLen - i - 1;
  //       const q = p / 4;
  //       const m = p % 4;
  //       if (n === "0") {
  //         zeroCount++;
  //       } else {
  //         if (zeroCount > 0) {
  //           chineseStr += cnNums[0];
  //         }
  //         // 归零
  //         zeroCount = 0;
  //         chineseStr += cnNums[parseInt(n, 10)] + cnIntRadice[m];
  //       }
  //       if (m === 0 && zeroCount < 4) {
  //         chineseStr += cnIntUnits[q];
  //       }
  //     }
  //     chineseStr += cnIntLast;
  //   }
  //   // 小数部分
  //   if (decimalNum !== "") {
  //     const decLen = decimalNum.length;
  //     for (let i = 0; i < decLen; i++) {
  //       const n = decimalNum.substr(i, 1);
  //       if (n !== "0") {
  //         chineseStr += cnNums[Number(n)] + cnDecUnits[i];
  //       }
  //     }
  //   }
  //   if (chineseStr === "") {
  //     chineseStr += cnNums[0] + cnIntLast + cnInteger;
  //   } else if (decimalNum === "") {
  //     chineseStr += cnInteger;
  //   }
  //   return chineseStr;
  // },
  removePoint(data) {
    // 循环递归 去掉"."
    const copyData = JSON.parse(JSON.stringify(data));
    let endObj = null;
    if (this.type(copyData) === "object") {
      endObj = {};
      for (let [key, value] of Object.entries(copyData)) {
        // console.log(`key: ${key} value:${value}`);
        const arr = key.split(".");
        const newKey = arr[arr.length - 1];
        if (this.type(value) === "object" || this.type(value) === "array") {
          endObj[newKey] = this.removePoint(value);
        } else {
          endObj[newKey] = value;
        }
      }
    } else if (this.type(copyData) === "array") {
      endObj = [];
      copyData.forEach((item, index) => {
        if (this.type(item) === "object") {
          endObj[index] = {};
          for (let [key, value] of Object.entries(item)) {
            // console.log(`key: ${key} value:${value}`);
            const arr = key.split(".");
            const newKey = arr[arr.length - 1];
            let newValue;
            if (this.type(value) === "object") {
              newValue = this.removePoint(value);
            } else if (this.type(value) === "array") {
              newValue = this.removePoint(value);
            } else {
              newValue = value;
            }
            endObj[index][newKey] = newValue;
          }
        }
      });
    } else {
      endObj = data;
    }
    return endObj;
  },
  /**
   * 往上查找离el最近的带滚动条的容器
   * @param el {HTMLElement}
   * @returns {HTMLElement}
   */
  findScrollEl(el) {
    while (el.parentElement) {
      el = el.parentElement;
      if (el.tagName === "BODY") {
        break;
      }
      let computedStyle = window.getComputedStyle(el);
      let overflowX = el.style.getPropertyValue('overflow-x') || computedStyle['overflow-x'];
      let overflowY = el.style.getPropertyValue('overflow-y') || computedStyle['overflow-y'];
      let overflow = el.style.getPropertyValue('overflow') || computedStyle['overflow'];
      if (overflowY === "auto" || overflow === "auto" || overflowX === "auto" ||
        overflowY === "scroll" || overflow === "scroll" || overflowX === "scroll") {
        break;
      }
    }
    return el;
  },
  /**
   * 递归遍历
   * @param obj       要遍历的对象或数组
   * @param callback  回调函数，Function(item, level: 层级, index: 所在层级的索引, parent: 父级)。
   *                  返回1则跳过当前层的遍历；
   *                  返回0则结束当前层的遍历，且继续该层的子层级遍历；
   *                  返回-1则结束当前层的遍历。
   * @param options
   * @param options.children  子层级的字段名
   * @param options.isLast    是否只遍历最后一层
   */
  deepForEach(obj, callback, options) {
    if (!callback) return;
    let { children = "children", isLast = false } = options || {};
    deepForEach(null, obj, 0, 0, callback, { children, isLast });
  },
  codeExec(str) {
    let code = [`return (${str})`];
    let fn = new Function(code.join("\r\n"));
    let result = fn();
    return result;
  },
  vueHelper: vueHelper
};

function toPromise(fn, ...params) {
  return new Promise((resolve, reject) => {
    let next = (mark) => {
      if (mark === false || mark instanceof Error) {
        reject(mark);
      } else {
        resolve(true);
      }
    };
    if (fn) {
      fn(next, ...params);
    }
    else {
      next()
    }
  });
}

/**
 * 递归遍历
 * @param parentObj
 * @param obj
 * @param level
 * @param index
 * @param callback
 * @param children
 * @param isLast
 * @returns {any}
 */
function deepForEach(parentObj, obj, level = 0, index = 0, callback, { children = "children", isLast = false }) {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      let res = deepForEach(parentObj, obj[i], level, i, callback, { children, isLast });
      if (res === -1) return -1;
      if (res === 0) break;
    }
  } else if (typeof obj == "object") {
    let child = obj[children], res = undefined;
    if (!isLast || !child || !child.length) {
      res = callback(obj, level, index, parentObj);
    }
    if (((dsf.type(child) === 'array' && child.length) || dsf.type(child) === 'object') && !res) {
      return deepForEach(obj, child, level + 1, 0, callback, { children, isLast });
    }
    return res;
  }
}