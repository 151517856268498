import layout from './layout';
export default {
  mixins: [layout],
  design: {
    layout: {
      exclude: ['active']
    }
  },
  props: {
    tabHeight: {
      type: String,
      default: '100%',
      desc: '组件高度'
    },
    slots: {
      type: Array,
      default: function () {
        return []
      }
    },
    tabStyle: {
      type: String,
      default: '',
      desc: '组件风格'
    },
    tabPosition: {
      type: String,
      default: 'top',
      desc: '组件位置'
    },
    enableDelete: {
      type: Boolean,
      default: false,
      desc: '标签是否可关闭'
    },
    showPadding: {
      type: Boolean,
      default: true,
      desc: '是否有边距'
    },
    openLazy: {
      type: Boolean,
      default: false,
      desc: '开启懒加载'
    },
    active: {
      type: String,
      default: '',
    },
    contentPadding: {
      type: Array,
      default: ['0', '0', '0', '0']
    }
  },
  data() {
    return {
      activeName: this.active,
      height: '100%'
    };
  },
  computed: {
    activeNames() {
      let arr = [];
      for (let i in this.slots) {
        let tab = this.slots[i];
        if(this.isDesign){
          arr.push(tab.name);
        }
        else{
          if (!this.$eval(tab.disable) && !this.$eval(tab.isHide)) {
            arr.push(tab.name);
          }
        }
      }
      return arr;
    }
  },
  watch: {
    activeName() {
      this.$nextTick(() => {
        this.$page && dsf.resizeComponent(this.$page);
      })
    }
  },
  created() {
    if (this.isDesign && this.slots.length == 0) {
      let v = [];
      for (let i = 0; i < 4; i++) {
        v.push({
          title: 'Tab' + (i + 1),
          name: 'tab' + (i + 1),
          disable: 'false',
          isHide: 'false',
          badge: null,
          lazy: 'false',
          controls: []
        });
      }
      this.slots = v;
    }
    this.$nextTick(() => {
      this.changeActive();
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeHeight();
    });
  },
  methods: {
    isdisable(tab) {
      if (this.isDesign) {
        return false;
      }
      if(tab.disable){
        return this.$eval(tab.disable);
      }
      return false;
    },
    isHide(tab) {
      if (!this.isDesign) {
        return this.$eval(tab.isHide);
      }
      return false;
    },
    isLazy(tab) {
      if (this.isDesign) {
        return false;
      } else {
        return this.$eval(tab.lazy);
      }

    },
    changeActive() {
      // let activeName = null;
      // for (let i in this.slots) {
      //   let tab = this.slots[i];
      //   if (!activeName && !(this.$eval(tab.disable) || this.$eval(tab.isHide))) {
      //     activeName = tab.name;
      //   }
      //   this.activeNames.push(tab.name);
      // }
      if (this.activeNames.length && this.activeNames.indexOf(this.activeName) === -1) {
        this.activeName = this.activeNames[0];
      }
    },
    resizeHeight() {
      if (this.$parent.__desContext__) {
        this.$parent.__desContext__.height = this.tabHeight;
      }
    },
    tabChange(obj) {
      // 作为二次开发切换选中tab
      // obj  this.slots[2]  也可以是 {name: this.slots[2].name}
      this.activeName = obj.name;
    },
    tabClick(tab, event) {
      // 二次开发tab选中事件监听（第一次选中和代码触发时不会调用）
      // console.log(tab, event);
      this.$emit("tab-click", tab);
      this.$nextTick(() => {
        //强制更新页面尺寸,解决需要动态计算宽高的组件在tab未点击隐藏时尺寸计算不正确的情况
        dsf.resizeComponent(this);
      })
    },
    tabRemove(tab, event) {
      // 二次开发tab被删除事件
      // tab.index(索引)
      // tab.name(id)
      // console.log(tab, event);
    },
    renderTabContent(obj) {
      // 刷新某一个内容区域
      let name = obj && obj.name ? obj.name : this.activeName;
      let slot = _.find(this.slots, s => s.name === name);
      slot.isHide = 'true';
      this.$nextTick(() => {
        slot.isHide = 'false';
      });
    },
    reloadData() {
      _.each(this.$children, (children) => {
        children?.reloadData && children.reloadData();
      });
    }
  }
}