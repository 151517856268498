export default {
  webRoot: {
    default: "/dsf5/",
    dorm: "/dorm/"
  },
  assetsDir: "./static/",
  htmlDir: "./",
  //无论何时都强行再请求前回家webRoot
  unshiftWebRoot: false,
  //系统生成页面的访问地址
  serverCreateHtmlDir: "./",
  file: {
    preViewServer: "/"
  },
  http: {
    timeout: 20000
  },
  //混入特殊类型页面配置
  layoutViewMixins: {
    'form': ["form"],
    'question': ['asqQuestion']
  },
  //调用message时对应的弹出层提示,仅对PC端有效
  message: {
    error: "error",
    success: "success",
    warning: "warning",
    info: "info",
    box: {
      customClass: ['el-message-box-10vh']
    }
  },
  //数据列表组件配置
  list: {
    //勾选列
    checkColumn: {
      width: 60
    }
  },

  //组件配置
  component: {
    //高级检索
    superSearch: {
      //下拉框配置
      select: {
        //多选是否折叠选项
        collapseTags: true,
        //搜索过滤选项
        filterable: true
      }
    },
    //弹出框口默认是否显示在顶层
    topOpenDialog: false
  },
  //设计器scss编译文件
  designer: {
    //是否过滤组件版本
    controlsVersionFilter: false,
    defaultControlGroup: [],
    scssDir: "/static/",
    //设计器编辑页面自定义样式时的基础样式变量
    // scss: ["platform/variable.scss", "platform/themes.scss", "platform/mixins.scss", "project/variable.scss", "project/themes.scss", "project/mixins.scss"]
    scss: ["platform/variable.scss", "platform/themes.scss", "platform/mixins.scss"]
  },
  //国密加密公钥
  setting_public_smpublickey:"0437c909848258e8a28113248f3fd53bd9afbc7c3ed24b524e3400e772ff70503e37fe59978645e953c656654b3bcfa3aba3dffd39bfb07667c9908785c6616362",
  //开启网络状态监听
  setting_public_watchnetonline:true,
  setting_public_multipagename:"multiPage.html",
  //防止截图水印是否开启
  setting_public_on_watermark: true,
  //防止截图水印默认状态，兼容已经配置好的老页面
  setting_public_default_watermark: true,
  //防止截图水印默认显示文字
  setting_public_watermark_text: "@[session('user_name')]\n@[dateFormat(now(),'yyyy-mm-dd')]",
  //防止截图水印默认显示文字透明通道
  setting_public_watermark_text_alpha: "0.7",
  //加载页面和ext时是否使用浏览器缓存
  setting_public_layoutviewcache: process.env.NODE_ENV === "development" ? false : true,
  // 全局共享WebSocket通道地址
  setting_public_ws_url: '',
  //默认字体大小。bigFont 大号字形16号，normal 常规字形14号
  setting_public_default_size: "normal",
  //登录成功后跳转页面
  setting_public_login_success_to: "./index.html",
  //登录成功后跳转页面
  setting_public_mobile_login_success_to: "./mobile-index.html",
  //登录页地址
  setting_public_pc_login_page: "./page.html#/pc/login",
  //移动端登录页面地址
  setting_public_mobile_login_page: "./mobile-index.html#/mobile/login",
  // 默认主题
  setting_public_default_theme: "darkred-theme",
  // 可切换的主题列表
  setting_public_show_themes: "darkred-theme,red-theme,darkblue-theme,blue-theme,green-theme,orange-theme,",
  //页面快捷跳转到设计器的功能开关
  setting_public_gotoicon: "1",
  //可设置的基准字体大小
  setting_public_show_fontsizebase: "14",
  //可设置的基准字体递增量
  setting_public_show_fontsizestep: 2,
  //可设置的基准字体递增档数
  setting_public_show_fontsizestepcount: 4,
  //可设置字体大小的文本
  setting_public_show_fontsizemap: "正常,中号,大号,特大,最大",
  //pc端logo配置
  setting_public_logo_image: "./static/img/platform/logo.png",
  //pc端logo配置(小)
  setting_public_logo_image_mini: "./static/img/platform/logo.png",
  //移动端logo图片
  setting_public_mobile_logo_image: "./static/img/platform/logo.png",
  //移动端logo图片(小)
  setting_public_mobile_logo_image_mini: "./static/img/platform/logo.png",
  //默认标题
  setting_public_logo_title: "",
  //默认头像
  setting_public_user_default_header: "./static/img/platform/defaultHeader.png",
  //菜单请求接口
  setting_public_home_menu_src: "/menu/getTree",
  //登录接口
  setting_public_loginin: "/login/login",
  //退出登录接口
  setting_public_loginout: "/login/loginOut",
  //默认预览
  setting_public_file_host: "/",
  // 全局dialog的底部（按钮）布局：left、center、right
  setting_public_default_dialog_footer_align: 'right',
  // iframeloader加载白名单
  setting_public_whitelist: [
    //允许加载的url地址
    // {
    //   'setting_public_white_list.domain': "*://*:*"
    // }
  ],
  kw: {
    id: "_id",
    order: "ds_order",
    deleted: "ds_deleted",
    unitid: "ds_unit_id",
    createtime: "ds_create_time",
    updatetime: "ds_update_time",
    updateusername: "ds_update_user_name",
    updateuserid: "ds_update_user_id",
    createusername: "ds_create_user_name",
    createuserid: "ds_create_user_id"
  },
  // 修改密码地址
  setting_public_alter_password_page: './index.html#/pc/dsfa/platformhome/alterPassword',
  // pc端忘记密码地址
  setting_public_forgot_password_page: './page.html#/pc/dsfa/platformhome/forgetPassword',
  // 移动端忘记密码地址
  setting_mobile_public_forgot_password_page: "./mobile-index.html#/mobile/dsfa/platformhome/mobile-forgot-password",

};
