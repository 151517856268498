import { mapState } from 'vuex';
import control from './control';

const defaultData = [
  {
    _id: 1,
    picture: '',
    icon: 'icon-location-solid',
    color: '',
    foreColor: '',
    openType: '1',
    desc: '这是一段描述文字',
    name: '坐标',
    url: ''
  },
  {
    _id: 2,
    picture: '',
    icon: 'icon-xiaolianmanyifuwu',
    color: '',
    foreColor: '',
    openType: '1',
    desc: '这是一段描述文字',
    name: '笑脸',
    url: ''
  },
  {
    _id: 3,
    picture: '',
    icon: 'icon-tab-addressbook-fill',
    color: '',
    foreColor: '',
    openType: '1',
    desc: '这是一段描述文字',
    name: '通讯录',
    url: ''
  },
  {
    _id: 4,
    picture: '',
    icon: 'icon-tab_message_d',
    color: '',
    foreColor: '',
    openType: '1',
    desc: '这是一段描述文字',
    name: '消息中心',
    url: ''
  },
  {
    _id: 5,
    picture: '',
    icon: 'icon-location-solid',
    color: '',
    foreColor: '',
    openType: '1',
    desc: '这是一段描述文字',
    name: '坐标',
    url: ''
  },
  {
    _id: 6,
    picture: '',
    icon: 'icon-xiaolianmanyifuwu',
    color: '',
    foreColor: '',
    openType: '1',
    desc: '这是一段描述文字',
    name: '笑脸',
    url: ''
  },
  {
    _id: 7,
    picture: '',
    icon: 'icon-tab-addressbook-fill',
    color: '',
    foreColor: '',
    openType: '1',
    desc: '这是一段描述文字',
    name: '通讯录',
    url: ''
  },
  {
    _id: 8,
    picture: 'https://img.yzcdn.cn/vant/cat.jpeg',
    icon: 'icon-tab_message_d',
    color: '',
    foreColor: '',
    openType: '1',
    desc: '这是一段描述文字',
    name: '消息中心',
    url: ''
  },
  {
    _id: 9,
    picture: '',
    icon: 'icon-tab_message_d',
    color: '',
    foreColor: '',
    openType: '1',
    desc: '这是一段描述文字',
    name: '消息中心',
    url: ''
  },
];

// 新版快捷菜单
export default {
  mixins: [control],
  props: {
    // 组件高度
    height: {
      type: String,
      default: '100%'
    },
    // 组件最小高度
    minHeight: {
      type: String,
      default: 'none'
    },
    // 内部高度自适应
    innerHeightAuto: {
      type: Boolean,
      default: true
    },
    // 一行几列
    colNum: {
      type: Number,
      default: 5
    },
    // 一页几行
    // 0表示不限行数，且页数将固定1
    rowNum: {
      type: Number,
      default: 0
    },
    // 最多几页
    // 0表示无限制
    maxPages: {
      type: Number,
      default: 0
    },
    // 是否强制使用默认样式
    useDefaultStyle: {
      type: Boolean,
      default: false
    },
    // 是否显示更多按钮
    hasMore: {
      type: Boolean,
      default: false
    },
    // 数据来源
    dataSource: {
      type: String,
      default: 'http'
    },
    dataSourceUrl: {
      type: String,
      default: ''
    },
    dataSourceList: {
      type: Array,
      default() {
        return []
      }
    },
    displayStyle: {
      type: String,
      default: 'style1'
    },
    /*****更多按钮*****/
    // 更多按钮显示文字
    moreText: {
      type: String,
      default: '更多'
    },
    // 更多按钮显示图标
    moreIcon: {
      type: String,
      default: 'icon-quanbu'
    },
    // 更多按钮跳转链接
    moreUrl: {
      type: String,
      default: ''
    },
    // 更多按钮跳转方式
    moreOpentype: {
      type: String,
      default: '0'
    },
    // 二次开发传入的方法。
    itemClick: {
      type: Function,
      required: false
    },
    clickBefore: {
      type: Function,
      required: false
    },
    /*****角标设置*****/
    // 角标样式。text 显示文字; dot 显示点;
    enableMark: {
      type: String,
      default: ''
    },
    /*****图标样式*****/
    // 展示优先级。img图片优先，icon图标优先
    showPriority: {
      type: String,
      default: 'img'
    },
    // 默认图标
    defaultIcon: {
      type: String,
      default: ''
    },
    iconWidth: {
      type: String,
      default: '50px'
    },
    iconHeight: {
      type: String,
      default: '50px'
    },
    iconRadius: {
      type: String,
      default: '5px'
    },
    iconBgColor: {
      type: String,
      default: '#fff'
    },
    iconBgShadow: {
      type: Boolean,
      default: true
    },
    iconFontSize: {
      type: String,
      default: '25px'
    },
    iconFontBold: {
      type: Boolean,
      default: false
    },
    iconFontColor: {
      type: String,
      default: '@normal'
    },
    iconFontShadow: {
      type: Boolean,
      default: true
    },
    /*****公共样式*****/
    cellMargin: {
      type: Array,
      default() {
        return ['5px','5px','5px','5px']
      }
    },
    cellPadding: {
      type: Array,
      default() {
        return ['0px','0px','0px','0px']
      }
    },
    cellWidth: {
      type: String,
      default: '100%'
    },
    cellRadius: {
      type: String,
      default: '0px'
    },
    cellColor: {
      type: String,
      default: ''
    },
    hasDesc: {
      type: Boolean,
      default: false
    },
    iconAndInfoOfSpacing: {
      type: String,
      default: '0px'
    },
    /*****风格1样式*****/
    /*****风格2样式*****/
  },
  data() {
    return {
      loading: false,
      activeIndex: 0,
      sourceData: [],
      isIniting: true
    }
  },
  computed: {
    ...mapState("ws", ["subscript"]),
    dataList() {
      if (this.isDesign) {
        if (this.dataSource == 'http' || !this.dataSourceList.length) {
          return defaultData;
        }
      }
      if (this.dataSource == 'http') {
        return this.sourceData;
      }
      return this.dataSourceList;
    },
    moreInfo() {
      if (!this.hasMore || this.loading) return {};
      return {
        _id: 'more',
        picture: '',
        icon: this.moreIcon,
        color: '',
        bgColor: '',
        url: this.moreUrl,
        openType: this.moreOpentype,
        name: this.moreText
      };
    },
    menuList() {
      let res = [...this.dataList];
      if (this.hasMore) {
        res.push(this.moreInfo)
      }
      res = _.chunk(res, this.colNum);
      // 最后一行补位
      if (res.length && _.last(res).length < this.colNum) {
        let n = this.colNum - _.last(res).length;
        _.last(res).push(...new Array(n).fill({_hide: true}));
      }
      if (this.rowNum) {
        res = _.chunk(res, this.rowNum);
        // 最后一页补位
        if (res.length && _.last(res).length < this.rowNum) {
          let n = this.rowNum - _.last(res).length;
          _.last(res).push(...new Array(n).fill([]));
        }
      } else {
        res = [res];
      }
      return res;
    },
  },
  created() {
    this.reloadData();
  },
  methods: {
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.clickBefore?.(...params);
      if (res && res.then) {
        res.then(r => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
    reloadData() {
      if (this.isDesign || this.dataSource != 'http') {
        return;
      }
      if (this.dataSourceUrl == '') {
        this.data = [];
        return;
      }
      this.loading = true;
      this.$http.get(this.dataSourceUrl).done(({success, data, message}) => {
        if (success) {
          this.sourceData = data;
          this.loading = false;
        } else {
          dsf.layer.message(message, false);
        }
      }).error(err => {
        dsf.layer.message(err.message, false);
      }).always(() => {
        this.isIniting = false;
      });
    },
    getImgUrl({ picture, icon }) {
      if (this.showPriority == 'img') {
        return picture;
      }
      if (!icon || icon == '') {
        return picture;
      }
      return '';
    },
    getIcon(icon) {
      if (!icon || icon == '') {
        icon= this.defaultIcon
      }
      if (!icon) return '';
      return !icon.indexOf('icon-') ? icon : 'icon-' + icon;
    },
    showMark({ mark }) {
      if (this.enableMark == '') return false;
      if (this.isDesign) return true;
      return this.subscript[mark] && this.subscript[mark] == '0';
    },
    markVal({ mark }) {
      if (this.isDesign) return '99+';
      let v = this.subscript[mark];
      if (/^\d$/.test(v)) {
        if (parseInt(v) > 99) return '99+';
      }
      return v;
    },
    onClick(item) {
      if (this.itemClick) {
        let res = this.itemClick(item);
        if (res === false) return;
        if (res.then) {
          res.then((r) => {
            if (r !== false) {
              this.openPage(item);
            }
          });
          return;
        }
      }
      this.openPage(item);
    },
    openPage(menu) {
      let url = menu.url;
      if (!url || url == "") {
        return;
      }
      if (url.indexOf("#") == 0 && url.indexOf("#/") != 0) {
        url = url.replace("#", "#/");
      }
      url = this.$replace(url, this.local);
      let opt = {
        url,
        state: menu.openType || menu.opentype,
      };
      if (opt.state == 3) {
        opt['title'] = menu.dialogTitle || menu.name || undefined;
        opt['width'] = menu.dialogWidth || '70vw';
        opt['height'] = menu.dialogHeight || '60vh';
      }
      this.$open(opt);
    },
  }
};