export default {
  type: "harmony",
  isEnv() {
    let { harmony } = dsf.client;
    return harmony();
  },
  init(opt = {}) {
    try {
      return new Promise((resolve, reject) => {
        let { sdkUrl } = opt;
        if (!sdkUrl) {
          throw "请传入_harmony.sdkUrl";
        }
        dsf.http
          .importFiles(sdkUrl)
          .then(([{ state }]) => {
            // 加载sdk
            if (!state) {
              console.error("_harmony.sdkUrl.js文件出错");
              reject(`${sdkUrl}文件出错`);
            }
            document.addEventListener(
              "deviceready",
              () => {
                this.methods.isDeviceready = true;
                setTimeout(() => {
                  resolve();
                });
              },
              false
            );
          })
          .catch((err) => {
            alert(err);
            reject(err);
          });
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  methods: {
    chooseImage() {
      return Promise.reject({
        message: "当前环境不支持选择图片操作",
      });
    },
    previewImage() {
      return Promise.reject({
        message: "当前环境不支持预览图片操作",
      });
    },
    camera:{
      scan(param = {}) {
        return new Promise((resolve, reject) => {
          const action = "$SystemAction.scanCode";
          $dsfMobile.execute(
            action,
            param,
            function (success) {
              resolve(success?.data?.originalValue)
            },
            function (fail) {
              reject(fail.message)
            }
          );
        });
      },
    },
    scan(param = {}) {
      return new Promise((resolve, reject) => {
        const action = "$SystemAction.scanCode";
        $dsfMobile.execute(
          action,
          param,
          function (success) {
            resolve(success?.data?.originalValue)
          },
          function (fail) {
            reject(fail.message)
          }
        );
      });
    },
    getLocation() {
      return Promise.reject({
        message: "当前环境不支持定位操作",
      });
    },
    openLocation() {
      return Promise.reject({
        message: "当前环境不支持调用内置地图操作",
      });
    },
    /**
     * 拨打电话
     * @param options
     * @param options.phoneNumber {string} 手机号码
     */
    callPhone(options = {}) {
      return new Promise((resolve, reject) => {
        try {
          if (!options.phoneNumber) throw "请传入phoneNumber电话号码";
          window.location.href = `tel:${options.phoneNumber}`;
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    // 关闭开机画面
    hideSplashScreen(param = {}) {
      return new Promise((resolve, reject) => {
        const action = "$SystemAction.splashscreen.hide";
        $dsfMobile.execute(
          action,
          { ...param },
          function (success) {
            resolve(success.data);
          },
          function (fail) {
            reject(fail.message);
          }
        );
      });
    },
    storage: {
      // 设置
      set(key, value) {
        return new Promise((resolve, reject) => {
          $dsfMobile.execute(
            "LocalStorageAction.save",
            {
              [key]: value,
            },
            function (s) {
              resolve(s);
            },
            function (e) {
              reject(e.message);
            }
          );
        });
      },
      // 获取
      get(key) {
        return new Promise((resolve, reject) => {
          $dsfMobile.execute(
            "LocalStorageAction.get",
            {
              key,
              defaultValue: null,
            },
            function (s) {
              resolve(s);
            },
            function (e) {
              reject(e.message);
            }
          );
        });
      },
      // 删除
      remove(key) {
        return new Promise((resolve, reject) => {
          $dsfMobile.execute(
            "LocalStorageAction.remove",
            {
              key,
            },
            function (s) {
              resolve(s);
            },
            function (e) {
              reject(e.message);
            }
          );
        });
      },
      // 清除
      claerAll() {
        return new Promise((resolve, reject) => {
          $dsfMobile.execute(
            "LocalStorageAction.clear",
            function (s) {
              resolve(s);
            },
            function (e) {
              reject(e.message);
            }
          );
        });
      },
    },
  },
};
