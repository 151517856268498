import { gcj02ToBd09ii } from "./utils"
let authenticationJsApiList = []
export default {
  type: "wechat",
  isEnv() {
    return dsf.client.weixin()
  },
  init(opt = {}, ref) {
    return new Promise((resolve, reject) => {
      try {
        let { sdkUrl } = opt
        if (!sdkUrl) {
          throw '请传入wechat.sdkUrl'
        }
        this.methods.camera.scan = this.methods.scan.bind(ref)
        dsf.http.importFiles(opt.sdkUrl).then(([{ state }]) => { // 加载sdk
          if (!state) {
            console.error("wechat.js文件出错")
            reject(`${sdkUrl}文件出错`)
          }
          authenticationJsApiList = this.jsApiList
          authentication().then(() => {
            resolve()
          }).catch((err) => {
            reject(err)
          });
        }).catch((err) => {
          reject(err)
        })
      } catch (error) {
        reject(error)
      }
    });
  },
  jsApiList: [
    'updateAppMessageShareData',
    'updateTimelineShareData',
    'onMenuShareTimeline',
    'onMenuShareAppMessage',
    'onMenuShareQQ',
    'onMenuShareWeibo',
    'onMenuShareQZone',
    'startRecord',
    'stopRecord',
    'onVoiceRecordEnd',
    'playVoice',
    'pauseVoice',
    'stopVoice',
    'onVoicePlayEnd',
    'uploadVoice',
    'downloadVoice',
    'chooseImage',
    'previewImage',
    'uploadImage',
    'downloadImage',
    'translateVoice',
    'getNetworkType',
    'openLocation',
    'getLocation',
    'hideOptionMenu',
    'showOptionMenu',
    'hideMenuItems',
    'showMenuItems',
    'hideAllNonBaseMenuItem',
    'showAllNonBaseMenuItem',
    'closeWindow',
    'scanQRCode',
    'chooseWXPay',
    'openProductSpecificView',
    'addCard',
    'chooseCard',
    'openCard',
  ],
  methods: {
    //扫描二维码
    async scan(opt = {}) {
      try {
        let _opt = this._getFuncOpts(
          {
            needResult: 1,
            scanType: ["qrCode", "barCode"]
          },
          opt
        )
        const fn = () => {
          return new Promise((resolve, reject) => {
            wx.scanQRCode({
              needResult: _opt.needResult, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: _opt.scanType, // 可以指定扫二维码还是一维码，默认二者都有
              success: function (res) {
                resolve((res && res.resultStr) || '')
              },
              fail: function (err) {
                reject(err)
              },
              complete: function (val) { }
            })
          })
        }
        if (dsf.client.android()) {
          return authentication().then(() => {
            return fn()
          })
        } else {
          return fn()
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    camera: {
      scan: null
    },
    // 获取用户详情
    getUserDetails() {
      return new Promise((resolve, reject) => {
        try {
          let data = {
            country: decodeURIComponent(dsf.cookies.get('country')),
            headImgUrl: decodeURIComponent(dsf.cookies.get('headImgUrl')),
            nickName: decodeURIComponent(dsf.cookies.get('nickName')),
            openid: decodeURIComponent(dsf.cookies.get('openId')),
            province: decodeURIComponent(dsf.cookies.get('province')),
            sex: decodeURIComponent(dsf.cookies.get('sex')),
            unionId: decodeURIComponent(dsf.cookies.get('unionId')),
          }
          let res = {
            openid: data.openid,
            name: data.nickName,
          }
          res["_" + this.$type] = data
          resolve(res)
        } catch (err) {
          reject(err)
        }
      })
    },
    // 分享
    shareToMessage(opt) {
      try {
        let _opt = this._getFuncOpts(
          {
            "url": "",
            "title": "",
            "content": "",
            "image": "",
          },
          opt
        )
        if (!_opt.url) {
          throw '请传入url'
        }
        if (!_opt.title) {
          throw '请传入title'
        }
        let close = shareToMessageShowBox()
        return Promise.all([new Promise((resolve, reject) => {
          wx.updateTimelineShareData({
            title: _opt.title, // 分享标题
            link: _opt.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _opt.imgUrl, // 分享图标
            success: function (res) {
              // 设置成功
              resolve(res)
            },
            fail: function (err) {
              reject(err)
            },
          })
          resolve()
        }), new Promise((resolve, reject) => {
          wx.updateAppMessageShareData({
            title: _opt.title, // 分享标题
            desc: _opt.content, // 分享描述
            link: _opt.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _opt.imgUrl, // 分享图标
            success: function (res) {
              // 设置成功
              resolve(res)
            },
            fail: function (err) {
              reject(err)
            },
          })
          resolve()
        })])
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // 获取当前定位位置
    getGeolocation(opt = {}) {
      try {
        let _opt = this._getFuncOpts(
          {
            "type": 'gcj02',
          },
          opt
        )
        const fn = () => {
          return new Promise((resolve, reject) => {
            wx.getLocation({
              type: _opt.type, // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: (data) => {
                let res = {
                  type: "gcj02",
                  lng: data.longitude,
                  lat: data.latitude,
                }
                res['_' + this.$type] = data
                resolve(res)
              },
              fail: function (err) {
                reject(err)
              },
              cancel: function (err) {
                reject(err)
              }
            })
          });
        }
        if (dsf.client.android()) {
          return authentication().then(() => {
            return fn()
          })
        } else {
          return fn()
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    location: {
      getBD() {
        try {
          const fn = () => {
            return new Promise((resolve, reject) => {
              wx.getLocation({
                type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: (data) => {
                  const location = gcj02ToBd09ii({
                    lng: data.longitude,
                    lat: data.latitude,
                  })
                  let res = {
                    type: "gcj02",
                    lng: location.lng,
                    lat: location.lat
                  }
                  res['_' + this.$type] = data
                  resolve(res)
                },
                fail: function (err) {
                  reject(err)
                },
                cancel: function (err) {
                  reject(err)
                }
              })
            });
          }
          if (dsf.client.android()) {
            return authentication().then(() => {
              return fn()
            })
          } else {
            return fn()
          }
        } catch (error) {
          return Promise.reject(error)
        }
      }
    }
  }
}

//鉴权
function authentication(jsApiList) {
  let url = '/api/wechat/auth/js/token';//获取微信签名
  return new Promise((resolve, reject) => {
    dsf.http.get(url, { url: window.encodeURIComponent(window.location.href) })
      .done((res) => {
        if (res.data.jsApiTicket) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: jsApiList || authenticationJsApiList // 必填，需要使用的JS接口列表
          });
          wx.ready(function () {
            resolve(true)
          });
          wx.error(function (res) {
            reject(res)
          });
        }
      })
      .error((res) => {
        reject(res)
      })
  })
}

// 微信分享提示
function shareToMessageShowBox(text) {
  let box = $(`
  <div class="dsf-share-to-message-box">
    <div class="dsf-share-to-message-box-jiantou">
    →
    </div>
    <div class="dsf-share-to-message-box-text">
    ${text || '点击更多菜单，自行分享'}
    </div>
  </div>
`).appendTo($(document.body))
  box.click(() => box.remove())
  return function () {
    box.remove()
  }
}