import handler from './helper.js';

// 是否可使用SharedWorker
// const canWorker = typeof window.SharedWorker !== 'undefined';
const canWorker = false;

const socketUrlErrorMessage='socket的url未指定或格式错误'

class DsfSocket {
  // 静态常量：正在链接中
  static CONNECTING = 0;
  // 静态常量：已经链接并且可以通讯
  static OPEN = 1;
  // 静态常量：连接正在关闭
  static CLOSING = 2;
  // 静态常量：连接已关闭或者没有链接成功
  static CLOSED = 3;

  #_url = '';
  get url() {
    return this.#_url;
  }
  // 当前的链接状态。
  #_readyState = '';
  get readyState() {
    if (this.#_socket) {
      return  this.#_socket.readyState;
    }
    return this.#_readyState;
  }
  // 服务器选择的下属协议。
  #_protocol = '';
  get protocol() {
    return this.#_protocol;
  }

  #_worker = null;
  #_socket = null;

  onclose = () => {};
  onerror = () => {};
  onmessage = () => {};
  onopen = () => {};

  /**
   * 构造器
   * @param url         要连接的URL；这应该是WebSocket服务器将响应的URL。
   * @param protocols   一个协议字符串或者一个包含协议字符串的数组。默认空字符串
   */
  constructor(url, protocols) {
    if (url.indexOf('http://') === 0) {
      throw 'url格式不合法';
    }
    this.#_url = url;
    this.#_protocol = protocols;
    // 若支持SharedWorker，就适用SharedWorker共享socket通道，否则降级。
    // iframe中若不支持SharedWorker，就适用顶层window共享socket通道。
    if (canWorker) {
      this._doWorker(url, protocols);
    }
    // 直接用原生WebSocket
    else {
      this._WebSocket(url, protocols);
    }
  }

  /**
   * 关闭WebSocket
   * @param code    一个数字状态码，它解释了连接关闭的原因。如果没有传这个参数，默认使用1005。
   * @param reason  一个人类可读的字符串，它解释了连接关闭的原因。这个UTF-8编码的字符串不能超过123个字节。
   */
  close(code, reason) {
    if (this.#_socket) {
      this.#_socket.close(code, reason);
    } else if (this.#_worker) {
      this.#_worker.port.postMessage({
        action: 'close',
        data: [this.#_url, code, reason]
      });
    }
  }

  /**
   * 发送消息
   * @param data {{USVString | ArrayBuffer | Blob | ArrayBufferView}}
   */
  send(data) {
    if (this.#_socket) {
      this.#_socket.send(data);
    } else if (this.#_worker) {
      this.#_worker.port.postMessage({
        action: 'send',
        data: [this.#_url, data]
      });
    }
  }

  _doWorker(url, protocols) {
    const jsSrc = dsf.url.getWebPath('$/js/platform/sharedWs.js');
    const worker = new window.SharedWorker(jsSrc, 'sharedWs');
    worker.port.addEventListener('message', e => {
      let {action, data}  = e.data;
      switch (action) {
      case 'open':
        this._onopen(data);
        break;
      case 'error':
        this._onerror(data);
        break;
      case 'close':
        this._onclose(data);
        break;
      case 'message':
        this._onmessage(data);
        break;
      }
    }, false);
    worker.port.addEventListener('error', e => {
      console.error(e);
    }, false);
    worker.port.start();
    worker.port.postMessage({
      action: 'openSocket',
      data: [url, protocols]
    });
    this.#_worker = worker;
  }
  _WebSocket(url, protocols) {
    const socket = new WebSocket(url, protocols);
    socket.onopen = e => this._onopen(e);
    socket.onerror = e => this._onerror(e);
    socket.onclose = e => this._onclose(e);
    socket.onmessage = e => this._onmessage(e);
    this.#_socket = socket;
  }

  /**
   * 用于指定连接关闭后的回调函数。
   * @param e {{CloseEvent}}
   * @private
   */
  _onclose(e) {
    if (this.#_worker) {
      this.#_worker.port.close();
    }
    this.#_readyState = e.target.readyState;
    this.onclose?.(e);
  }

  /**
   * 用于指定连接失败后的回调函数。
   * @param e {{Event}}
   * @private
   */
  _onerror(e) {
    this.#_readyState = e.target.readyState;
    this.onerror?.(e);
  }

  /**
   * 用于指定当从服务器接受到信息时的回调函数。
   * @param e {{MessageEvent}}
   * @private
   */
  _onmessage(e) {
    this.#_readyState = e.target.readyState;
    this.onmessage?.(e);
  }

  /**
   * 用于指定连接成功后的回调函数。
   * @param e {{Event}}
   * @private
   */
  _onopen(e) {
    this.#_readyState = e.target.readyState;
    this.onopen?.(e);
  }
}

/**
 * 异常断开重连时间间隔 5s
 * @type {number} 5s
 */
const RECONNECT_T = 5000;

/**
 * 尝试重连最大次数，超过次数后不再尝试
 * @type {number}
 */
const RECONNECT_MAX_NUMBER = 10;

/**
 * 用于存放成功的连接
 * {url: {id: DsfSocket}}
 * @type {Map<String, Map<String, DsfSocket>>}
 */
const ConnectMap = {};

/**
 * 用于存放监听的事件
 * {url: {id: {key: {router: [Function]}}}}
 * @type {Map<String, Map<String, Map<String, Map<String, Function[]>>>>}
 */
const ListenerMap = {};

/**
 * 用于存放初始化了的路由
 * {url: {id: {router: ''}}}
 * @type {Map<String, Map<String, Map<String, String>>>}
 */
const InitMap = {};

const protocol = location.protocol === 'http:' ? 'ws:' : 'wss:';

/**
 * 发送事件队列
 * 若发送事件发生在连接建立成功前，则将事件放在这里等待连接成功后进行
 * {url: {id: {key: [[router, data, unique]]}}}
 * @type {Map<String, Map<String, Map<String, String[][]>>>}
 */
const SendQueue = {};

const emptyObj = {
  $on: function () {return emptyObj},
  $off: function () {return emptyObj},
  $send: function () {return emptyObj},
  $one: function () {return emptyObj},
  $end: function () {return emptyObj},
  $close: function () {return emptyObj},
  $destroy: function () {return emptyObj}
}

/**
 * 判断router监听是否存在在当前url中
 * @param id
 * @param url
 * @param router
 */
function hasListener(id, url, router) {
  let router2 = router.split('://').pop();
  let map = ListenerMap[url]?.[id];
  if (!map) return false;
  for(let key in map) {
    if (map[key][router] || map[key][router2]) {
      return true;
    }
  }
  return false;
}

/**
 * 向后端发送订阅事件消息
 * @param id
 * @param url
 * @param params
 * @param router
 */
function sendActiveMessage(id, url, params, router) {
  router = router.split('://').pop();
  let skt = ConnectMap[url]?.[id];
  if (skt?.readyState === DsfSocket.OPEN && !InitMap[url][id][router]) {
    let authorization_token = dsf.cookies.get('authorization_token');
    skt.send(JSON.stringify({
      _header: {
        path: router,
        handshaking: 'ACTIVE',
        queryParams: params,
        authorization_token
      }
    }));
    InitMap[url][id][router] = true;
  }
}

/**
 * 向后端发送取消订阅事件消息
 * @param id
 * @param url
 * @param router
 */
function sendCloseMessage(id, url, router) {
  router = router.split('://').pop();
  let skt = ConnectMap[url]?.[id];
  if (skt?.readyState === DsfSocket.OPEN && !hasListener(id, url, router)) {
    let authorization_token = dsf.cookies.get('authorization_token');
    skt.send(JSON.stringify({
      _header: {
        path: router,
        handshaking: 'CLOSE',
        authorization_token
      }
    }));
    delete InitMap[url][id][router];
  }
}

function openSocket(id, key, url, options) {
  let wsUrl = url;
  // 将options.data参数拼接在url后面
  if (options.data) {
    wsUrl = _.map(options.data, function(v, k) {
      return `${k}=${v}`;
    });
    if (wsUrl.length) {
      wsUrl = url + '?' + wsUrl.join('&');
    } else {
      wsUrl = url;
    }
  }
  let socket = new DsfSocket(wsUrl);
  if (!ConnectMap[url]) ConnectMap[url] = {};
  ConnectMap[url][id] = socket;
  if (!InitMap[url]) InitMap[url] = {};
  InitMap[url][id] = {};
  if (!ListenerMap[url]) ListenerMap[url] = {};
  if (!ListenerMap[url][id]) ListenerMap[url][id] = {};
  if (!ListenerMap[url][id][key]) ListenerMap[url][id][key] = {};
  // 连接成功
  socket.onopen = function (e) {
    socket.__reconnectNumber = 0;
    // 初始化路由
    if (ListenerMap[url][id]) {
      _.forEach(ListenerMap[url][id], function (routerMap) {
        _.forEach(routerMap, function (fnArr, router) {
          sendActiveMessage(id, url, fnArr[0].header, router);
        });
      });
    }
    // 处理发送事件队列
    if (SendQueue[url]?.[id]) {
      let queue = SendQueue[url][id];
      delete SendQueue[url][id];
      let authorization_token = dsf.cookies.get('authorization_token');
      _.forEach(queue, function (sendParams) {
        _.forEach(sendParams, function ([router, data, unique]) {
          let _header = {
            path: router,
            authorization_token
          };
          if (unique) {
            _header[unique] = unique;
          }
          socket.send(JSON.stringify({_header, data}));
        });
      });
    }
    // 报告成功
    console.info(`"${url}"通道WebSocket连接成功!`);
    options?.open?.(e);
  };
  // 连接错误
  socket.onerror = function (e) {
    if (options?.error) {
      options.error(e);
    } else {
      // console.warn(`"${url}"通道WebSocket连接异常。\n异常信息：`, e);
      console.warn(`"${url}"通道WebSocket连接异常。`);
    }
  };
  // 连接关闭后的回调
  socket.onclose = function (e) {
    // 正常关闭
    if (e.code === 1000) {
      delete ConnectMap[url][id];
      delete ListenerMap[url][id];
      delete InitMap[url][id];
      delete SendQueue[url][id];
      console.info(`${e.code} "${url}"通道WebSocket连接已关闭!`);
      options?.close?.(e);
      socket = undefined;
    }
    // 非正常关闭
    else {
      // console.warn(`"${url}"通道WebSocket连接意外关闭，5s后尝试重新连接。\n异常信息：`, e);
      if (socket.__reconnectNumber === undefined) socket.__reconnectNumber = 0;
      if (socket.__reconnectNumber < RECONNECT_MAX_NUMBER) {
        console.warn(`${e.code} "${url}"通道WebSocket连接意外关闭，5s后尝试第${++socket.__reconnectNumber}次重新连接。`);
        setTimeout(() => {
          openSocket(id, key, url, options);
          ConnectMap[url][id].__reconnectNumber = socket.__reconnectNumber;
          socket = undefined;
        }, RECONNECT_T);
      } else {
        console.warn(`${e.code} "${url}"通道WebSocket连接意外关闭。`);
        socket = undefined;
      }
    }
  };
  // 从服务器接受到信息时的回调
  socket.onmessage = function (e) {
    let res = JSON.parse(e.data);
    let { _header: {path, unique}, data } = res;
    if (ListenerMap[url][id]) {
      if (unique) {
        path = '/' + unique + '://' + path;
      }
      _.forEach(ListenerMap[url][id], function (routerMap) {
        _.forEach(routerMap, function (fnArr, router) {
          if (router === path) {
            fnArr && _.forEach(fnArr, fn => fn(data, unique));
          }
        });
      });
    }
  };
}

/**
 * 新建一个webSocket连接
 * @param url
 * @param options
 * @param options.data    参数。在建立连接时，会把参数拼接在url后
 * @param options.open    连接建立成功的回调
 * @param options.error   连接异常的回调
 * @param options.close   连接关闭的回调
 * @param id { String | Boolean } true 始终开启新管道；字符串 复用id相同的管道
 */
function _ws(url, options, id) {
  let defaultRouter = undefined;
  let isReadConfig = false;
  switch (arguments.length) {
  case 0:
    url = dsf.config.setting_public_ws_url;
    options = {};
    id = '';
    break;
  case 1:
    if (arguments[0] === true) {
      url = dsf.config.setting_public_ws_url;
      options = {};
      id = dsf.uuid(32);
      isReadConfig = true;
    } else if (dsf.isObject(arguments[0])) {
      url = dsf.config.setting_public_ws_url;
      options = arguments[0];
      id = '';
      isReadConfig = true;
    } else {
      url = arguments[0];
      options = {};
      id = '';
    }
    break;
  case 2:
    if (dsf.isObject(arguments[0])){
      url = dsf.config.setting_public_ws_url;
      options = arguments[0];
      id = arguments[1];
      isReadConfig = true;
    } else if (dsf.isObject(arguments[1])) {
      url = arguments[0];
      options = arguments[1];
      id = '';
    } else {
      url = arguments[0];
      options = {};
      id = arguments[1];
    }
    break;
  default:
    url = arguments[0];
    options = arguments[1];
    id = arguments[1];
  }
  if (dsf.isString(url)) {
    if (url.indexOf('//') === 0) url = protocol + url;
    if (url !== dsf.config.setting_public_ws_url) {
      if (url.indexOf(dsf.config.setting_public_ws_url) == 0) {
        defaultRouter = url.replace(dsf.config.setting_public_ws_url, '');
      } else if (url.indexOf('ws://') === -1 && url.indexOf('wss://') === -1) {
        defaultRouter = url;
        url = dsf.config.setting_public_ws_url;
        isReadConfig = true;
      }
    }
  } else {
    dsf.warn(socketUrlErrorMessage);
    options?.error?.({
      message: socketUrlErrorMessage
    });
    return emptyObj;
  }
  if (!url) {
    let message = isReadConfig ? 'setting_public_ws_url配置错误' : socketUrlErrorMessage;
    dsf.warn(message);
    options?.error?.({message});
    return emptyObj;
  }
  options = options || {};
  id = id === true ? dsf.uuid(32) : (id || '');
  let key = dsf.uuid(16);
  let socket = ConnectMap[url]?.[id];
  // 单例
  if (socket) {
    // 正在链接中
    if (socket.readyState === DsfSocket.CONNECTING) {
      options?.open?.();
      if (typeof options?.error === 'function') socket.addEventListener('error', options.error);
      if (typeof options?.close === 'function') socket.addEventListener('close', options.close);
    }
    // 已经链接并且可以通讯
    else if (socket.readyState === DsfSocket.OPEN) {
      if (typeof options?.open === 'function') socket.addEventListener('open', options.open);
      if (typeof options?.error === 'function') socket.addEventListener('error', options.error);
      if (typeof options?.close === 'function') socket.addEventListener('close', options.close);
    } else {
      delete ConnectMap[url][id];
      socket = null;
    }
  }
  // 连接不存在
  if (!socket) {
    openSocket(id, key, url, options);
  }
  return {
    /**
     * 订阅一个事件
     * @param router {String}
     * @param header  {Object}
     * @param callback {Function}
     */
    $on(router, header, callback) {
      switch (arguments.length) {
      case 0:
        dsf.error('订阅事件参数不能为空');
        return;
      case 1:
        if (dsf.isFunction(arguments[0])) {
          router = defaultRouter;
          header = {};
          callback = arguments[0];
        } else {
          dsf.error('订阅事件参数错误');
          return;
        }
        break;
      case 2:
        if (dsf.isString(arguments[0]) && dsf.isFunction(arguments[1])) {
          router = arguments[0];
          header = {};
          callback = arguments[1];
        } else if (dsf.isObject(arguments[0]) && dsf.isFunction(arguments[1])) {
          router = defaultRouter;
          header = arguments[0];
          callback = arguments[1];
        } else {
          dsf.error('订阅事件参数错误');
          return;
        }
        break;
      default:
        if (dsf.isString(arguments[0]) && dsf.isObject(arguments[1]) && dsf.isFunction(arguments[2])) {
          router = arguments[0];
          header = arguments[1];
          callback = arguments[2];
        } else {
          dsf.error('订阅事件参数错误');
          return;
        }
      }
      callback.header = header;
      if (!/^\//.test(router)) router = '/' + router;
      if (!/\/$/.test(router)) router += '/';
      let skt = ConnectMap[url][id];
      if (skt?.readyState === DsfSocket.OPEN) {
        sendActiveMessage(id, url, header, router);
      }
      if (!ListenerMap[url][id][key]) ListenerMap[url][id][key] = {};
      if (!ListenerMap[url][id][key][router]) ListenerMap[url][id][key][router] = [];
      ListenerMap[url][id][key][router].push(callback);
      return this;
    },
    /**
     * 取消订阅
     * @param router
     * @param callback  不传则表示取消所有订阅
     */
    $off(router, callback) {
      if (dsf.isFunction(router)) {
        callback = router;
        router = defaultRouter;
      }
      if (!/^\//.test(router)) router = '/' + router;
      if (!/\/$/.test(router)) router += '/';
      if (ListenerMap[url][id][key][router]) {
        if (!callback) {
          delete ListenerMap[url][id][key][router];
          sendCloseMessage(id, url, router);
        } else {
          let index = ListenerMap[url][id][key][router].indexOf(callback);
          ListenerMap[url][id][key][router].splice(index, 1);
          if (!ListenerMap[url][id][key][router].length) {
            delete ListenerMap[url][id][key][router];
            sendCloseMessage(id, url, router);
          }
        }
      }
      return this;
    },
    /**
     * 向服务器发送数据
     * @param router
     * @param data
     * @param lazy   true表示，动作发生在连接建立成功前，则将会放入队列中等待连接成功后进行
     */
    $send(router, data, lazy) {
      let skt = ConnectMap[url][id];
      if (skt?.readyState === DsfSocket.OPEN) {
        let authorization_token = dsf.cookies.get('authorization_token');
        skt.send(JSON.stringify({
          _header: {
            path: router,
            authorization_token
          },
          data
        }));
      } else if (!lazy) {
        throw new Error('当前通道处于不可通信状态');
      } else {
        if (!SendQueue[url]) {
          SendQueue[url] = {};
        }
        if (!SendQueue[url][id]) {
          SendQueue[url][id] = {};
        }
        if (!SendQueue[url][id][key]) {
          SendQueue[url][id][key] = [];
        }
        SendQueue[url][id][key].push([router, data]);
      }
      return this;
    },
    /**
     * 一问一答
     * @param router
     * @param data
     * @param lazy    true表示，动作发生在连接建立成功前，则将会放入队列中等待连接成功后进行
     * @returns {Promise<unknown>}
     */
    $one(router, data, lazy) {
      let _this = this;
      let unique = dsf.uuid();
      let path = router;
      router = router.replace(/\?.*/, '/');
      if (!/^\//.test(router)) router = '/' + router;
      if (!/\/$/.test(router)) router += '/';
      router = unique + '://' + router;
      return new Promise(function(resolve, reject) {
        let skt = ConnectMap[url][id];
        let callback = function (data, unique2) {
          if (unique2 === unique) {
            _this.$off(router, callback);
            resolve(data);
          }
        };
        if (skt?.readyState === DsfSocket.OPEN) {
          let authorization_token = dsf.cookies.get('authorization_token');
          skt.send(JSON.stringify({
            _header: {
              unique,
              path,
              authorization_token
            },
            data
          }));
          _this.$on(router, callback);
        } else if (!lazy) {
          callback = undefined;
          reject({
            message: '当前通道处于不可通信状态'
          });
        } else {
          if (!SendQueue[url]) {
            SendQueue[url] = {};
          }
          if (!SendQueue[url][id]) {
            SendQueue[url][id] = {};
          }
          if (!SendQueue[url][id][key]) {
            SendQueue[url][id][key] = [];
          }
          SendQueue[url][id][key].push([path, data, unique]);
          _this.$on(router, callback);
        }
      });
    },
    /**
     * 结束当前作用域
     */
    $end() {
      if (SendQueue[url]?.[id]?.[key]) {
        delete SendQueue[url][id][key];
      }
      if (ListenerMap[url]?.[id]?.[key]) {
        let map = ListenerMap[url][id][key]
        delete ListenerMap[url][id][key];
        _.forEach(map, function(fnArr, router) {
          sendCloseMessage(id, url, router);
        });
      }
      this.$on = dsf.noop;
      this.$off = dsf.noop;
      this.$send = dsf.noop;
      this.$end = dsf.noop;
    },
    /**
     * 关闭一个连接通道
     * 先执行$end，若没有了订阅，则执行$destroy
     * @param code    同原生
     * @param reason  同原生
     */
    $close(code, reason) {
      if (SendQueue[url]?.[id]?.[key]) {
        delete SendQueue[url][id][key];
      }
      let map = null;
      if (ListenerMap[url]?.[id]?.[key]) {
        map = ListenerMap[url][id][key]
        delete ListenerMap[url][id][key];
      }
      if (!Object.keys(ListenerMap[url][id]).length) {
        this.$destroy(code, reason);
      } else {
        _.forEach(map, function(fnArr, router) {
          sendCloseMessage(id, url, router);
        });
      }
    },
    /**
     * 强制关闭一个连接通道
     * @param code    同原生
     * @param reason  同原生
     */
    $destroy(code, reason) {
      let skt = ConnectMap[url][id];
      if (skt) {
        skt.close(code, reason);
        delete ConnectMap[url][id];
      }
      if (SendQueue[url]?.[id]) {
        delete SendQueue[url][id];
      }
      if (ListenerMap[url]?.[id]) {
        delete ListenerMap[url][id];
      }
      this.$on = dsf.noop;
      this.$off = dsf.noop;
      this.$send = dsf.noop;
      this.$end = dsf.noop;
      this.$close = dsf.noop;
      this.$destroy = dsf.noop;
    }
  }
}

let win = handler.getTopWindow();
const ws = (canWorker || win === window) ? _ws : win.dsf.ws;

export function wsInstall(Vue) {
  Vue.prototype.$ws = ws;
}

export default ws;