export default {
  inject: {
    $buttonOwner: {
      default: null
    },
    $isPreView: {
      default: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    isButtonBar: {
      type: Boolean,
      default: true
    },
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "center",
    },
    emitPrefix: {
      type: String,
      default: "",
    },
    renderButton: {
      type: Function,
      default: null,
    },
    disabledButton: {
      type: Function,
      default: null,
    },
    maxNumber: {
      type: Number,
      default: 2,
    },
    moreButtonStyle: {
      type: String,
      default: "",
    },
    moreButtonText: {
      type: String,
      default: "更多",
    }
  },
  data() {
    return {
      client: "pc",
      buttonHandler: null,
      sPID: null,
      sCurNodeID: null,
      sCurLinkID: null
    }
  },
  computed: {
    owner() {
      return this.$buttonOwner;
    },
  },
  mounted() {
    if (!this.isDesign) {
      let data;
      if (this.$vm?.$dialog?.dialogArgs?.data) {
        data = this.$vm.$dialog.dialogArgs.data[0];
      }
      this.sPID = data?.sPID || this.$route.query.sPID || this.queryString.sPID;
      this.sCurNodeID = data?.sCurNodeID || this.$route.query.sCurNodeID || this.queryString.sCurNodeID;
      this.sCurLinkID = data?.sCurLinkID || this.$route.query.sCurLinkID || this.queryString.sCurLinkID;
      this.initFlowBtns();
    }
  },
  methods: {
    isFunctionCode(code) {
      let rfunction = /^\s*function(\s+\w*)?\(.*\)\s*{/g;
      return rfunction.test(code);
    },
    execFunction(button, owner, v) {
      let code = [
        "var result= (" + v + ").call(this,data,button,owner,self);",
        "data=null;",
        "button=null;",
        "owner=null;",
        "self=null;",
        "return result;"
      ];
      let fn = new Function("data", "button", "owner", "self", code.join("\n"));
      let result = fn.call(this.$vm, this.local, button, owner, this);
      result = this.$replace(result, this.local);
      return result;
    },
    getButtonParams(button) {
      let owner = this.owner || this;
      let params = _.map(button.params, (p) => {
        let paramValue = null;
        if (dsf.type(p.value) == "string") {
          let v = p.value.trim();
          if (this.isFunctionCode(v)) {
            paramValue = this.execFunction(button, owner, v);
          } else {
            let obj = this.$expressParams(this.local);
            let value = this.$replace(v, obj);
            paramValue = value;
          }
        } else if (dsf.type(p.value) == "object") {
          if (p.value.type == "static") {
            let v = p.value.code;
            let obj = this.$expressParams(this.local);
            let value = this.$replace(v, obj);
            paramValue = value
          } else if (p.value.type == "switch") {
            let v = this.local[p.value.code.field];
            let value = "";
            if (p.value.code.value[v]) {
              value = p.value.value[v];
            }
            let obj = this.$expressParams(this.local);
            value = this.$replace(value, obj);
            paramValue = value
          } else {
            let v = p.value.code;
            if (this.isFunctionCode(v)) {
              paramValue = this.execFunction(button, owner, v);
            }
          }
        }
        if (paramValue == "" || dsf.isUnDef(paramValue)) {
          paramValue = p.default || null;
        }
        if (paramValue === "false") {
          paramValue = false;
        } else if (paramValue === "true") {
          paramValue = true;
        }
        return paramValue;
      });
      return params;
    },
    async clickHandler(button, $event, response) {
      if (this.isDesign) {
        return;
      }
      if (this.$isPreView) {
        dsf.layer.message("预览状态下按钮不可执行此操作", false)
        return;
      }
      if (!button.execing) {
        button.execing = true;
        try {
          if (button.disabled || this.isDesign) {
            return;
          }
          if (button.secondConfirm) {
            dsf.layer
              .confirm(this.$replace(button.secondConfirmText || "确认提交？", this.local))
              .then(() => {
                this.buttonAction(button, $event, response);
              })
              .catch(() => {
              });
          } else {
            this.buttonAction(button, $event, response);
          }
        } catch (error) {
          dsf.error(error);
        } finally {
          button.execing = false;
        }
      }
    },
    async buttonAction(button, $event, response) {
      if (button?.children && button.children.length > 0) return;

      let owner = this.owner || this;
      if (dsf.isUnDef(button.loading)) {
        this.$set(button, 'loading', false);
      }
      //如果是系统按钮
      if (button.isSystem) {
        let handler = this.buttonHandler(button.actionName, this.client);
        let params = this.getButtonParams(button);
        if (handler && dsf.isFunction(handler)) {
          //系统按钮
          let param2 = {
            owner: owner,
            data: this.local,
            _self: this,
            targetButton: button,
            params: params
          };
          if (response) {
            param2.response = response;
          }
          await handler.call(this.$vm, param2, $event);
        }
      } else {
        //自定义按钮
        let eventName = (this.emitPrefix ? this.emitPrefix + "-" : "") + button.actionName;
        let handler = owner.$listeners[eventName];
        if (handler) {
          if (dsf.isFunction(handler)) {
            let params = {
              owner: owner,
              data: this.local,
              _self: this,
              targetButton: button
            };
            if (response) {
              params.response = response;
            }
            await handler(params, $event);
          }
        }
        else {
          //通过动作名字直接找对应的页面中的函数
          let eventName = button.actionName;
          if (eventName && this.$vm[eventName] && dsf.isFunction(this.$vm[eventName])) {

            let params = {
              owner: owner,
              data: this.local,
              _self: this,
              targetButton: button
            };
            if (response) {
              params.response = response;
            }
            await this.$vm[eventName](params, $event);
          }
          else {
            this.$message("设置的动作名称" + eventName + "不存在或不是一个函数", false);
          }
        }
      }
      button.execing = false;
    },
    addFlowBtns(button, btns, index) {
      if (!btns && this.flowButtons.length > 0) {
        button.popVisible = !button.popVisible;
        setTimeout(() => {
          document.addEventListener("click", this.hidePopVisible);
        }, 0);
        return;
      }
      let sWFID;
      dsf.recursionComponentProps(this.$page.$props, (data) => {
        if (data.isFormControl && data.caption === "wfinfo") {
          sWFID = data.chooseFlow && data.chooseFlow.id;
        }
      });
      if (sWFID) {
        this.$http
          .get("/wfr/getLines", {
            sWFID: sWFID,
            sPID: this.sPID,
            sCurNodeID: this.sCurNodeID,
            sCurLinkID: this.sCurLinkID,
            sExInfos: "",
          })
          .then((res) => {
            if (res.data.success) {
              const data = res.data.data;
              const lines = data && data.SendParams && data.SendParams.Lines;
              if (lines) {
                lines.forEach((line) => {
                  if (line.Name === "审核通过") {
                    line.order = 0;
                  } else if (line.Name === "返回修改") {
                    line.order = 1;
                  } else if (line.Name === "审核不通过") {
                    line.order = 2;
                  } else {
                    line.order = 1;
                  }
                });
                lines.sort((a, b) => a.order - b.order);
                lines.forEach((line, i) => {
                  const newButton = Object.assign({}, button, {
                    caption: line.Name,
                    text: line.Name,
                    title: line.Name,
                    line: line,
                  });
                  if (!btns) {
                    this.flowButtons.push(newButton);
                    this.$set(button, "popVisible", true);
                    document.addEventListener("click", this.hidePopVisible);
                  } else {
                    // 重排页面
                    if (!dsf.global.$isMobile) {
                      setTimeout(() => {
                        newButton.style = {
                          width: "auto",
                          transform: "scale(1)",
                        };
                      }, 0);
                    }
                    btns.splice(index + i + 1, 0, newButton);
                  }
                });
                btns && btns.splice(btns.indexOf(button), 1);
              }
            }
          })
          .catch((res) => { });
      }
    },
    // 流程类按钮
    hideFlowBtn(btn) {
      const actionName = btn.actionName;
      if (!this.sPID) {
        return actionName === "system_flow_send" || actionName === "system_batch_flow_send" || actionName === "system_flow_openDialog";
      }
      if (this.$vm?.flows?.flowCurInfo) {
        const flowCurInfo = this.$vm.flows.flowCurInfo;
        if (flowCurInfo.STATUS === 2) {
          // 回收 强制回收 显示
          return actionName === "system_flow_forceRecovery" || actionName === "system_flow_recovery";
        } else {
          return actionName !== "system_flow_forceRecovery" && actionName !== "system_flow_recovery";
        }
      }
      return true;
    },
    initFlowBtns() {
      let hasFlowBtn = false;
      let autoReceive = false;
      this.buttons.forEach((button, index) => {
        //将按钮执行状态设置为false
        button.execing = false;
        // 按钮是流程发送按钮或者是批量发送按钮 配置了分环节发送 设置了不折叠后需要加载
        let isLinkSend = button.isLinkSend;
        let isLinkSendFold = button.isLinkSendFold;
        let actionName = button.actionName;
        let sendButtons = ["system_flow_send", "system_batch_flow_send"];
        if (button.isSystem && isLinkSend && !isLinkSendFold && sendButtons.indexOf(actionName) >= 0) {
          // 为了解决ie显示一般问题
          if (dsf.client.type && dsf.client.type.indexOf("IE") > -1) {
            button.style = {
              transform: "scale(0.99)",
            };
          }
          this.addFlowBtns(button, this.buttons, index);
        }
        if (button.isSystem && (actionName.indexOf("system_flow") > -1 || actionName.indexOf("system_batch_flow") > -1)) {
          hasFlowBtn = true;
        }
        if (button.autoReceive) {
          autoReceive = true;
        }
      });
      // 如果有流程按钮 需要查接口 看当前按钮是否显示
      if (hasFlowBtn) {
        this.getLinkCurInfo(autoReceive);
      }
    },
    getLinkCurInfo(autoReceive) {
      if (this.$vm?.$dialog?.dialogArgs?.data || this.$route.query.sPID || this.queryString.sPID) {
        let data = {};
        if (this.$vm?.$dialog?.dialogArgs?.data) {
          data = this.$vm.$dialog.dialogArgs.data[0];
        }
        this.$http
          .get("/wfr/getLinkInfoByLinkID", {
            sPID: this.sPID,
            sLinkID: this.sCurLinkID,
          })
          .then(({ data }) => {
            if (data.success) {
              if (this.$vm?.flows) {
                this.$set(this.$vm.flows, "flowCurInfo", data.data);
              }
              // 自动签收
              if (data.data.STATUS == 0 && autoReceive) {
                this.autoReceive();
              }
            }
          });
      }
    },
    autoReceive() {
      // 自动签收
      this.$http
        .post("/wfr/receive", {
          sPID: this.sPID,
          sCurLinkID: this.sCurLinkID,
        })
        .then(({ data }) => {
          if (data.success) {
            if (data?.data?.Code === 23000) {
              this.$vm.flows?.flowProcess?.forEach((item) => {
                if (this.sCurLinkID === item.linkid) {
                  item.rusername = dsf.cookies.get("user_name");
                  item.ruserid = '';
                  item.STATUS = 1;
                }
              });
            }
            // console.log('签收成功')
          }
        });
    }
  }
}