export default {
  type: "dingTalk",
  isEnv() {
    return /\DingTalk/.test(navigator.userAgent)
  },
  init(opt = {}) {
    try {
      return new Promise((resolve, reject) => {
        let { sdkUrl } = opt
        if (!sdkUrl) {
          throw '请传入dingTalk.sdkUrl'
        }
        dsf.http.importFiles(opt.sdkUrl).then(([{ state }]) => { // 加载sdk
          if (!state) {
            console.error("钉钉sdk加载出错")
            reject(`${sdkUrl}文件出错`)
          }
          dd.ready(() => {
            //钉钉禁止ios页面在上下边界滑动时页面弹动效果
            dd?.ui?.webViewBounce?.disable?.();
            resolve()
          })
        }).catch((err) => {
          reject(err)
        })
      })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  methods: {
    // 获取授权码
    getAuthCode(opt = {}) {
      return new Promise((resolve, reject) => {
        try {
          let _opt = this._getFuncOpts(
            {
              corpId: 'ding58a6e4aef9bb5cfa35c2f4657eb6378f'
            },
            opt
          )
          if (!_opt.corpId) throw '请传入corpId'
          dd.runtime.permission.requestAuthCode({
            corpId: _opt.corpId,
            onSuccess: res => {
              if (res.code) {
                resolve(res.code)
              } else {
                throw res
              }
            },
            onFail: err => {
              reject(err)
            },
          });
        } catch (error) {
          reject(error)
        }
      })
    },
    // 扫一扫
    scan(opt = {}) {
      return new Promise((resolve, reject) => {
        try {
          let _opt = this._getFuncOpts(
            {
              type: 'all' // 类型 qrCode 二维码  barCode 条形码 all 所有
            },
            opt
          )
          dd?.biz?.util?.scan({
            type: _opt.type, // type 为 all、qrCode、barCode，默认是all。
            onSuccess: (data) => {
              resolve(data.text)
            },
            onFail: (err) => {
              reject(err)
            }
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    // 拨打电话
    callPhone(opt = {}) {
      return new Promise((resolve, reject) => {
        try {
          let _opt = this._getFuncOpts(
            {
              phoneNumber: '', // 类型 qrCode 二维码  barCode 条形码 all 所有
              code: '+86',  // 国家代号，中国是+86。
              showDingCall: false, // 是否显示钉钉电话。
            },
            opt
          )
          if (!_opt.phoneNumber) throw '请传入phoneNumber电话号码'
          dd.biz.telephone.showCallMenu({
            phoneNumber: _opt.phoneNumber, // 期望拨打的电话号码
            code: _opt.phoneNumber.code, // 国家代号，中国是+86
            showDingCall: _opt.phoneNumber.showDingCall, // 是否显示钉钉电话
            onSuccess: function () {
              resolve()
            },
            onFail: function () {
              reject()
            }
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    // 设备唯一标识
    getDeviceCode() {
      return new Promise((resolve, reject) => {
        try {
          dd.device.base.getUUID({
            onSuccess: (data) => {
              resolve(data.uuid)
            },
            onFail: (err) => {
              reject(err);
            }
          });
        } catch (error) {
          reject(error)
        }
      })
    },
    // 新开页面
    open(opt = {}) {
      return new Promise((resolve, reject) => {
        let _opt = this._getFuncOpts(
          {
            url: '', // 网址
          },
          opt
        )
        if (!_opt.url) throw '请传入url'
        try {
          dd.biz.util.openLink({
            url: _opt.url,//要打开链接的地址
            onSuccess: function (result) {
              /**/
              resolve()
            },
            onFail: function (err) {
              reject(err)
            }
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    // 设置 浏览器 title
    navigationSetTitle(opt = {}) {
      return new Promise((resolve, reject) => {
        try {
          let _opt = this._getFuncOpts(
            {
              title: '', // 网址
            },
            opt
          )
          dd.biz.navigation.setTitle({
            title: _opt.title,//控制标题文本，空字符串表示显示默认文本
            onSuccess: function (result) {
              resolve(result)
            },
            onFail: function (err) {
              reject(err)
            }
          });

        } catch (error) {
          reject(error)
        }
      })
    }
  }
}