import helper from './helper';

let validatorMap = {
  'isNull': {
    name: "是否为空",
    validator(value) {
      let v = value;
      if (dsf.type(v) == 'string') {
        v = v.trim();
      }
      if (v === '' || v == "[]" || v == "{}") {
        return true;
      } else if (dsf.type(v) == 'object' && dsf.isEmptyObject(v)) {
        return true;
      } else if (dsf.type(v) == 'array' && v.length == 0) {
        return true;
      } else if (dsf.isUnDef(v)) {
        return true;
      }
      return false;
    }
  },
  // 验证是否是一个数字
  "isNumber": {
    name: "是否是一个数字",
    validator(value, precision) {
      let reg = null;
      if (precision && precision > 0) {
        reg = new RegExp("^-?([0-9]\\d*|0(?!\\.0+$))(\\.\\d{0," + precision + "})?$", 'ig');
      } else {
        reg = new RegExp("^[+-]?(\\d|[1-9]\\d+)(\\.\\d+)?$", 'ig');
      }
      if (!reg.test(value)) {
        return false;
      }
      return true;
    }
  },
  // 验证数字是否超出
  "isNumberOver": {
    name: "是否超出指定数字",
    validator(value, ceil) {
      value = parseFloat(value);
      ceil = parseFloat(ceil);
      if (value > ceil) {
        return true;
      }
      return false;
    }
  },
  // 验证数字是否小于
  "isNumberUnder": {
    name: "是否小于指定数字",
    validator(value, floor) {
      value = parseFloat(value);
      floor = parseFloat(floor);
      if (value < floor) {
        return true;
      }
      return false;
    }
  },
  // 验证数字精度
  "precision": {
    name: "数字精度",
    validator(value, precision) {
      value = value.toString();
      const reg = new RegExp("^-?([0-9]\\d*|0(?!\\.0+$))(\\.\\d{0," + precision + "})?$", 'ig');
      if (!reg.test(value)) {
        return false;
      }
      return true;
    }
  },
  "maxLength": {
    name: "字符串超出指定长度",
    validator(value, maxlength) {
      if (value.length > maxlength) {
        return true;
      }
      return false;
    }
  },
  "minLength": {
    name: "字符小于指定长度",
    validator(value, minLength) {
      if (value.length < minLength) {
        return true;
      }
      return false;
    }
  },
  // 验证是否为日期时间
  "isDate": {
    name: "是否为日期时间",
    validator(value, format = 'yyyy-mm-dd') {
      try {
        let d = dsf.date.parse(value, format);
        if (d == 'Invalid Date' || (d.getFullYear() + '').length > 4) {
          if (value.length != format.length) {
            return false;
          }
          ['y', 'M', 'm', 'D', 'd', 'H', 'h', 'i', 's'].forEach(v => {
            let i = format.indexOf(v);
            if (i > -1) {
              value = value.substring(0, i) + '-' + value.substr(i);
              format = format.substring(0, i) + '-' + format.substr(i);
            }
          });
          d = dsf.date.parse(value, format);
        }
        if (d == 'Invalid Date') {
          return false;
        }
        let value2 = dsf.date.format(d, format);
        return value == value2;
      } catch (e) {
        return false;
      }
    }
  },
  // 身份证号码
  'isIDCard': {
    name: "身份证号码",
    type: "express",
    validator(value) {
      //身份证正则表达式(15位)
      const isIDCard1 = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/;
      //身份证正则表达式(18位)
      const isIDCard2 = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if (!isIDCard1.test(value) && !isIDCard2.test(value)) {
        return false;
      }
      return true;
    }
  },
  // 身份证号码(强校验)
  'isIDCardFull': {
    name: "身份证号码",
    type: "express",
    validator(value) {
      const res = dsf.idCode(value);
      return res.type;
    }
  },
  // 是否是手机
  'isMobile': {
    name: "手机号码",
    type: "express",
    validator(value) {
      var reg = /^1(3|4|5|7|8|9|6)\d{9}$/;
      if (!reg.test(value)) {
        return false;
      }
      return true;
    }
  },
  // 是否是中文
  'isChinese': {
    name: "中文",
    type: "express",
    validator(value) {
      var reg = /^[\u4e00-\u9fa5]+$/;
      if (!reg.test(value)) {
        return false;
      }
      return true;
    }
  },
  // 是否是座机
  "isTelPhone": {
    name: "座机号码",
    type: "express",
    validator(value) {
      var reg = /^(0\d{2,3}-)?\d{7,8}$/;
      if (!reg.test(value)) {
        return false;
      }
      return true;
    }
  },
  // 是否是手机或座机
  "isMobileOrTelPhone": {
    name: "手机/座机号码",
    type: "express",
    validator(value) {
      var mobile = /^1(3|4|5|7|8|9|6)\d{9}$/;
      var tel = /^(0\d{2,3}-)?\d{7,8}(-\d{2,6})?$/;
      if (!mobile.test(value) && !tel.test(value)) {
        return false;
      }
      return true;
    }
  },
  // 电子邮箱
  'isEmail': {
    name: "电子邮箱",
    type: "express",
    validator(value) {
      var reg = /\w+((-w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+/;
      if (!reg.test(value)) {
        return false;
      }
      return true;
    }
  },
  // 车牌号码
  "isPlateNumber": {
    name: "车牌号码",
    type: "express",
    validator(value) {
      var reg = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([ABCDEF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/;
      if (!reg.test(value)) {
        return false;
      }
      return true;
    }
  },
  // 是否是字母和数字的混合组成
  "isCombination": {
    name: "字母和数字的混合",
    type: "express",
    validator(value) {
      var number = /[0-9]+/;
      var ss = /[a-zA-Z]+/;
      if (!number.test(value) || !ss.test(value)) {
        return false;
      }
      return true;
    }
  },
  // 是否是邮政编码
  "isPostcode": {
    name: "邮政编码",
    type: "express",
    validator(value) {
      var number = /^\d{6}$/;
      return number.test(value);
    }
  }
}

let fn = {
  getDefaultErrorMsg() {
    return {

      /**过期，后续不再使用 */
      isNumber: "该项必须填写数字",
      /**过期，后续不再使用 */
      min: "该项长度不可小于@[min]个字符",
      /**过期，后续不再使用 */
      max: "该项长度不可大于@[max]个字符",
      
      required: "该项必须填写",
      arrayMin: "该项最少选择@[arrayMin]个",
      arrayMax: "该项最多选择@[arrayMax]个",
      numberMin: "该项不可小于@[numberMin]",
      numberMax: "该项不可大于@[numberMax]",
      maxLength: "该项内容长度必须在@[minLength]-@[maxLength]个字符",
      number:"该项必须填写数字",
      string:"该项内容长度必须在@[minLength]-@[maxLength]个字符",
      express: "@[expressName]格式不正确",
      precision: "小数点后最多保留@[precision]位小数",
      array:"该项只能选择@[arrayMin]-@[arrayMax]个"
    }
  },
  getMetaDataValidateObject() {
    return {
      required: false,
      /**过期，后续不再使用 */
      isNumber: false,
      minLength: "",
      maxLength: "",
      numberMax: "",
      numberMin: "",
      arrayMin: "",
      arrayMax: "",
      express: "",
      expressName: "",
      precision: "",
      errorMsg: ""
    }
  }
}

let validate = function () {
  let args = Array.prototype.slice.call(arguments, 0);
  let validator = args[0];
  let params = args.slice(1);
  return validatorMap[validator].validator.apply(this, params);
}

if (window.$$validate) {
  helper.mix(validatorMap, window.$$validate)
}
validate.map = validatorMap;
validate.fn = fn;
export default validate;