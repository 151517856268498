const bodyClass = document.body.classList;

/**
 * 主题设置相关
 */
export default themesConfig => {
  const state = Vue.observable({
    themesConfig,
    setting: {
      // 全局字体大小：normal，bigFont
      fontSize: dsf.config.setting_public_default_size || 'normal',
      // 全局主题
      theme: dsf.config.setting_public_default_theme || 'darkred-theme',
      // 默认顶部导航栏是否窄条
      defaultHeaderMini: dsf.config.setting_public_default_header_mini || false,
      // 默认侧边导航栏是否窄条
      defaultAsideMini: dsf.config.setting_public_default_aside_mini || false,
    }
  });
  Object.defineProperty(state, 'currentTheme', {
    enumerable: true,
    get: function () {
      return state.themesConfig[state.setting.theme];
    }
  });
  // 获取主题色
  Object.defineProperty(state, 'getThemeColor', {
    enumerable: false,
    get: function () {
      return function (key = 'normal') {
        if (key[0] == '@') {
          key = key.substring(1);
        }
        return this.currentTheme[key] || null;
      }
    }
  });
  // 设置字体大小
  Object.defineProperty(state, 'setFontSize', {
    enumerable: false,
    get: function () {
      return function (to = dsf.config.setting_public_default_size || 'normal') {
        Vue.set(state.setting, 'fontSize', to);
      }
    }
  });
  // 设置主题
  Object.defineProperty(state, 'setTheme', {
    enumerable: false,
    get: function () {
      return function (to = dsf.config.setting_public_default_theme || 'darkred-theme') {
        Vue.set(state.setting, 'theme', to);
      }
    }
  });
  // 设置默认顶部导航栏是否窄条
  Object.defineProperty(state, 'setDefaultHeaderMini', {
    enumerable: false,
    get: function () {
      return function (to = dsf.config.setting_public_default_header_mini || false) {
        Vue.set(state.setting, 'defaultHeaderMini', to);
      }
    }
  });
  // 设置默认顶部导航栏是否窄条
  Object.defineProperty(state, 'setDefaultAsideMini', {
    enumerable: false,
    get: function () {
      return function (to = dsf.config.setting_public_default_aside_mini || false) {
        Vue.set(state.setting, 'defaultAsideMini', to);
      }
    }
  });
  dsf.themeConfig = state;

  return {
    data() {
      return {
        isDesigner:false,
        userSetting:{}
      }
    },
    computed: {
      themesConfig() {
        return state.themesConfig;
      },
      setting() {
        return state.setting;
      },
      currentTheme() {
        return state.currentTheme;
      }
    },
    watch: {
      'setting.fontSize'(to, from) {
        if(!this.isDesigner){
          bodyClass.remove(from);
          bodyClass.add(to);
        }
        dsf.storage.local.set("themeConfig", this.setting);
        this.updateIFrame('setFontSize', to, from);
      },
      'setting.theme'(to, from) {
        bodyClass.remove(from);
        bodyClass.add(to);
        dsf.storage.local.set("themeConfig", this.setting);
        this.updateIFrame('setTheme', to, from);
      },
      'setting.defaultHeaderMini'() {
        dsf.storage.local.set("themeConfig", this.setting);
      },
      'setting.defaultAsideMini'() {
        dsf.storage.local.set("themeConfig", this.setting);
      },
    },
    created() {
      if(window.location.href.indexOf("designer.html")>0){
        this.isDesigner=true
      }
      let themeConfig1 = dsf.custom.getSystem('themeConfig');
      let themeConfig2 = dsf.storage.local.get("themeConfig");
      let themeConfig = themeConfig1 || themeConfig2;
      if (themeConfig && !dsf.isEmptyObject(themeConfig)) {
        state.setting = dsf.mix(state.setting, themeConfig, this.userSetting);
      }
      if(!this.isDesigner){
        bodyClass.add(state.setting.fontSize);
        this.updateIFrame('setFontSize', state.setting.fontSize);
      }
      bodyClass.add(state.setting.theme);
      this.updateIFrame('setTheme', state.setting.theme);
      window.$$themeConfig = state.setting
    },
    methods: {
      // 获取主题色
      getThemeColor(key = 'normal') {
        return state.getThemeColor(key);
      },
      /**
       * 设置字体大小
       * @param to {String}
       *    **normal**：正常字体大小14px
       *    **bigFont**：字体大小16px。
       */
      setFontSize(to = dsf.config.setting_public_default_size || 'normal') {
        state.setFontSize(to);
      },
      /**
       * 设置主题
       * @param to {String} 类名
       */
      setTheme(to = dsf.config.setting_public_default_theme || 'darkred-theme') {
        state.setTheme(to);
      },
      /**
       * 设置默认顶部导航栏是否窄条
       * @param to
       */
      setDefaultHeaderMini(to = dsf.config.setting_public_default_header_mini || false) {
        state.setDefaultHeaderMini(to);
      },
      /**
       * 设置默认顶部导航栏是否窄条
       * @param to
       */
      setDefaultAsideMini(to = dsf.config.setting_public_default_aside_mini || false) {
        state.setDefaultAsideMini(to);
      },
      // 把主题注入到iframe中
      updateIFrame(funName, to, from) {
        for (let i = 0; i < window.frames.length; i++) {
          let win = window.frames[i];
          //判断子窗口是否引入的dsf-sdk.js
          if (win?.$dsf?.sdk) {
            let watchType = "";
            let watchValue = null;
            if (funName == 'setFontSize') {
              watchType = "font-size";
              watchValue = state.setting.fontSize;
            }
            else {
              watchType = "theme";
              watchValue = state.setting.theme;
            }
            win?.dsf?.triggerThemeConfigChange?.(watchType,watchValue)
          }
          else {
            let vueApp = win.document.getElementById("app");
            vueApp = vueApp && vueApp.__vue__ && vueApp.__vue__.$root;
            let fun = null;
            // 如果嵌套的iframe是5.0平台的页面
            if (vueApp && vueApp.setting) {
              fun = vueApp[funName];
            }
            // 嵌套其他iframe
            else {
              fun = win[funName];
            }
            fun && fun(to, from);
          }

        }
      }
    }
  }
}