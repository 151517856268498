/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2022-08-12 16:12:36
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-08-12 16:21:49
 */
const sm = require('sm-crypto');

function createSm2GenerateKeyPairHex() {
  let sm2PublicKey = window.sessionStorage.getItem("client_sm2_pub");
  let sm2PrivateKey = window.sessionStorage.getItem("client_sm2_pri");
  if (!sm2PublicKey || !sm2PrivateKey) {
    let sm2Keypair = sm.sm2.generateKeyPairHex();
    window.sessionStorage.setItem("client_sm2_pub", sm2Keypair.publicKey);
    window.sessionStorage.setItem("client_sm2_pri", sm2Keypair.privateKey);
    sm2PublicKey = sm2Keypair.publicKey;
    sm2PrivateKey = sm2Keypair.privateKey;
  }
  return {
    publickKey: sm2PublicKey,
    privateKey: sm2PrivateKey
  }
}
const sm2 = {
  //通过公钥加密
  encode(word, keyStr, cipherMode = 1) {
    if (!keyStr) {
      keyStr = createSm2GenerateKeyPairHex().publickKey
    }
    return sm.sm2.doEncrypt(word, keyStr, cipherMode)
  },
  //通过私钥解密
  decode(word, keyStr, cipherMode = 1) {
    if (!keyStr) {
      keyStr = createSm2GenerateKeyPairHex().privateKey
    }
    return sm.sm2.doDecrypt(word, keyStr, cipherMode)
  }
}
const sm3 = {
  encode(word) {
    return sm.sm3(word)
  }
}

export default {
  sm2,
  sm3
}