/* eslint-disable no-unused-vars */
import helper from "./helper";
// import control from '../mixins/control'
// import keys from './keys';

let pascalCaseRE = /^[A-Z][^\-]*$/g;

let __ctrls__ = {};
let __designOptions__ = {};
let __options__ = {};
// let __temp__ = {};
let hasSymbol = typeof Symbol !== "undefined" && helper.isNative(Symbol) && typeof Reflect !== "undefined" && helper.isNative(Reflect.ownKeys);
let componentNameRE = /^(Dsf)(Mobile)?(\w+)/g;
let onlineComponents = {};

//注册组件
function component(options) {
  if (!options.name) {
    throw "组件缺少name属性";
  }
  if (!options.name.startsWith('Dsf') && !options.name.startsWith("DSFMobile")) {
    throw "组件" + options.name + "的name属性PC端组件必须以Dsf开头，移动端组件必须以DsfMobile开发头"
  }
  // dsf.log(options.name)
  if (!options.ctrlCaption && options.mixins) {
    let hasCtrlCaption = false;
    options.mixins.forEach((mix) => {
      if (mix.ctrlCaption) {
        hasCtrlCaption = true;
      }
    });
    if (!hasCtrlCaption) {
      throw options.name + "组件缺少ctrlCaption属性";
    }
  }
  componentNameRE.lastIndex = 0;
  let code = componentNameRE.exec(options.name);
  let arr = [];
  if (code && code.length > 0) {
    for (let i = 1; i < code.length; i++) {
      if (code[i]) {
        arr.push(code[i].toLowerCase())
      }
    }
  }
  if (dsf.isUnDef(options.props)) {
    options.props = {};
  }
  let dsfCode = arr.join(".")
  options.props["ctrlType"] = {
    type: String,
    default: dsfCode
  };
  let ctrlType = options.ctrlType = dsfCode;
  // dsf.global.$components[options.name] = options;
  if (!__options__[ctrlType]) {
    pascalCaseRE.lastIndex = 0;
    if (!pascalCaseRE.test(options.name)) {
      throw options.name + "组件name属性请使用PascalCase方式命名";
    }
    if (!options.props) {
      options.props = {};
    }
    if (!dsf.hasOwn(options.props, "ctrlName")) {
      options.props["ctrlName"] = {
        type: String,
        default: options.name
      };
    }
    if (!dsf.hasOwn(options.props, "ctrlType")) {
      componentNameRE.lastIndex = 0;
      let code = componentNameRE.exec(options.name);
      let arr = [];
      if (code && code.length > 0) {
        for (let i = 1; i < code.length; i++) {
          if (code[i]) {
            arr.push(code[i].toLowerCase())
          }
        }
      }
      let dsfCode = arr.join(".")
      options.props["ctrlType"] = {
        type: String,
        default: dsfCode
      };
      options.ctrlType = dsfCode;
    }

    let ctrlType = options.ctrlType.toLowerCase();

    let mixins = $getMinixsAndExtends(options, true, ["props", "design"]);
    //将attributes数据提出取出来，存放到全局对象中
    let ctrlAttrs = $minixAttributes(options, mixins["props"]);
    options.design = $mergeData(options.design || {}, mixins["design"]);

    __ctrls__[ctrlType] = ctrlAttrs;
    __designOptions__[ctrlType] = options.design;
    __options__[ctrlType] = options;
    return options;
  } else {
    return __options__[ctrlType];
  }
}

// //注册用户组件
// function exportOnLineComponent(options) {
//   if (options?.mixins?.length > 0) {
//     let result = _.filter(options.mixins, (v) => {
//       return v === control;
//     })
//     if (result.length <= 0) {
//       options.mixins.unshift(control);
//     }
//   }
//   else {
//     options.mixins = [control]
//   }
//   dsf.component(options)
//   return options;
// }

// //导入用户组件
// async function importOnLineComponent(nameSpace) {
//   if(!onlineComponents[nameSpace]){
//     let arr = nameSpace.split(".").join("/");
//     return new Promise((resolve, reject) => {
//       let moduleName = dsf.url.getWebPath("$/testlibs/test.js");
//       dsf.require([moduleName], (options) => {
//         let component = exportOnLineComponent(options);
//         onlineComponents[nameSpace]=options;
//         resolve(component);
//       });
//     });
//   }
//   else{
//     return onlineComponents[nameSpace];
//   }

// }

function mergeCtrlProp(ctrl, props) {
  if (props) {
    for (let k in props) {
      if (helper.isObject(props[k])) {
        ctrl[k] = helper.mix(true, {}, props[k]);
      }
      else if (helper.isArray(props[k])) {
        ctrl[k] = helper.mix(true, [], props[k]);
      }
      else {
        ctrl[k] = props[k];
      }
    }
  }
  return ctrl;
}
//创建一个组件对象
function createComponent(key, attributes) {
  if (dsf.type(key) == "string") {
    if (__ctrls__[key]) {
      let ctrl = __ctrls__[key];
      //将组件ctrlType、ctrlName提取出来,避免被attributes中相同属性覆盖
      let retain = {
        ctrlType: __ctrls__[key].ctrlType,
        ctrlName: __ctrls__[key].ctrlName
      }
      attributes = attributes || {};
      let attrs = dsf.mix(true, {}, mergeCtrlProp(ctrl, attributes), retain);
      if (!attrs.designId) {
        attrs.designId = dsf.uuid();
      }
      return attrs;
    }
  } else if (dsf.type(key) == "object") {
    if (!__ctrls__[key.ctrlType]) {
      component(key);
    }
    return createComponent(key.ctrlType, attributes);
  }
}

function getComponentOptions(key) {
  if (__options__[key]) {
    return __options__[key];
  }
}

//获取组件design节点配置信息
function getComponentDesignOptions(key) {
  return __designOptions__[key];
}

//补全加载布局时候组件属性
function fillComponentDefaultProps(props, callback) {
  if (props) {
    let c = dsf.createComponent(props.ctrlType);
    if (c) {
      // console.log(props.ctrlType)
      //补丁：修复日期组件之前有$开头的属性，后续做了清理后，以前保存的表单
      for (let k in props) {
        if (k.startsWith("$")) {
          props[k.substr(1)] = props[k];
          delete props[k];
        }
      }
      let to_slots = c.slots.slice(0);
      let from_slots = props.slots.slice(0);
      let source = dsf.mix(c, props);
      _.each(to_slots, (el) => {
        let index = _.findIndex(from_slots, (el1) => {
          return el1.name == el.name;
        });
        if (index >= 0) {
          for (let k in el) {
            if (from_slots[index][k] == undefined) {
              from_slots[index][k] = el[k]
            }
          }
        } else {
          from_slots.push(el)
        }

      })
      dsf.mix(props, source, { slots: from_slots });
      callback && callback(c);
      if (props.slots) {
        _.each(props.slots, slot => {
          let controls = slot.controls;
          _.each(controls, ctrlProps => {
            fillComponentDefaultProps(ctrlProps, callback);
          });
        });
      }
    }

  }
  return props;
}

//递归组件及子组件
function recursionComponentProps(props, callback) {
  if (props) {
    callback && callback(props);
    if (props.slots) {
      _.each(props.slots, slot => {
        let controls = slot.controls;
        _.each(controls, ctrlProps => {
          recursionComponentProps(ctrlProps, callback);
        });
      });
    }
  }
}

//减少window再resize时候的函数调用，特别是IE会出现严重的屏幕闪烁
let d = _.debounce($resizeComponent, 50);
// let d=$resizeComponent
function resizeDetector() {
  window.removeEventListener("resize", d);
  window.addEventListener("resize", d);
}


async function $resizeComponent(event, component) {
  let com = component;
  if (!com) {
    com = document.querySelectorAll(".ds-page");
  } else {
    com = [com];
  }
  for (let i = 0; i < com.length; i++) {
    let el = com[i]
    let component = el._isVue ? el : el.__vue__;
    await $resize(component);
  }
}

async function $resize(component) {
  if (component) {
    if (component.ctrlType && component.resize && !component.$$getComponent) {
      try {
        await component.resize();
      }
      catch (ex) {
        console.log(component)
        throw ex;
      }
    }
    if (component.$children) {
      for (let i = 0; i < component.$children.length; i++) {
        let children = component.$children[i];
        await $resize(children);
      }
      // _.each(component.$children, (children) => {
      //   $resize(children);
      // });
    }
  }

}

//获取混入信息
function $getMinixsAndExtends(options, isRoot, nodes) {
  let result = {},
    instance = {};
  let arr = [];
  let extend = options.extends;
  let mixins = options.mixins || [];
  if (dsf.isDef(extend)) {
    arr = arr.push(extend);
  }
  if (mixins && mixins.length) {
    arr = arr.concat(mixins);
  }
  if (isRoot === false) {
    for (let k in nodes) {
      if (options[nodes[k]]) {
        result[nodes[k]] = dsf.mix({}, options[nodes[k]]);
      }
    }
  }

  if (arr && arr.length) {
    for (let i = arr.length - 1; i >= 0; i--) {
      // for (let i = 0; i < arr.length; i++) {
      let from = $getMinixsAndExtends(arr[i], false, nodes);
      result = $mergeData(result, from);
    }
  }
  instance = $mergeData(instance, result);
  return instance;
}

//获取组件attributes函数中的对象
function $getAttributes(options, props) {
  let attributes = {},
    mixinsAttributes = {},
    instance = {};
  for (let k in options.props) {
    let attrVal;
    if (dsf.hasOwn(options.props[k], 'default')) {
      let defaultVal = undefined;
      let propDefault = options.props[k].default;
      let type = options.props[k].type;
      if (type && (type === Function || type[0] === Function)) {
        defaultVal = propDefault;
      }
      else {
        defaultVal = dsf.isFunction(propDefault) ? propDefault() : propDefault;
      }
      attrVal = defaultVal;
    }
    attributes[k] = attrVal;
  }
  for (let k in props) {
    let attrVal;
    if (dsf.hasOwn(props[k], 'default')) {
      let defaultVal = undefined;
      let propDefault = props[k].default;
      if (props[k].type && props[k].type === Function) {
        defaultVal = propDefault
      }
      else {
        defaultVal = dsf.isFunction(propDefault) ? propDefault() : propDefault;
      }
      attrVal = defaultVal;
    }
    mixinsAttributes[k] = attrVal;
  }
  $mergeData(attributes, mixinsAttributes);
  instance = $mergeData(instance, attributes);
  return instance;
}

//合并组件属性
function $mergeData(to, from) {
  if (!from) {
    return to;
  }
  var key, toVal, fromVal;
  var keys = hasSymbol ? Reflect.ownKeys(from) : Object.keys(from);

  for (var i = 0; i < keys.length; i++) {
    key = keys[i];
    toVal = to[key];
    fromVal = from[key];
    if (!dsf.hasOwn(to, key)) {
      to[key] = fromVal;
    } else if (toVal !== fromVal) {
      if (dsf.isPlainObject(toVal) && dsf.isPlainObject(fromVal)) {
        $mergeData(toVal, fromVal);
      }
    }
  }
  return to;
}

//混入attributes
function $minixAttributes(options, mixinsProps) {
  // let key = dsf.kebabCase(options.name, "-");
  let ctrlAttrs = $getAttributes(options, mixinsProps);
  ctrlAttrs.ctrlName = options.name;
  ctrlAttrs.ctrlType = options.ctrlType;
  //如果组件存在元数据
  if (ctrlAttrs.metadata) {
    // let arr = options.ctrlType.split(/\.|-/g)
    ctrlAttrs.metadata.currentControl = { text: options.ctrlCaption, value: options.ctrlType };
    ctrlAttrs.metadata.controls = [ctrlAttrs.metadata.currentControl];
  }
  return ctrlAttrs;
}

function getComponentMixins(vm) {
  let arr = [];
  let mixins = vm.$options ? vm.$options.mixins : vm.mixins;
  _.each(mixins, (it) => {
    let arr2 = getComponentMixins(it);
    _.each(arr2, (it) => {
      arr.push(it);
    })
    arr.push(it);
  });
  return arr;
}

// //1.0映射与2.0的映射补丁
// let versionMap={
//   'dsf.textbox':'textbox',
//   'dsf.radiobox':'radiobox',
//   'dsf.checkbox':'checkbox'
// }




export default {
  component,
  createComponent,
  // exportOnLineComponent,
  // importOnLineComponent,
  fillComponentDefaultProps,
  recursionComponentProps,
  getComponentDesignOptions,
  resizeDetector,
  resizeComponent: (page) => {
    $resizeComponent(null, page)
  },
  getComponentOptions,
  getComponentMixins
};