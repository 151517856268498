export default {
  ctrlCaption: "表单意见框",
  props: {
    caption: {
      type: String,
      default() {
        return "";
      },
    },
    label: {
      type: String,
      default() {
        return "";
      },
    },
    showLabel: {
      type: Boolean,
      default() {
        return true;
      },
    },
    visible: {
      type: Boolean,
      default() {
        return true;
      },
    },
    tbNoPadding: {
      type: Boolean,
      default() {
        return false;
      },
    },
    lrNoPadding: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showAddComment: {
      type: Boolean,
      default() {
        return false;
      },
    },
    addCommentText: {
      type: String,
      default() {
        return "填写处理意见";
      },
    },
    scope: {
      type: Number,
      default() {
        return 0;
      },
    },
    nodes: {
      type: Array,
      default() {
        return [];
      },
    },
    order: {
      type: Number,
      default() {
        return 0;
      },
    },
    template: {
      type: Number,
      default() {
        return 0;
      },
    },
    metadata: {
      type: Object,
      default() {
        return {
          id: "",
          name: "表单意见框",
          type: {
            value: 3,
            text: "业务元数据",
          },
          valueAttributes: [
            {
              name: "文本",
              code: "text",
              type: dsf.metadata.getDataType("string"),
              length: "50",
              defaultValue: null,
              unit: null,
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      sPID: this.$route.query.sPID,
      sCurLinkID: this.$route.query.sCurLinkID,
      curNodeId: null,
    };
  },
  computed: {
    data() {
      if (this.isDesign || !this.sPID) {
        return [
          {
            nodename: "拟稿",
            STATUS: 0,
            linkid: "faedda583f7742948b0714f0f17d1085",
            opinionatts: {
              Content: "同意",
            },
            ruserid: "",
            pid: "af60e455757d48dd801d07b1538eb806",
            rusername: "平台管理员",
            rdeptname: "平台管理组",
            reachtime: "2020年01月06日 18:21:21",
            receivetime: "2020年1月2日 10:21:21",
          },
        ];
      }
      return this.$vm?.flows?.flowProcess ? this.formatData(_.cloneDeep(this.$vm.flows.flowProcess)) : [];
    },
    isShowAdd() {
      if (this.isDesign) {
        return this.showAddComment;
      }
      if (this.$vm?.flows?.flowCurInfo?.STATUS == 2) {
        return false;
      }
      if (this.showAddComment && this.$vm?.flows?.flowProcess) {
        let curId;
        this.$vm?.flows?.flowProcess?.forEach((item) => {
          if (this.sCurLinkID === item.linkid && item.nodetype != "299") {
            curId = item.nodeid;
          }
        });
        return this.nodes?.indexOf(curId) > -1;
      }
      return false;
    },
  },
  mounted() {
    const data = (this.$dialog && this.$dialog.dialogArgs && this.$dialog.dialogArgs.data && this.$dialog.dialogArgs.data[0]) || null;
    this.sPID = data ? data.sPID : this.$route.query.sPID || this.queryString.sPID;
    this.sCurLinkID = data ? data.sCurLinkID : this.$route.query.sCurLinkID || this.queryString.sCurLinkID;
    if (this.sPID) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$vm?.flows?.loadFlowProcess) {
        return;
      }
      this.$vm.flows.loadFlowProcess = true;
      this.$http
        .get("wfr/getProcessInfoByPID", {
          sPID: this.sPID,
        })
        .then((res) => {
          if (res.data.success) {
            let params;
            if (this.$vm?.flows) {
              this.$set(this.$vm.flows, "flowProcess", res.data.data);
              this.$vm.flows?.flowProcess?.forEach((item) => {
                if (this.sCurLinkID === item.linkid && item.nodetype != "299") {
                  this.$vm.flows.sOpinion = JSON.parse(item.opinionatts);
                }
              });
            }
            res.data.data.forEach((item) => {
              if (item.linkid === this.sCurLinkID && item.pid === this.sPID) {
                if (item.STATUS != 0 && item.STATUS != 1) {
                  params = dsf.mix({}, this.$route.query, {
                    isHide: true,
                  });
                }
              }
            });
            //更新url添加id参数
            if (params) {
              this.$router.replace({
                path: this.$route.path,
                query: params,
              });
            }
          }
        });
    },
    addComment() {
      if (this.$listeners["click"]) {
        this.$dispatch("click", this);
      } else {
        this.openDialog();
      }
    },
    openDialog() {
      this.$openDialog({
        title: "处理意见",
        width: dsf.global.$isMobile ? "100vw": "800px",
        height: dsf.global.$isMobile ? "100vw": "490px",
        content: dsf.global.$isMobile ? "DsfMobileFlowAddComment" : "DsfFlowAddComment",
        hasFooter: false,
        footerAlign: "center",
        params: {
          $vm: this.$vm,
          flow: {
            sPID: this.sPID,
            sCurLinkID: this.sCurLinkID,
          },
        },
        dialogArgs: {},
        btns: [],
      });
    },
    formatData(list) {
      let data = [];
      list.forEach((item) => {
        item.opinionatts = JSON.parse(item.opinionatts);
        if (this.nodes) {
          this.nodes.forEach((id) => {
            if (id === item.nodeid) {
              data.push(item);
            }
          });
        }
      });
      return data;
    },
  },
}