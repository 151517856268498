export default {
  toChinesNum: function (num) {
    var changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
    var unit = ["", "十", "百", "千", "万"];
    num = parseInt(num);
    var getWan = function (temp) {
      var strArr = temp.toString().split("").reverse();
      var newNum = "";
      for (var i = 0; i < strArr.length; i++) {
        newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[
          strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
      }
      return newNum;
    }
    var overWan = Math.floor(num / 10000);
    var noWan = num % 10000;
    if (noWan.toString().length < 4) noWan = "0" + noWan;
    return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
  },
  numToEnglishLetter: function (number) {
    var char = "";
    var array = [];
    // Switch ASCII
    var numToStringAction = function (nnum) {
      var num = nnum - 1;
      var a = parseInt(num / 26);
      var b = num % 26;
      array.push(b);
      if (a > 0) {
        numToStringAction(a);
      }
    }
    numToStringAction(number);
    array = array.reverse();
    // Return excel letter: such => C / AA / BBA
    for (var i = 0; i < array.length; i++) {
      char += String.fromCharCode(64 + parseInt(array[i] + 1));
    }
    return char;
  },
  randomInt(min, max) {
    if (dsf.isUnDef(min)) {
      min = -1;
    }
    if (dsf.isUnDef(max)) {
      max = 999999999999;
    }
    var range = max - min;
    var rand = Math.random();
    var num = min + Math.round(rand * range);
    return num;
  },
  toMoney(num, prefix = '') {
    if (dsf.isNumber(num)) {
      if (dsf.isString(num)) {
        num = parseFloat(num);
      }
      num = num.toFixed(2);
      return prefix + num;
    }
    return num;
  },
  numberAmountConversion: function (amount) {
    // 汉字的数字
    const cnNums = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    // 基本单位
    const cnIntRadice = ["", "拾", "佰", "仟"];
    // 对应整数部分扩展单位
    const cnIntUnits = ["", "万", "亿", "兆"];
    // 对应小数部分单位
    const cnDecUnits = ["角", "分", "毫", "厘"];
    // 整数金额时后面跟的字符
    const cnInteger = "整";
    // 整型完以后的单位
    const cnIntLast = "元";
    // 最大处理的数字
    const maxNum = 9999999999999999.99;
    // 金额整数部分
    let integerNum;
    // 金额小数部分
    let decimalNum;
    // 输出的中文金额字符串
    let chineseStr = "";
    // 分离金额后用的数组，预定义
    let parts;
    if (amount === "") {
      return "";
    }
    amount = parseFloat(amount);
    if (amount >= maxNum) {
      // 超出最大处理数字
      return "";
    }
    if (amount === 0) {
      chineseStr = cnNums[0] + cnIntLast + cnInteger;
      return chineseStr;
    }
    // 转换为字符串
    amount = amount.toString();
    if (amount.indexOf(".") === -1) {
      integerNum = amount;
      decimalNum = "";
    } else {
      parts = amount.split(".");
      integerNum = parts[0];
      decimalNum = parts[1].substr(0, 4);
    }
    // 获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
      let zeroCount = 0;
      const IntLen = integerNum.length;
      for (let i = 0; i < IntLen; i++) {
        const n = integerNum.substr(i, 1);
        const p = IntLen - i - 1;
        const q = p / 4;
        const m = p % 4;
        if (n === "0") {
          zeroCount++;
        } else {
          if (zeroCount > 0) {
            chineseStr += cnNums[0];
          }
          // 归零
          zeroCount = 0;
          chineseStr += cnNums[parseInt(n, 10)] + cnIntRadice[m];
        }
        if (m === 0 && zeroCount < 4) {
          chineseStr += cnIntUnits[q];
        }
      }
      chineseStr += cnIntLast;
    }
    // 小数部分
    if (decimalNum !== "") {
      const decLen = decimalNum.length;
      for (let i = 0; i < decLen; i++) {
        const n = decimalNum.substr(i, 1);
        if (n !== "0") {
          chineseStr += cnNums[Number(n)] + cnDecUnits[i];
        }
      }
    }
    if (chineseStr === "") {
      chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum === "") {
      chineseStr += cnInteger;
    }
    return chineseStr;
  },
  /**
   * 弧度转角度
   */
  r2a: function (r) {
    return 180 / (Math.PI / r);
  },
  /**
   * 角度转弧度
   */
  a2r: function (a) {
    return Math.PI * (a) / 180
  },

  /**
   * 计算两点直线的角度
   */
  getRadina: function (p1, p2, angle) {
    var d = Math.atan((p2.y - p1.y) / (p2.x - p1.x));
    d = d + (angle || 0);

    //修正第二、三象限中的弧度
    if ((p2.y - p1.y > 0 && p2.x - p1.x < 0) || (p2.y - p1.y < 0 && p2.x - p1.x < 0)) {
      d += Math.PI;
    }
    // 修正第一象限弧度
    else if (p2.y - p1.y < 0 && p2.x - p1.x > 0) {
      d += Math.PI * 2;
    }
    // x轴负方向 180度
    if (p2.x < p1.x && p2.y == p1.y) {
      // console.log("x轴负方向");
      d = Math.PI;
    }
    // y轴负方向 270度
    else if (p2.x == p1.x && p2.y < p1.y) {
      d = 1.5 * Math.PI;
    }
    // console.log(d * 180 / Math.PI)
    return d
  },
  //获取以指定点按中心点旋转一定角度后所在的位置
  getRotatedPiont: function (x, y, xcenter, ycenter, angle) {
    var radian = Math.PI * angle / 180;
    var x2 = (x - xcenter);
    var y2 = (y - ycenter)
    var x1 = x2 * Math.cos(radian) - y2 * Math.sin(radian) + xcenter;
    var y1 = x2 * Math.sin(radian) + y2 * Math.cos(radian) + ycenter;
    return {
      x: x1,
      y: y1
    }
  },
  //计算两点之间距离
  getDistance: function (p1, p2) {
    if (!p1 || !p2) {
      return 0;
    }
    var x_len = p2.x - p1.x;
    var y_len = p2.y - p1.y;
    var len = Math.sqrt(Math.pow(x_len, 2) + Math.pow(y_len, 2));
    return len;
  },

  //坐标系转换
  convertLocalCoordinate: function (el, global, scale) {
    el = $(el);
    var offset = el.offset();
    var top = offset.top;
    var left = offset.left;
    var local = {
      x: 0,
      y: 0
    }
    local.x = global.x - left;
    local.y = global.y - top;
    if (!dsf.isDef(scale)) {
      scale = 1;
    }
    local.x /= scale;
    local.y /= scale;
    return local;
  }
}
