export default {
  ctrlCaption: "处理意见",
  props: {
    allowPost:{
      type:Boolean,
      default:false
    },
    caption: {
      type: String,
      default() {
        return "";
      },
    },
    label: {
      type: String,
      default() {
        return "";
      },
    },
    placeholder: {
      type: String,
      default() {
        return "请输入";
      },
    },
    showLabel: {
      type: Boolean,
      default() {
        return true;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    visible: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isTextarea: {
      type: Boolean,
      default() {
        return false;
      },
    },
    textareaRows: {
      type: Number,
      default() {
        return 3;
      },
    },
    visibleUpBtn: {
      type: Boolean,
      default() {
        return true;
      },
    },
    visibleOptionsBtn: {
      type: Boolean,
      default() {
        return true;
      },
    },
    visibleUpImgBtn: {
      type: Boolean,
      default() {
        return true;
      },
    },
    tbNoPadding: {
      type: Boolean,
      default() {
        return false;
      },
    },
    metadata: {
      type: Object,
      default() {
        return {
          id: "",
          name: "处理意见",
          type: {
            value: 3,
            text: "业务元数据",
          },
          valueAttributes: [
            {
              name: "文本",
              code: "text",
              type: dsf.metadata.getDataType("string"),
              length: "1000",
              defaultValue: null,
              unit: null,
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      showOptions: false,
      sPID: 0,
      sCurLinkID: 0,
      options: [],
      isSave: true,
      sOpinion: {
        Content: '',
        Attachments: [],
      }
    };
  },
  mounted() {
    if (this.$vm && this.$vm.$dialog && this.$vm.$dialog.dialogArgs && this.$vm.$dialog.dialogArgs.data) {
      const data = this.$vm.$dialog.dialogArgs.data[0];
      this.sPID = data.sPID;
      this.sCurLinkID = data.sCurLinkID;
    } else {
      this.sPID = this.$route.query.sPID || this.queryString.sPID;
      this.sCurLinkID = this.$route.query.sCurLinkID || this.queryString.sCurLinkID;
    }
    if (!this.isDesign && this.sPID && this.sCurLinkID) {
      this.initOptions();
    }
    if (this.$vm && this.$vm.flows) {
      this.$vm.flows.disposeIdea = this;
    }
  },
  methods: {
    // 获取角色树
    initOptions() {
      this.$http.get("wfr/getOpinionKeyWord", {}).then((res) => {
        if (res.data.success) {
          this.options = res.data.data.map((item) => {
            item.name = item.key_word;
            return item;
          });
        }
      });
    },
    setValue(option) {
      this.sOpinion.Content = option.key_word;
      this.showOptions = false;
    },
    upload() {
      this.$refs.uploadFile.openSelectFile();
    },
    getValue(val) {
      this.sOpinion.Attachments = JSON.parse(val.args);
    },
    setDoSave(val) {
      this.isSave = val;
    },
    postBefore(opts) {
      if (!this.isSave || (!this.sPID && !this.sCurLinkID)) {
        // next();
      } else {
        let param = {
          sPID: this.sPID,
          sCurLinkID: this.sCurLinkID,
          sOpinion: this.sOpinion,
        };
        param.sOpinion = JSON.stringify(param.sOpinion);
        this.$http
          .get("wfr/opinion", param)
          .then((res) => {
            if (res.data.success) {
              // next();
            } else {
              this.$message(res.data.message || (this.label || "处理意见") + "异常", false);
            }
          })
          .catch((res) => {
            this.$message((this.label || "处理意见") + "异常", false);
          });
      }
    },
  },
}