
export default {
  type: "govDingTalk",
  isEnv() {
    return dsf.client.govDingding()
  },
  init(opt = {}) {
    try {
      return new Promise((resolve, reject) => {
        let { sdkUrl } = opt
        if (!sdkUrl) {
          throw '请传入govDingTalk.sdkUrl'
        }
        dsf.http.importFiles(opt.sdkUrl).then(([{ state }]) => { // 加载sdk
          if (!state) {
            console.error("钉钉sdk加载出错")
            reject(`${sdkUrl}文件出错`)
          }
          dd.ready(() => {
            authConfig(this.jsApiList).then((res) => {
              resolve(res)
            }).catch((err) => {
              reject(err)
            })
          })
        }).catch((err) => {
          reject(err)
        })
      })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  jsApiList: [
    "copyToClipboard",
    "uploadRemoteFileToDisk",
    "chooseSpaceDir",
    "createChatGroup",
    "openChat",
    "createVideoMeeting",
    "createDing",
    "searchOnMap",
    "showOnMap",
    "startGeolocation",
    "locateOnMap",
    "getGeolocation",
    "chooseVideo",
    "chooseDepartments",
    "chooseContactWithComplexPicker",
    "chooseContact",
    "previewDoc",
    "stopAudio",
    "resumeAudio",
    "pauseAudio",
    "playAudio",
    "downloadAudio",
    "stopRecordAudio",
    "stopRecordAudio",
    "onRecordAudioEnd",
    "onAudioPlayEnd",
    "showQuickCallMenu",
    "showCallMenu",
  ],
  methods: {
    // 获取授权码
    getAuthCode(opt = {}) {
      return new Promise((resolve, reject) => {
        try {
          let _opt = this._getFuncOpts(
            {},
            opt
          )
          dd.getAuthCode(_opt).then((res) => {
            resolve(res)
          }).catch((err) => {
            reject(err)
          });
        } catch (error) {
          reject(error)
        }
      })
    },
    // 获取用户详情
    getUserDetails(opt = {}) {
      return new Promise((resolve, reject) => {
        try {
          let _opt = this._getFuncOpts(
            {},
            opt
          )
          return this.getAuthCode({}).then((res) => {
            if (res.code) {
              return dsf.http.get('/api/ding/dg/auth/authUserInfo', {
                authCode: res.code,
                ..._opt
              }).then(({ data }) => {
                if (data && data.success) {
                  data = data.data || {}
                  let res = {
                    openid: data.openid,
                    name: data.nickNameCn,
                  }
                  res["_" + this.$type] = data
                  console.log(res)
                  resolve(res)
                } else {
                  reject(data)
                }
              })
            } else {
              reject(res)
            }
          })
        } catch (err) {
          reject(err)
        }
      })
    },
    // 扫一扫
    scan(opt = {}) {
      try {
        let _opt = this._getFuncOpts(
          {},
          opt
        )
        return dd?.scan(_opt).then((res) => {
          return res ? res.text || '' : ''
        })
      } catch (error) {
        reject(error)
      }
    },
    // 拨打电话
    callPhone(opt = {}) {
      return new Promise((resolve, reject) => {
        try {
          let _opt = this._getFuncOpts(
            {
              phoneNumber: '', // 类型 qrCode 二维码  barCode 条形码 all 所有
              code: '+86',  // 国家代号，中国是+86。
              showDingCall: false, // 是否显示钉钉电话。
            },
            opt
          )
          if (!_opt.phoneNumber) throw '请传入phoneNumber电话号码'
          dd.biz.telephone.showCallMenu({
            phoneNumber: _opt.phoneNumber, // 期望拨打的电话号码
            code: _opt.phoneNumber.code, // 国家代号，中国是+86
            showDingCall: _opt.phoneNumber.showDingCall, // 是否显示钉钉电话
            onSuccess: function () {
              resolve()
            },
            onFail: function () {
              reject()
            }
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    // 设备唯一标识
    getDeviceCode() {
      return new Promise((resolve, reject) => {
        try {
          dd.device.base.getUUID({
            onSuccess: (data) => {
              resolve(data.uuid)
            },
            onFail: (err) => {
              reject(err);
            }
          });
        } catch (error) {
          reject(error)
        }
      })
    },
    // 新开页面
    open(opt = {}) {
      return new Promise((resolve, reject) => {
        let _opt = this._getFuncOpts(
          {
            url: '', // 网址
          },
          opt
        )
        if (!_opt.url) throw '请传入url'
        try {
          dd.biz.util.openLink({
            url: _opt.url,//要打开链接的地址
            onSuccess: function (result) {
              /**/
              resolve()
            },
            onFail: function (err) {
              reject(err)
            }
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    // 分享
    shareToMessage(opt) {
      try {
        let _opt = this._getFuncOpts(
          {
            "url": "",
            "title": "",
            "content": "",
            "image": ""
          },
          opt
        )
        if (!_opt.url) {
          throw '请传入url'
        }
        if (!_opt.title) {
          throw '请传入title'
        }
        return dd.shareToMessage({
          url: _opt.url,
          title: _opt.title,
          content: _opt.content,
          image: _opt.image,
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // 获取当前定位位置
    getGeolocation(opt = {}) {
      try {
        let _opt = this._getFuncOpts(
          {
            "withReGeocodeurl": true,
          },
          opt
        )
        return dd.getGeolocation({
          withReGeocodeurl: _opt.withReGeocodeurl,
        }).then((data) => {
          let res = {
            type: "gcj02",
            lng: data.longitude,
            lat: data.latitude,
          }
          res['_' + this.$type] = data
          return Promise.resolve(res)
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // 设置 浏览器 title
    navigationSetTitle(opt = {}) {
      return new Promise((resolve, reject) => {
        try {
          let _opt = this._getFuncOpts(
            {
              title: '', // 网址
            },
            opt
          )
          dd.biz.navigation.setTitle({
            title: _opt.title,//控制标题文本，空字符串表示显示默认文本
            onSuccess: function (result) {
              resolve(result)
            },
            onFail: function (err) {
              reject(err)
            }
          });

        } catch (error) {
          reject(error)
        }
      })
    }
  }
}

// jsApi鉴权
function authConfig(jsApiList) {
  return getTicket().then(({ data }) => {
    if (data && data.success) {
      return Promise.resolve({ ticket: data?.data?.ticket, jsApiList })
    }
    return Promise.reject(data)
  }).then(dd.authConfig)
}

// 获取Ticket
function getTicket() {
  return dsf.http.get('/api/ding/dg/auth/ticket')
}

// 获取Token
function getToken() {
  return dsf.http.get('/api/ding/dg/auth/token')
}
