// 请求函数封装
import axios from "axios";
import qs from "qs";
import url from "./url";
import helper from "./helper";

const defaultTimeOut = 20000;
const sessionTimeoutCodes = ['29401', '29402', '29404', '29405', '29406'];
const deviceChangeCodes = ['29407'];
let sysInterceptorsResponse = false;
const Axios = axios.create({
  baseURL: "",
  timeout: defaultTimeOut,
  responseType: "json",
  crossDomain: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  }
  // withCredentials: true
  // withCredentials设置为true，后端设置的请求标头Access-Control-Allow-Origin'不能为*，跨域处理需要设置为获取的当前请求的origin
});

// const DEFAULT_ERROR = "网络存在异常";
Axios.interceptors.response.use(response => {
  //处理IE9请求json时不能自动转化成对象的问题
  if (response.data == null && response.config.responseType === "json" && response.request.responseText != null) {
    try {
      response.data = JSON.parse(response.request.responseText);
    } catch (e) {
      // ignored
    }
  }
  if (response?.data?.state) {
    let message = ""
    //拦截session丢失，后端返回的错误码 
    if (sessionTimeoutCodes.indexOf(response.data.state.toString()) >= 0) {
      message = "会话已经失效，需重新登录"
    }
    //用户在另一台设备登录
    else if (deviceChangeCodes.indexOf(response.data.state.toString()) >= 0) {
      message = "当前用户已在其他设备登录"
    }
    if (message) {
      if (!sysInterceptorsResponse) {
        sysInterceptorsResponse = true;
        return new Promise((resolve, reject) => {
          openSystemAlert(message, {
            code: response?.data?.state,
            message: message
          }, goTologin, goTologin)
        })
      }
      else {
        return new Promise(() => { })
      }
    }

  }
  return response;
});

//跳转到登录页
function goTologin(error) {
  let topWin = dsf.getTopWindow();
  let root = topWin.__multiPageVm__ ? topWin : window;
  let app = document.querySelector("#app");
  let vueRoot = app?.__vue__;
  if (vueRoot && vueRoot.$options.sessionCatch) {
    vueRoot.$options.sysCatch(error)
    return;
  }
  let api = dsf.api.getAPI();
  api.loginOut()
    .then((res) => {
      if (dsf.global.$isMobile) {
        root.location.replace(dsf.url.getMobileLogin());
      } else if (res && res.data && res.data.outUrl) {
        root.location.replace(res.data.outUrl);
      } else {
        root.location.replace(dsf.config.setting_public_pc_login_page);
      }
    })
    .catch(() => {
      if (dsf.global.$isMobile) {
        root.location.replace(dsf.url.getMobileLogin());
      } else {
        root.location.replace(dsf.config.setting_public_pc_login_page);
      }
    })
    .finally(() => {
      dsf.cookies.remove("authorization_token");
      dsf.storage.session.remove('authorization_token');
      dsf.cookies.remove("user_name");
      dsf.cookies.remove("isLogin");
    })
}
//弹出系统级错误提示
function openSystemAlert(message, error, yes, cancel) {
  dsf.layer.alert(message)
    .then(() => {
      sysInterceptorsResponse = false;
      yes(error)
    })
    .catch(() => {
      sysInterceptorsResponse = false;
      cancel(error)
    })
}

let done = Promise.prototype.done = function (fn) {
  function responseHanlder(response) {
    if (response.headers) {
      var result = response.data || {};
      //success、state、message这3个属性都存在表示是我们自己应用的程序
      if (helper.isDef(result.success) && helper.isDef(result.state) && helper.isDef(result.message)) {
        return fn(result);
      } else {
        return fn(result);
      }
    } else {
      var result = response;
      //success、state、message这3个属性都存在表示是我们自己应用的程序
      if (helper.isDef(result.success) && helper.isDef(result.state) && helper.isDef(result.message)) {
        return fn(result);
      } else {
        return fn(result);
      }
    }

  }
  let r = this.then(response => {
    if (response !== void 0) {
      return responseHanlder(response);
    }
  });
  attachDoneErrorAlways(r)
  return r;
};

let error = Promise.prototype.error = function (fn) {
  function errorHandler(error) {
    let err = { "message": "" }
    if (dsf.type(error) == 'string') {
      err = {
        message: error
      }
    } else if (dsf.type(error) == "error" || dsf.type(error) == "object") {
      if (error.message == "sessionTimeout") {
        dsf.httpError(error);
        return;
      }
      err = {
        message: error.message
      }
    } else {
      err = {
        message: "服务器异常"
      }
    }
    dsf.error(error);
    fn(err);
  }
  let r = this.catch(error => {
    errorHandler(error);
  });
  attachDoneErrorAlways(r)
  return r;
};

let always = Promise.prototype.always = function (fn) {
  let r = this.finally(fn);
  attachDoneErrorAlways(r);
  return r;
};

let _Promise = Promise;
function attachDoneErrorAlways(promise) {
  //分包后导致项目层的Promise和平台层的Promise不是同一个对象而导致Promise无效
  // if (client.type.startsWith("IE") && window.Promise) {
  //   window.Promise = _promise;
  //   console.log("weng1111", window.Promise.prototype)
  // }
  if (promise) {
    promise.done = done;
    promise.error = error;
    promise.always = always;
  }

  // 修复火狐低版本兼容问题
  let P = promise?.constructor;
  if (P && P !== _Promise) {
    P.prototype.done = done;
    P.prototype.error = error;
    P.prototype.always = always;
  }
}
// IEPromiseSupport()
function getToken() {
  return dsf.cookies.get('authorization_token') || "";
}

function get(path, params, options, baseUrl) {
  // IEPromiseSupport();
  if (dsf.type(options) == 'string') {
    baseUrl = options;
    options = null;
  }
  path = url.getWebPath(path, baseUrl);
  let opts = {
    params: params || {},
    paramsSerializer: function (params) {
      return qs.stringify(params, {
        arrayFormat: "repeat"
      });
    },
    timeout: dsf.config.http.timeout
  };
  opts = dsf.mix(true, opts, options || {}, {
    headers: dsf.getDefaultHttpHeader()
  });
  if (!opts.cache) {
    opts.params._t = (new Date()).getTime();
    delete opts.cache;
  }
  let p = Axios.get(path, opts);

  attachDoneErrorAlways(p)
  return p;
}

function post(path, params, options, baseUrl) {
  // IEPromiseSupport();
  if (dsf.type(options) == 'string') {
    baseUrl = options;
    options = null;
  }
  path = url.getWebPath(path, baseUrl);

  let configContentType = options && options.headers && options.headers["Content-Type"] ? options.headers["Content-Type"] : "";
  //raw-json提交
  if (configContentType.indexOf('application/json') >= 0) {
    if (dsf.isObject(params) || dsf.isArray(params)) {
      params = JSON.stringify(params)
    }
  }
  else {
    // if (configContentType.indexOf("multipart/form-data") < 0 && dsf.isObject(params)) {
    //   params = qs.stringify(params);
    // }
    //普通的form提交，不包含上传文件
    if (dsf.isObject(params) && configContentType.indexOf("multipart/form-data") < 0) {
      params = qs.stringify(params);
    }
  }
  let opts = {
    timeout: dsf.config.http.timeout
  };
  opts = dsf.mix(true, opts, options || {}, {
    headers: dsf.getDefaultHttpHeader()
  });
  let p = Axios.post(path, params, opts)
  attachDoneErrorAlways(p)
  return p;
}

function all(arr) {
  return axios.all(arr).then(axios.spread((...res) => {
    const list = res.map(item => item.data);
    return list;
  }))
}

function upload(path, params, options, baseUrl) {
  // IEPromiseSupport();
  if (dsf.type(options) == 'string') {
    baseUrl = options;
    options = null;
  }
  let opts = dsf.mix(true, options || {}, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...dsf.getDefaultHttpHeader()
    }
  });
  var forms = new FormData();

  for (let k in params) {
    if (params[k] instanceof Blob) {
      forms.append(k, params[k], params[k]?.name);
    } else {
      forms.append(k, params[k]);
    }
  }
  path = url.getWebPath(path, baseUrl);
  let p = Axios.post(path, forms, opts);
  attachDoneErrorAlways(p)
  return p;
}

window.$$allLoadFiles = {};
window.$$allLoadFilesArray = [];

/**
 * 加载js文件或css
 * @param files {[String | [String, String]]}
 *        [url, [url], [url, type]...]
 *          url:脚本链接;
 *          type: '.js' 或者 '.css'。
 * @returns {Promise<unknown>}
 */
function importFiles(files) {
  //IEPromiseSupport();
  let states = [];
  let loadFiles = dsf.type(files) == "array" ? files.slice(0) : [files];
  let promise = new Promise(resolve => {
    recursionLoad(() => {
      resolve(states);
    });
  });

  function recursionLoad(callback) {
    let f = loadFiles.shift();
    if (f) {
      let file = f, type = undefined;
      if (dsf.type(f) == "array" && f.length > 0) {
        file = f[0];
        if (f.length > 1) type = f[1];
      }
      loadFile(file, type)
        .then(result => {
          states.push(result);
          result = null;
        })
        .catch(result => {
          importFiles.kill(result.src, true);
          states.push(result);
          result = null;
        })
        .finally(() => {
          recursionLoad(callback);
        });
    } else {
      callback && callback();
    }
  }
  return promise;
}

function getFileType(url) {
  if (url != null && url.length > 0) {
    if (url.lastIndexOf("?") > 0) {
      url = url.substr(0, url.lastIndexOf("?"));
    }
    return url.substr(url.lastIndexOf(".")).toLowerCase();
  }
}

function loadFile(url, type) {
  // IEPromiseSupport();
  type = type || getFileType(url);
  let fileObj = null;
  let promise = new Promise((resolve, reject) => {
    if (!window.$$allLoadFiles[url]) {
      if (type == ".js") {
        fileObj = document.createElement("script");
        fileObj.src = url;
      } else if (type == ".css") {
        fileObj = document.createElement("link");
        fileObj.href = url;
        fileObj.type = "text/css";
        fileObj.rel = "stylesheet";
      }
      if (fileObj) {
        fileObj.__views__ = [];
        fileObj.onload = fileObj.onreadystatechange = function () {
          if (!this.readyState || "loaded" === this.readyState || "complete" === this.readyState) {
            window.$$allLoadFiles[url].state = "success";
            resolve(window.$$allLoadFiles[url]);
            _.each(window.$$allLoadFiles[url].promiseList, p => {
              p.resolve(window.$$allLoadFiles[url]);
            });
          }
        };
        fileObj.onerror = function () {
          window.$$allLoadFiles[url].state = "error";
          _.each(window.$$allLoadFiles[url].promiseList, p => {
            p.reject(window.$$allLoadFiles[url]);
          });
        };
        if (!window.$$allLoadFiles[url]) {
          window.$$allLoadFiles[url] = {
            elem: fileObj,
            state: "pending",
            type: type,
            src: url,
            promiseList: [{ resolve: resolve, reject: reject }]
          };
          window.$$allLoadFilesArray.push(url);
          document.getElementsByTagName("BODY")[0].appendChild(fileObj);
        }
      }
    } else {
      window.$$allLoadFilesArray.push(url);
      let state = window.$$allLoadFiles[url].state;
      if (state == "pending") {
        window.$$allLoadFiles[url].promiseList.push({ resolve: resolve, reject: reject });
      } else if (state == "success") {
        resolve(window.$$allLoadFiles[url]);
      } else {
        reject(window.$$allLoadFiles[url]);
      }
    }
  });
  attachDoneErrorAlways(promise)
  return promise;
}
importFiles.kill = function (src, mark) {
  dsf.array.remove(window.$$allLoadFilesArray, src);
  let result = _.filter(window.$$allLoadFilesArray, url => url == src);
  if (result.length <= 0 || mark === true) {
    let f = window.$$allLoadFiles[src];
    if (f && f.elem && f.elem.parentNode) {
      f.elem.parentNode.removeChild(f.elem);
    }
    delete window.$$allLoadFiles[src];
  }
};
importFiles.has = function (src) {
  let result = _.filter(window.$$allLoadFilesArray, url => url == src);
  return result.length > 0;
};

export default {
  get,
  post,
  all,
  upload,
  importFiles
};

// 示例:
// this.dsf.http.get('wfm/getWFDefinition', {
//   sID: '5b2d48d830c44b509d4fa730fa9d0b9b'
// }).done((d) => {
//   _this.dsf.layer.toast('模拟Get请求成功', true)
// }).error((response) => {
//   _this.dsf.layer.toast('模拟Get请求失败', false)
// }).always((res) => {
//   debugger
// })

// this.dsf.http.post('dsfa/tags/saveOrCancel', {
//   ywlxValue: '00',
//   ywlxText: '课程',
//   ywid: '6ec7aadc7e1c49a7a3ac454d939da5cd'
// }).done((d) => {
//   _this.dsf.layer.toast('模拟Post请求成功', true)
// }).error((response) => {
//   _this.dsf.layer.toast('模拟Post请求失败', false)
// }).always((res) => {
//   debugger
// })