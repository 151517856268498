/**
 * dom元素拖动
 * 在元素上绑定v-drag指令，即可拖动
 */
export default {
  directives: {
    drag: { // 拖拽指令
      inserted($el, binding, vnode) {
        function elDown(ev) {
          ev = ev.touches && ev.touches[0] || ev;
          $el.setAttribute('data-flag', false)
          // 用元素距离视窗的X、Y坐标，减去el元素最近的相对定位父元素的left、top值
          let sX = ev.clientX - $el.offsetLeft;
          let sY = ev.clientY - $el.offsetTop;
          const firstTime = new Date().getTime();
          document.onmousemove = document.ontouchmove = rootMove;
          document.onmouseup = document.ontouchend = rootUp;
          function rootMove(ev) {
            ev = ev.touches && ev.touches[0] || ev;
            $el.style.left = ev.clientX - sX + 'px';
            $el.style.top = ev.clientY - sY + 'px';
            if (window.$isMobile) {
              $el.style.left = vnode.context.pxToRem(ev.clientX - sX);
              $el.style.top = vnode.context.pxToRem(ev.clientY - sY);
            } else {
              $el.style.left = ev.clientX - sX + 'px';
              $el.style.top = ev.clientY - sY + 'px';
            }
          }
          function rootUp() {
            document.onmousemove = null;
            document.ontouchmove = null;
            document.onmouseup = null;
            document.ontouchend = null;
            const lastTime = new Date().getTime();
            if (lastTime - firstTime < 200) {
              $el.setAttribute('data-flag', true)
            }
          }
        }
        $el.onmousedown = elDown;
        $el.ontouchstart = elDown;
      },
      unbind($el) {
        $el.onmousedown = null;
        $el.ontouchstart = null;
      }
    }
  }
};