import qs from "qs";
import helper from './helper'

//匹配路径地址是否是./、../、#/、http://、https://、ws://开头
let reg_url_prefix = /^(\.\/|\.\.\/|#\/|\/\/|http(s?):\/\/|ws:\/\/)/gi;
let reg_iframeloader = /^\/(iframeloader)(\?|\/)/gi;
function getWebPath(url, rootPath) {
  if (!url) {
    return "";
  }
  let isHtmlDir = false;
  let isAssetsDir = false;
  reg_url_prefix.lastIndex = 0;
  if (reg_url_prefix.test(url)) {
    return url;
  }
  // if (url.startsWith("./") || url.startsWith("../") || url.startsWith("#/") || url.startsWith("//") || url.startsWith("http://") || url.startsWith("https://")) {
  //   return url;
  // }
  // :/为平台特殊判定 强行解析成根目录
  if (url.startsWith(":/")) {
    return url.substr(1);
  }
  if (url.startsWith("~")) {
    isHtmlDir = true;
    url = url.substr(1);
  } else if (url.startsWith("$")) {
    isAssetsDir = true;
    url = url.substr(1);
  }
  if (url.startsWith("/")) {
    url = url.substr(1);
  }
  if (isHtmlDir) {
    return dsf.config.htmlDir + url;
  } else if (isAssetsDir) {
    return dsf.config.assetsDir + url;
  } else {
    let root = rootPath || dsf.config.webRoot.default;
    return root + url;
  }
}

var globalCache = {};

function toRoutePath(url) {
  if (url.startsWith("#")) {
    url = url.substr(1);
  }
  if (!url.startsWith("/")) {
    url = "/" + url;
  }
  return url;
}

function getMobileLogin() {
  let currUrl = dsf.url.parse(window.location.href);
  let searchQuery = currUrl.searchQuery;
  if (dsf.isUnDef(searchQuery) || dsf.isEmptyObject(searchQuery)) {
    searchQuery = null;
  }
  let url = currUrl.origin + currUrl.path;
  let loginUrl = dsf.url.parse(dsf.config.setting_public_mobile_login_page);
  url += loginUrl.hash;
  return url;
}

function queryString(key) {
  if (!globalCache["url_queryString"]) {
    globalCache["url_queryString"] = {};
  }
  if (!globalCache["url_queryString"][key]) {
    var url = window.location.hash;
    var oRegex = new RegExp("[?&]" + key + "=([^&]+)", "i");
    var oMatch = oRegex.exec(url);
    if (oMatch && oMatch.length > 1) {
      var val = decodeURI(oMatch[1]);
      globalCache["url_queryString"][key] = val;
      return val;
    } else {
      return "";
    }
  }
  return globalCache["url_queryString"][key];
}

function getAbsolutePath(url) {
  if (url.indexOf("~/") == 0) {
    url = dsf.url.getWebPath(url.substr(1));
  } else if (url.indexOf("/") == 0) {
    url = dsf.url.getWebPath(url.substr(1));
  }
  return url;
}


let XEUtils_Lit = {
  helperGetLocatOrigin() {
    return window.location ? (window.location.origin || (window.location.protocol + '//' + window.location.host)) : ''
  },
  parseURLQuery(uri) {
    return XEUtils_Lit.unserialize(uri.split('?')[1] || '')
  },
  unserialize(str) {
    var items
    var result = {}
    if (str && helper.isString(str)) {
      _.each(str.split('&'), function (param) {
        items = param.split('=')
        result[decodeURIComponent(items[0])] = decodeURIComponent(items[1] || '')
      })
    }
    return result
  }
}
function parse(url) {
  // return XEUtils.parseUrl(url)
  var hashs, portText, searchs, parsed
  var href = '' + url
  var staticLocation = window.location;
  if (href.indexOf('//') === 0) {
    href = (staticLocation ? staticLocation.protocol : '') + href
  } else if (href.indexOf('/') === 0) {
    href = XEUtils_Lit.helperGetLocatOrigin() + href
  }
  searchs = href.replace(/#.*/, '').match(/(\?.*)/)
  parsed = {
    href: href,
    hash: '',
    host: '',
    hostname: '',
    protocol: '',
    port: '',
    search: searchs && searchs[1] && searchs[1].length > 1 ? searchs[1] : ''
  }
  parsed.path = href.replace(/^([a-z0-9.+-]*:)\/\//, function (text, protocol) {
    parsed.protocol = protocol
    return ''
  }).replace(/^([a-z0-9.+-]*)(:\d+)?\/?/, function (text, hostname, port) {
    portText = port || ''
    parsed.port = portText.replace(':', '')
    parsed.hostname = hostname
    parsed.host = hostname + portText
    return '/'
  }).replace(/(#.*)/, function (text, hash) {
    parsed.hash = hash.length > 1 ? hash : ''
    return ''
  })
  hashs = parsed.hash.match(/#((.*)\?|(.*))/)
  parsed.pathname = parsed.path.replace(/(\?|#.*).*/, '')
  parsed.origin = parsed.protocol + '//' + parsed.host
  parsed.hashKey = hashs ? (hashs[2] || hashs[1] || '') : ''
  parsed.hashQuery = XEUtils_Lit.parseURLQuery(parsed.hash)
  parsed.searchQuery = XEUtils_Lit.parseURLQuery(parsed.search);
  if (parsed.hashQuery.cro_q_str) {
    let params = null;
    params = dsf.aes.decode(parsed.hashQuery.cro_q_str);
    params = dsf.url.queryStringParse(params);
    parsed.hashQuery = params;
  }
  return parsed;
}
function serialize(obj) {
  // return XEUtils.serialize(url)
  return queryStringStringify(obj)
}
function unserialize(queryStr) {
  // return XEUtils.unserialize(url)
  return queryStringParse(queryStr)
}

function queryStringStringify(params, options) {
  let defaultOptions = {
    arrayFormat: "repeat"
  };
  options = dsf.mix({}, defaultOptions, options);
  return qs.stringify(params, options);
}

function queryStringParse(str, options) {
  let defaultOptions = {
    arrayFormat: "repeat"
  };
  options = dsf.mix({}, defaultOptions, options);
  return qs.parse(str, options);
}

function anchorToRoutePath(url) {
  if (url.startsWith("#/")) {
    url = url.substr(2);
  } else if (url.startsWith("#") && !url.startsWith("#/")) {
    url = url.substr(1);
  } else if (url.startsWith("/")) {
    url = url.substr(1);
  }
  url = "/" + url;
  return url;
}
//转换相对路径为绝对路径
function abs(base, href) {
  function parseURI(url) {
    var m = String(url).replace(/^\s+|\s+$/g, '').match(/^([^:\/?#]+:)?(\/\/(?:[^:@]*(?::[^:@]*)?@)?(([^:\/?#]*)(?::(\d*))?))?([^?#]*)(\?[^#]*)?(#[\s\S]*)?/);
    return (m ? {
      href: m[0] || '',
      protocol: m[1] || '',
      authority: m[2] || '',
      host: m[3] || '',
      hostname: m[4] || '',
      port: m[5] || '',
      pathname: m[6] || '',
      search: m[7] || '',
      hash: m[8] || ''
    } : null);
  }
  function removeDotSegments(input) {
    var output = [];
    input.replace(/^(\.\.?(\/|$))+/, '')
      .replace(/\/(\.(\/|$))+/g, '/')
      .replace(/\/\.\.$/, '/../')
      .replace(/\/?[^\/]*/g, function (p) {
        if (p === '/..') {
          output.pop();
        } else {
          output.push(p);
        }
      });
    return output.join('').replace(/^\//, input.charAt(0) === '/' ? '/' : '');
  }

  href = parseURI(href || '');
  base = parseURI(base || '');
  return !href || !base ? null : (href.protocol || base.protocol) +
    (href.protocol || href.authority ? href.authority : base.authority) +
    removeDotSegments(href.protocol || href.authority || href.pathname.charAt(0) === '/' ? href.pathname : (href.pathname ? ((base.authority && !base.pathname ? '/' : '') + base.pathname.slice(0, base.pathname.lastIndexOf('/') + 1) + href.pathname) : base.pathname)) +
    (href.protocol || href.authority || href.pathname ? href.search : (href.search || base.search)) +
    href.hash;
}

function isIframeLoader(url) {
  if (dsf.type(url) == 'object') {
    return url.name == 'iframeloader'
  }
  reg_iframeloader.lastIndex = 0;
  return reg_iframeloader.test(url);
}
/**
 * 解析url
 * @param url
 */
function analyzing(url) {
  let i;
  if (!url || url === '') {
    return {
      url: null,
      protocol: null,
      slash: null,
      host: null,
      port: null,
      path: null,
      queryString: null,
      hash: null,
      query: {}
    };
  }
  url = decodeURIComponent(url);
  if (url.indexOf('#') === 0 || url.indexOf('/#') === 0) {
    return {
      url: null,
      protocol: null,
      slash: null,
      host: null,
      port: null,
      path: null,
      queryString: null,
      hash: url,
      query: {}
    };
  }
  const parse_url = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
  const _result = parse_url.exec(url);
  const names = ['url', 'protocol', 'slash', 'host', 'port', 'path', 'queryString', 'hash'];
  const result = {};
  for (i = 0; i < names.length; i++) {
    result[names[i]] = _result[i];
  }
  result.protocol = result.protocol && (result.protocol + ':');
  result.path = result.slash !== '//' ? ('/' + result.host + (result.path ? '/' + result.path : "")) : '/' + (result.path ? result.path : "");
  result.host = result.slash !== '//' ? null : result.host;
  if (!result.port) {
    result.port = result.protocol === 'http:' ? '80' : result.protocol === 'https:' ? 8080 : '';
  }
  const _query = {};
  result.queryString = result.queryString || '';
  const query = result.queryString.split('&');
  for (i = 0; i < query.length; i++) {
    const item = query[i].trim();
    let _i = item.indexOf('='),
      _v = '',
      _k = '';
    if (item !== '') {
      if (_i === -1) {
        _k = item;
      } else {
        _v = item.substr(_i + 1);
        _k = item.replace('=' + _v, '');
      }
      if (!_query[_k]) {
        _query[_k] = _v;
      } else if (typeof _query[_k] === 'string') {
        _query[_k] = [_query[_k], _v];
      } else {
        _query[_k] = _query[_k].push(_v);
      }
    }
  }
  result.query = _query;
  return result;
}

function hashString(key) {
  var url = window.location.hash;
  var oRegex = new RegExp('[#&]' + key + '=([^&]+)', 'i');
  var oMatch = oRegex.exec(url);
  if (oMatch && oMatch.length > 1) {
    var val = decodeURI(oMatch[1]);
    return val;
  } else {
    return "";
  }
}


export default {
  getWebPath,
  // getAssetsDir,
  queryString,
  getAbsolutePath,
  queryStringStringify,
  parse,
  queryStringParse,
  analyzing,
  hashString,
  anchorToRoutePath,
  toRoutePath,
  getMobileLogin,
  isIframeLoader,
  abs,
  serialize,
  unserialize
};