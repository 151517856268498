import Browser from "./Browser";
import dsf from ".";

let ua = navigator.userAgent; //取得浏览器的userAgent字符串
let info = new Browser();

function checkBrowser() {
  if (info.browser === 'IE') {
    if (info.version === '9.0') {
      return "IE9";
    } else if (info.version === '10.0') {
      return "IE10";
    } else if (info.version === '11.0') {
      return "IE11";
    }
    return "IE";
  }
  if (info.browser === 'Opera') {
    return "Opera";
  }
  if (info.browser === 'Edge') {
    return "Edge";
  }
  if (info.browser === 'Firefox' || info.browser === 'Firefox Focus') {
    return "FF";
  }
  if (info.browser === 'Safari') {
    return "Safari";
  }
  if (info.browser === 'Chrome' || info.browser === 'Chromium') {
    return "Chrome";
  }
  return info.browser;
}
export default {
  info,
  lessThenIE(max) {
    if (dsf.client.type.indexOf("IE") == 0) {
      let arr = ["IE6", "IE7", "IE8", "IE9", "IE10", "IE11"];
      let index = arr.indexOf('IE' + max);
      let currentIndex = arr.indexOf(dsf.client.type);
      if (index < currentIndex) {
        return true;
      }
    }
    return false;
  },
  parse: function (userAgent) {
    return new Browser(userAgent);
  },
  // 是否是mac设备
  isMac: navigator.platform.match("Mac"),
  // 是什么浏览器
  type: checkBrowser(),
  /********设备********/
  //是否是pc
  pc: function () {
    return info.device === 'PC';
  },
  //是否是移动端
  mobile: function () {
    return info.device === 'Mobile';
  },
  //是否是平板
  pad: function () {
    return info.device === 'Tablet';
  },
  /********系统********/
  //是否是ios
  iOS: function () {
    return info.os === 'iOS';
  },
  //是否是Android
  Android: function () {
    return info.os === 'Android';
  },
  //是否是Windows
  Windows: function () {
    return info.os === 'Windows';
  },
  //是否是MacOs
  MacOs: function () {
    return info.os === 'Mac OS';
  },
  /********浏览器********/
  IE: function () {
    return info.browser === 'IE';
  },
  // 360
  360: function () {
    return info.browser === '360' || info.browser === '360SE' || info.browser === '360EE';
  },
  //safari浏览器
  safari: function () {
    return info.browser.toLowerCase() === 'safari';
  },
  //qq浏览器
  qq: function () {
    return !!ua.match(/mqqbrowser|qzone|qqbrowser|qbwebviewtype/i)
  },
  //是否是微信打开
  weixin: function () {
    ua = ua.toLowerCase()
    return (ua.match(/micromessenger/i) && ua.match(/micromessenger/i)[0] === 'micromessenger') || (ua.match(/wechatdevtools/i) && ua.match(/wechatdevtools/i)[0]) === 'wechatdevtools'
  },
  wechat: function () {
    return this.weixin()
  },
  // 是否是 cardova
  native: function () {
    return new RegExp(/DreamSoft|DreamsSoft/i).test(ua)
  },
  //是否是钉钉打开
  dingding: function () {
    if (dsf.client.iOS()) {
      return ua.toLowerCase().indexOf('dingtalk') > -1
    }
    if (dsf.client.android()) {
      return ua.toLowerCase().indexOf('com.alibaba.android.rimet') > -1
    }
  },
  //是否是专有钉钉(有点虚，不敢保证)
  govDingding() {
    return ua.toLowerCase().indexOf('taurusapp') > -1
  },
  //是否是钉钉、微信、政务钉钉、企业微信
  ddOrWeChart() {
    return this.wechat() || this.dingding() || this.govDingding();
  },
  // 是鸿蒙
  harmony() {
    return ua.toLowerCase().indexOf("harmony dreamsoft") > -1
  },
  //是否是安卓
  android() {
    return ua.toLowerCase().indexOf("android") > -1 && ua.toLowerCase().indexOf("linux") > -1;
  }
}