import dsf from '../utils'
import mixins from '../mixins';
import { dynamicComponent } from '../output';

//dsf母体
!function () {
  dsf.mix(dsf, {
    "dynamicComponent": dynamicComponent
  });
  dsf.global.$mixins = mixins;
}();