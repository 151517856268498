// let rxp_viewdata = /^\$viewData(\.|\[.+\])/g
import * as common from '../output/layoutViewCommon'

let form = {
  provide() {
    return {
      $form: this
    };
  },
  props: {
    nameSpace: {
      type: String,
      default: ""
    },
    pageName: {
      type: String,
      default: ""
    },
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    //表单执行loadData时不向服务器发送请求而使用localData传进来的数据
    localData: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      _isForm: true,
      loading: false,
      isView: false,
      ignoreValidate: false,
      validating: false,
      _errors: {},
      savedCloseTime: 1000
      // formItemInnerValidates: {}
    };
  },
  computed: {
    $errors() {
      return this.$data._errors;
    },
    $isForm() {
      return this.$data._isForm;
    },
    $subTables() {
      return _.filter(this.allComponents, (it) => {
        return it.isFormControl && it.isOneToMany
      })
    },
    $postFields() {
      return _.filter(this.allComponents, (it) => {
        return it.isFormControl
      })
    },
    $postFieldMap() {
      return _.keyBy(this.$postFields, '$formName');
    }
  },
  beforeCreate() {
  },
  created() {
    this.$data.$forceReadOnly = this.queryString.isview == 1;
  },
  dataLoaded(data, next) {
    next();
  },

  beforeDestroy() {
    for (let k in this.metaDataAndCtrls) {
      delete this.metaDataAndCtrls[k];
    }
    this.metaDataAndCtrls = null;
  },
  methods: {
    //调用数据加载接口
    async $loadData() {
      if (this.localData) {
        return {
          data: this.localData
        };
      }
      else {
        if (this.$dataKey) {
          let params = {
            ...this.queryString,
            pageName: this.pageName,
            namespace: this.nameSpace,
            data: JSON.stringify({
              _id: this.$dataKey
            })
          };
          try {
            let result = await this.$getFormDataAPI(params);//await this.$webAPI.getFormData(params, true);
            return result.data;
          } catch (err) {
            throw err;
          }
        }
      }
    },
    //表单提交操作后的更新
    async $updateData() {
      try {
        this.loading = true;
        let viewData = {};
        let initData = await this.$initData();
        dsf.mix(this.context.$$viewInitData, initData.data);
        viewData = this.$initControls(this);
        let data = await this.$loadData();
        data = dsf.mix(viewData, data?.data || null);
        this.context.$$viewData = data;
        // if (this.$options.updateData) {
        //   await promiseFn.call(this, this.$options.updateData, [opt]);
        // }
        common.mergeExecCycleFunctions(this, 'updateData', [opt], common.postPromiseFn);
      }
      catch (error) {
        dsf.httpError(error, { isSilent: true });
      }
      finally {
        this.loading = false;
      }
    },
    //表单验证（不触发验证前后的钩子）
    async validate(vdatakey, index) {
      let dict = this.metadataDict;
      let allKeys = this.metadataKeyList;
      let keys = [];
      //全局验证
      if (arguments.length == 0) {
        keys = _.filter(allKeys, (it) => {
          if (!dict[it].inSubTable) {
            return true
          }
        })
      }
      else {
        keys = (dsf.type(vdatakey) == 'array' ? vdatakey : [vdatakey])
      }
      let arrs = this.$getValidateFns(keys, dict, index);
      return await this.$execValidateFns(arrs);
    },
    //表单验证（触发验证前后的钩子）
    async formValidate(opt) {
      this.validating = true
      let validateBefore = this.$options.validateBefore;
      //表单验证后触发
      let validateAfter = this.$options.validateAfter;
      try {
        await common.mergeExecCycleFunctions(this, 'validateBefore', [opt], common.postPromiseFn);
        if (!this.ignoreValidate) {
          //执行验证函数
          await this.validate();
        }
        this.clearErrors();
      } catch (ex) {
        let err = ex;
        if (dsf.isArray(err)) {
          this.pushError(err);
          this.$scrollToError(err);
          err = {
            type: 'ValidateError',
            result: ex,
            message: "表单校验错误"
          }
        }
        throw err;
      } finally {
        await common.mergeExecCycleFunctions(this, 'validateAfter', [opt], common.postPromiseFn);
        this.validating = false
      }
    },
    //验证组件
    async validateField(value, index, field) {
      let errors = [];
      if (!field) {
        return;
      }
      try {
        if (!field.isOneToMany) {
          //主表或者子表中单个表单组件验证
          this.removeErrorsByTarget(field.$formName);
          await this.validate(field.formName, index);
        }
        else {
          //如果是子表整表或者指定行验证
          let subTableKey = field.formName;
          let subKeys = _.filter(this.metadataKeyList, (it) => {
            return this.metadataDict[it].inSubTable == subTableKey;
          });
          let rows = value;
          //如果指定了行，则使用行验证
          if (dsf.isDef(index)) {
            rows = [value[index]];
          }
          _.each(rows, (row) => {
            this.removeErrorsByRowHash(row.$hash);
          })
          await this.validate(field.formName, index);
        }
        return [];
      }
      catch (err) {
        if (dsf.type(err) == "array") {
          this.pushError(err);
        }
        return err;
      }
    },
    //重置表单验证结果
    resetValidate() {
      var fields = this.allComponents;
      this.clearErrors();
      _.each(fields, (field) => {
        field.errors = [];
      });
    },
    //添加表单组件
    $addFiled(ctrl) {
      this.allComponents.push(ctrl);
      this.$nextTick(() => {
        this.updateMetaDataDict(ctrl);
      });
    },
    //表单组件被销毁
    $removeFiled(ctrl) {
      if (this.metadataDict[ctrl.formName]) {
        //refs用来存储组件实例，组件移除时将refs中的实例也移除掉
        dsf.array.remove(this.metadataDict[ctrl.formName].refs || [], ctrl)
        // console.log(ctrl.formName,this.metadataDict[ctrl.formName].refs)
      }
      dsf.array.removeWith(this.allComponents, (it) => it == ctrl);
    },
    // 更新metadataDict，因为页面中有存在动态创建的组件（子表，动态布局）
    updateMetaDataDict(ctrl) {
      if (this.metadataDict) {
        let formName = ctrl.formName;
        let fullCode = ctrl.metadata_fullcode;
        let metadata = ctrl.metadata;
        if (!metadata || !formName) {
          return;
        }
        let arr = formName.split(".");
        let prefix = arr[0];
        let name = arr[1] ? arr[1] : "";
        let isUser = false;
        //子表
        if (ctrl.isOneToMany) {
          if (!this.metadataDict[formName]) {
            this.metadataDict[formName] = {
              type: "subtable",
              fieldsLength: 0,
              valueAttributes: [],
              level: 1,
              refs: []
            };
            isUser = true;
          }
          //refs用来存储组件实例，表单验证时可以判断refs中是否存在组件，如果为空数组则表示该key下没有可以验证的组件
          this.metadataDict[formName].refs = this.metadataDict[formName].refs || [];
          this.metadataDict[formName].valueIsHtml = ctrl.valueIsHtml === true ? true : false
          this.metadataDict[formName].validate = metadata.validate;
          this.metadataDict[formName].refs.push(ctrl);
          this.metadataDict[formName].allowPost = ctrl.allowPost === false ? false : true
          dsf.array.ensure(this.metadataKeyList, formName);
        }
        else {
          //主表或者子表内的表单组件
          if (!this.metadataDict[formName]) {
            this.metadataDict[formName] = {
              type: "field",
              fieldsLength: metadata.valueAttributes && metadata.valueAttributes.length,
              level: 0,
              valueAttributes: _.map(metadata.valueAttributes, "code"),
              refs: []
            };
            isUser = true;
          }
          if (ctrl.$list && this.metadataDict[ctrl.$list.formName]) {
            this.metadataDict[formName].level = 1;
            this.metadataDict[formName].inSubTable = ctrl.$list.formName;
          }
          this.metadataDict[formName].refs = this.metadataDict[formName].refs || [];
          this.metadataDict[formName].valueIsHtml = ctrl.valueIsHtml === true ? true : false
          this.metadataDict[formName].validate = metadata.validate;
          this.metadataDict[formName].refs.push(ctrl);
          this.metadataDict[formName].allowPost = ctrl.allowPost === false ? false : true
          dsf.array.ensure(this.metadataKeyList, formName);
        }
        if (this.metadataDict[formName]) {
          this.metadataDict[formName].isUser = isUser;
          if (ctrl.validateRule) {
            this.metadataDict[formName].validateRule = ctrl.validateRule;
          }
        }
      }
    },
    //轮询调用所有组建的postBefore
    $postBefore() {
      let arr = [];
      _.each(this.allComponents, (field) => {
        if (field.isFormControl) {
          let fn = field.postBefore;
          if (fn) {
            arr.push(fn());
          }
        }

      });
      return Promise.all(arr);
    },
    //轮询调用所有组建的postAfter
    $postAfter() {
      let arr = [];
      _.each(this.allComponents, (field) => {
        if (field.isFormControl) {
          let fn = field.postAfter;
          if (fn) {
            arr.push(fn());
          }
        }
      });
      return Promise.all(arr);
    },
    //轮询调用所有组建的postError
    $postError() {
      let arr = [];
      _.each(this.$postFields, (field) => {
        let fn = field.postError;
        if (fn) {
          arr.push(fn());
        }
      });
      return Promise.all(arr);
    },
    $scrollToError(errs) {
      if (errs) {
        try {
          let valideResult = dsf.isArray(errs) ? errs : [errs];
          if (valideResult && valideResult.length > 0) {
            let target = valideResult[0].target;
            let field = null;
            //如果是字符串则使用dom选择器定位
            if (target && dsf.isString(target)) {
              field = this?.$el?.querySelector?.(`[form-name="${target}"]`);
            }
            //如果是dom节点则直接赋值给field
            else if (target && target.nodeType == 1) {
              field = target;
            }
            //如果是vue组件则获取$el属性赋值给field
            else if (target && target.__vue__) {
              field = target?.$el;
            }
            if (field && field.nodeType == 1) {
              field.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
            }
          }
        }
        catch (ex) {
          dsf.warn("无法正确定位到错误元素");
        }
      }

    },
    //表单保存
    async formSave(options, afterCallback, isSyncSave, isReturnYes) {
      let isAddNew = false;
      if (!this.$dataKey) {
        isAddNew = true;
      }
      options = options || {};
      let opt = {
        isRefresh: false,
        savedClose: false,
        successMsg: "保存成功",
        errorMsg: "保存失败",
        isSilent: false
      };
      opt = dsf.mix(opt, options);
      try {
        let result = await this.$formSave(opt, isReturnYes);
        if (result?.data?._id) {
          if (afterCallback && dsf.isFunction(afterCallback)) {
            await afterCallback(result);
          }
          if (opt.savedClose) {
            this.loading = true;
            this.$dataKey = result.data._id;
            window.setTimeout(() => {
              this.loading = false;
              this.$closeWindow && this.$closeWindow();
            }, this.savedCloseTime);
          }
          else {
            this.$dataKey = result.data._id;
            // await this.$updateData();
            for (let k in result.data) {
              this.$set(this.$viewData, k, result.data[k]);
            }
            this.$updateUrl(result.data);
          }
        }
        return result;
      }
      catch (ex) {
        dsf.error(ex)
        throw ex;
      }
    },
    //表单保存私有方法
    async $formSave(options) {
      this.loading = true;
      let saveResult = null;
      let saveBefore = this.$options.saveBefore;
      let saveAfter = this.$options.saveAfter;
      let saveComplete = this.$options.saveComplete;
      try {
        // 清空所有校验错误信息
        this.resetValidate();
        //轮询执行所有组件提交前事件
        await this.$postBefore();
        //全局校验表单并滚动到错误组件
        await this.formValidate(options);
        // await formValidateAndScrollTo.call(this, options);
        //保存时强行结束掉所有子表的编辑状态
        _.each(this.$subTables, (sub) => {
          sub.editAllEnd && sub.editAllEnd();
        })
        // if (saveBefore) {
        //   //执行表单保存前钩子函数
        //   await promiseFn.call(this, saveBefore, [options], true);
        // }
        await common.mergeExecCycleFunctions(this, 'saveBefore', [options], common.postPromiseFn);
        await common.waitViewUpdated(this);
        //表单保存
        let result = null;
        try {
          result = await this.$save(options);
        } catch (ex) {
          throw ex;
        }
        //更新表单数据
        saveResult = result;
        //轮询执行所有组件的postAfter
        await this.$postAfter(result);
        // if (saveAfter) {
        //   //执行表单保存后钩子函数
        //   await promiseFn.call(this, saveAfter, [saveResult.data, options]);
        // }
        await common.mergeExecCycleFunctions(this, 'saveAfter', [saveResult.data, options], common.postPromiseFn);
        if (!options.isSilent) {
          this.$message(options.successMsg, true);
        }
        return result;
      } catch (ex) {
        let throwError = null;
        //表单校验错误
        if (dsf.isObject(ex) && ex.type == 'ValidateError') {
          throwError = ex;
        }
        //抛出错误代码，有state表示后端返回的错误，20001的话提示报错错误,其他的显示后端返回的错误代码
        else if (dsf.isObject(ex) && dsf.hasOwn(ex, "state")) {
          let message = ex.state === 20001 ? options.errorMsg : ex.message;
          this.$message(message, false);
        }
        //如果错误直接是文本，直接提示错误
        else if (dsf.type(ex) === "string") {
          this.$message(ex, false);
        }
        //程序出现错误
        else if (dsf.type(ex) === "error") {
          this.$message(options.errorMsg, false);
          throwError = ex;
        }
        saveResult = null;
        //轮询执行所有组件的postError
        await this.$postError();
        if (throwError) {
          throw throwError;
        }
      } finally {
        if (saveResult?.data) {
          this.$data._postState = 'FORMSAVE';
          this.$data._postState = '';
        }
        this.ignoreValidate = false;
        if (options.isRefresh && saveResult?.data?._id) {
          this.reloadParentViewData();
        }
        if (saveComplete) {
          //执行表单保存完成（无论成功失败）的钩子函数
          saveComplete.call(this, saveResult);
        }
        this.loading = false;
        saveResult = null;
      }
    },

    //提交数据
    async $save(options) {
      let _this = this;
      let data = this.$getPostData(options);
      let postDataBefore = this.$options.postDataBefore;
      let postDataAfter = this.$options.postDataAfter;

      //数据被提交前的处理
      // if (postDataBefore) {
      //   await promiseFn.call(this, postDataBefore, [data, options]);
      // }
      await common.mergeExecCycleFunctions(this, 'postDataBefore', [data, options], common.postPromiseFn);
      let params = {
        namespace: this.nameSpace,
        pageName: this.pageName,
        data: data,
        attach: JSON.stringify({})
      };
      if (this.$dataKey) {
        params.data["_id"] = this.$dataKey;
      }
      //保存时参数处理
      let saveParams = this.getSavePostParams;
      if (saveParams) {
        saveParams.call(this, params);
      }
      params.data = this.$postDataToJson(params.data);
      try {
        //调用接口
        let result = await this.saveAPI(params);
        if (result && result.data) {
          let data = result.data;
          let successCode = "20000";
          if (data.state == successCode) {
            _.each(this.$subTables, (table) => {
              table.deleted = [];
            });
          }
          // if (postDataAfter) {
          //   //提交后的数据处理
          //   await promiseFn.call(this, postDataAfter, [data, options]);
          // }
          await common.mergeExecCycleFunctions(this, 'postDataAfter', [data, options], common.postPromiseFn);
          if (data.state == successCode) {
            return data;
          } else {
            throw data;
          }
        }
      } catch (err) {
        throw err;
      }
    },
    //调用表单保存api

    //表单保存成功后获取保存结果中的主键更新url中的id参数
    $updateUrl(result) {
      let id = result._id;
      if (this?.$frame?.independent) {
        this.$frame.urlChange({
          pageName: this.pageName,
          nameSpace: this.nameSpace,
          query: { ...this.$localParams, id: id },
        }, true)
      }
      //如果页面在dialog中不更新url中的id参数
      else if (this.$dialog) {
        // this.queryString.id = result._id;
        let pageName = this.$dialog.currentView.pageName;
        let nameSpace = this.$dialog.currentView.nameSpace;
        let localParams = this.$dialog.currentView.localParams
        this.$dialog.currentView && this.$dialog.currentView.urlChange({
          pageName: pageName,
          nameSpace: nameSpace,
          query: { ...localParams, id: id },
        }, true)
      } else {
        let params = dsf.mix({}, this.$route.query, {
          id: result._id
        });
        //更新url添加id参数
        this.$router.replace({
          path: this.$route.path,
          query: params
        });
      }
    },
    // getFormComponentByName(formName) {
    //   let el = document.querySelector("[form-name='" + formName + "']");
    //   if (el && el.__vue__) {
    //     return el.__vue__;
    //   }
    //   return null;
    // },
    getSubTable(key) {
      return _.find(this.$subTables, (it) => {
        return it.metadata_fullcode == key;
      })
    },
    async yes() {
      try {
        // 清空所有校验错误信息
        this.resetValidate();
        //轮询执行所有组件提交前事件
        await this.$postBefore();
        //全局校验并滚动到错误组件
        await this.formValidate({});
        let data = this.$getPostData();
        return data;
      }
      catch (ex) {
        dsf.error(ex)
        this.$postError();
        return false;
      }
    },
    //保存数据API接口
    saveAPI(params) {
      return this.$webAPI.formSave(params, true);
    },
    //获取表单数据API接口
    $getFormDataAPI(params) {
      return this.$webAPI.getFormData(params, true);
    },
    //提交数据的处理
    $getPostData(options = {}) {
      let _this = this;
      let data = dsf.mix(true, {}, this.$viewData);
      //处理子表数据
      _.each(this.$subTables, (table) => {
        let key = table.metadata_fullcode;
        let tableData = data[key] || [];
        if (tableData.length == 1) {
          //判断当前行是否所有值都为空
          let isValid = false;
          for (let k in tableData[0]) {
            if (k.indexOf("$") != 0) {
              if (dsf.isDef(tableData[0][k]) && !dsf.isEmptyObject(tableData[0][k])) {
                isValid = true;
                break;
              }
            }
          }
          if (!isValid) {
            tableData = []
          }
        }
        if (table.deleted) {
          let deletedRows = [];
          _.each(table.deleted, (delRow) => {
            if (delRow["_id"]) {
              let row = _.find(tableData, (row) => row["_id"] == delRow["_id"]);
              if (!row) {
                delRow[key + ".ds_deleted"] = 1;
                deletedRows.push(delRow);
              }
            }
          });
          data[key] = tableData.slice(0).concat(deletedRows);
        }

        _.each(tableData, (row, i) => {
          if (row[key + ".ds_deleted"] != 1) {
            row[key + ".ds_deleted"] = 0;
          }
          row[key + ".ds_order"] = i;
        });

        // clearInValidMetaDataValueAttrs.call(this, data);
      });
      this.$filterInValidMetaDataValueAttrs(data);
      return data;
    },
    $postDataToJson(data, ignoreSystemField = true) {
      let _this = this;
      let systemKeys = [dsf.config.kw.updatetime, dsf.config.kw.updateusername, dsf.config.kw.updateuserid, dsf.config.kw.createuserid, dsf.config.kw.createusername, dsf.config.kw.createtime];
      let jsonStr = JSON.stringify(data, function (key, val) {
        // $开头的数据为前端产生的结构，不提交服务器否则会导致服务端
        if (!key.startsWith("$")) {
          let ignore = false;
          if (_this.metadataDict?.[key]?.allowPost === false) {
            ignore = true;
          }
          if (!ignore && ignoreSystemField) {
            //判断当前key是否是系统字段，如果是则忽略
            ignore = _.find(systemKeys, (it) => key.endsWith(`.${it}`));
          }
          return ignore ? undefined : val;
        }
        return undefined;
      });
      return jsonStr;
    },
    //过滤非法值属性
    $filterInValidMetaDataValueAttrs(data) {
      _.forOwn(data, (v, key) => {
        if (this.metadataDict[key]) {
          let meta = this.metadataDict[key];
          let va = meta.valueAttributes;
          if (meta.type == 'field') {
            if (va.length > 1) {
              filterInValidValueAttr(meta, v);
            }
          }
          else if (meta.type == 'subtable') {
            if (dsf.isDef(v)) {
              _.each(v, (row, i) => {
                this.$filterInValidMetaDataValueAttrs(row);
              });
            }
          }
        }
      });
    },
    $getValidateMetaList() {
      return this.metadataKeyList;
    }
  },
  watch: {
    //如果参数id变化更新$dataKey
    "queryString.id": function (v) {
      this.$isForm && (this.$dataKey = v);
    },
  }
};

//子表数据是否是个空行（没有任何数据）
function isEmptyRow(row) {
  let mark = false;
  for (let k in row) {
    if (k.indexOf("$")) {
      k.indexOf()
    }
  }
}

//获取组件所属的子表
function getSubTableForCtrl(ctrl) {
  if (ctrl.$list && ctrl.$list.isOneToMany) {
    return ctrl.$list;
  }
  return null;
}

//过滤值属性
function filterInValidValueAttr(meta, v) {
  let va = meta.valueAttributes;
  if (dsf.isObject(v)) {
    for (let k in v) {
      if (!~va.indexOf(k)) {
        delete v[k];
      }
    }
  }
  else if (dsf.isArray(v)) {
    _.each(v, (cv, i) => {
      if (dsf.isObject(cv)) {
        for (let k in cv) {
          if (!~va.indexOf(k)) {
            delete cv[k];
          }
        }
      }
    })
  }
}
export default form;
