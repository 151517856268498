import control from "./control";
export default {
  design: {
    settingButton: true,
    settingMenus: [
      {
        text: "静态数据维护",
        command: "tree-static-data",
        handler: addStaticData,
        hidden() {
          return true
          // return !this.$isPrototype;
        },
      },
    ],
  },
};

//列表添加静态数据
function addStaticData(item, proxy) {
  const self = this;
  this.$openDialog({
    title: "数据维护",
    content: "DesStaticTreeData",
    width: "90vw",
    height: "60vh",
    params: {
      value: self.staticValue || [],
    },
    btns: [
      {
        text: "确定",
        handler(res) {
          self.staticValue = res?.yes();
        },
      },
      {
        text: "清除",
        handler() {
          self.staticValue = null;
        },
      },
      {
        text: "取消",
      },
    ],
  });
}
