var drive = {
  type: "ios",
  isEnv() {
    let { mobile, iOS, native } = dsf.client
    return native() && mobile() && iOS()
  },
  init(opt = {}) {
    try {
      this.methods.camera.scan = this.methods.scan
      return new Promise((resolve, reject) => {
        let { sdkUrl } = opt
        if (!sdkUrl) {
          throw '请传入_ios.sdkUrl'
        }
        dsf.http.importFiles(opt.sdkUrl).then(([{ state }]) => { // 加载sdk
          if (!state) {
            console.error("cordova.js文件出错")
            reject(`${sdkUrl}文件出错`)
          }
          document.addEventListener(
            "deviceready",
            () => {
              this.methods.isDeviceready = true;
              if (this.methods._devicereadyEventListener.length) {
                this.methods._devicereadyEventListener.map((cb) => cb())
              }
              if (!window.dsfMobileLocationBack) {
                window.dsfMobileLocationBack = function () {
                  window.location.back();
                };
              }
              if (!window.backbutton) {
                window.backbutton = backbutton
                document.addEventListener('backbutton', backbutton, false)
              }
              setTimeout(() => {
                resolve()
              }, 0)
            },
            false
          )
        }).catch((err) => {
          reject(err)
        })
      })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  methods: {
    // 扫一扫
    scan(opt = {}) {
      return new Promise((resolve, reject) => {
        try {
          $Scanner.open(
            function (res) {
              let result = res.text
              resolve(result)
            },
            function (error) {
              dsf.layer.message(error, false);
              reject(error)
            }
          );
        } catch (error) {
          reject(error)
        }
      })
    },
    // 关闭开机画面
    hideSplashScreen() {
      if (window.WelcomePage && window.WelcomePage.hideen) {
        window.WelcomePage.hideen();
      }
    },
    // 新开页面
    open(opt = {}) {
      return new Promise((resolve, reject) => {
        let _opt = this._getFuncOpts(
          {
            showTitleBar: false,
            orientation: 'SCREEN_ORIENTATION_LANDSCAPE',
            url: '',
            title: '',
            ...opt
          },
          opt
        )
        if (!_opt.url) throw '请传入url'
        try {
          $OpenWebActivityPlugin?.openWebPage(JSON.stringify(_opt))
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    // 下载文件
    async downloadFiles(opt = {}) {
      try {
        if (dsf.type(opt.files) == 'string') {
          opt.files = JSON.parse(opt.files)
        }
        let _opt = this._getFuncOpts(
          {
            files: [],
            type: 'local', // local 下载到本地 ； photo 下载到相册
          },
          opt
        )
        if (!_opt.files.length) {
          console.warn('请传入opt.files')
          return reject('请传入opt.files')
        }
        for (let i = 0; i < _opt.files.length; i++) {
          const file = _opt.files[i];
          await MFileDownload({ file, type: _opt.type })
        }
        for (let i = 0; i < _opt.files.length; i++) {
          const file = _opt.files[i];
          await MFileDownload({ file, type: _opt.type })
        }
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}

drive.methods = {
  ...drive.methods,
  _devicereadyEventListener: [],
  // 相机相关
  camera: {
    //扫描二维码
    scan() {
      return new Promise((resolve, reject) => {
        if (dsf.client.iOS() && window.$Scanner) {
          // ios 调用插件
          $Scanner.open(
            function (res) {
              let result = res.text
              resolve(result)
            },
            function (error) {
              dsf.layer.message(error, false);
              reject(error)
            }
          );
        } else {
          dsf.layer.message('无法调起扫码功能！', false)
          reject('无法调起扫码功能！')
        }
      })
    },
    // 计时拍照
    takePhoto(opt = {}) {
      opt = {
        // 计时
        time: 5,
        COUNT_DOWN: true,
        SHOW_BUTTON: false,
        ...opt
      };
      return new Promise((resolve, reject) => {
        _DsfRecord.takePhoto(opt, () => {
          resolve()
        }, () => {
          reject()
        })
      })
    }
  },
  // 应用就绪 api 可用
  deviceready(cb) {
    if (!dsf.type(cb) == 'function') return console.warn('deviceready 必须传入一个回调函数')
    if (this.isDeviceready) return cb()
    this._devicereadyEventListener.push(cb)
  },
  // 定位
  location: {
    // 获取百度定位
    getBD() {
      return new Promise((resolve, reject) => {
        $BaiduLocation.getLocation("", (res) => {
          res = JSON.parse(res)
          let address = {
            address: res.address,
            lng: res.longitude,
            lat: res.latitude,
            accuracy: res.accuracy,
            speed: res.speed,
          }
          resolve(address)
        }, (res) => {
          reject(res)
        });
      })
    }
  },
  // 获取设备码
  getDeviceSerialId() {
    return new Promise((resolve, reject) => {
      $DeviceIdPlugin?.getDeviceSerialId((data) => {
        resolve(data)
      }, (err) => {
        reject(err)
      });
    })
  },
  // 订阅照片回调
  subGlobalPhoto(cb) {
    if (!dsf.type(cb) == 'function') return console.warn('subGlobalPhoto 必须传入一个回调函数')
    if (!window.pluginCameraResult) {
      window.pluginCameraResult = (res) => {
        /**
         * res
         * {"file":"","suffix":"","name":"","size":""}
         */
        cb(res)
      }
    }
  },
  // 存储
  storage: {
    // 设置
    set(key, value) {
      return new Promise((resolve, reject) => {
        $sharedPreferences.save({ [key]: value }, () => {
          resolve()
        }, (e) => {
          reject(e)
        });
      })
    },
    // 获取
    get(key) {
      return new Promise((resolve, reject) => {
        $sharedPreferences.get(key, (val) => {
          resolve(val)
        }, (e) => {
          reject(e)
        });
      })
    },
    // 删除
    remove(key) {
      return new Promise((resolve, reject) => {
        $sharedPreferences.remove(key, (val) => {
          resolve(val)
        }, (e) => {
          reject(e)
        });
      })
    },
    // 清除
    claerAll() {
      return new Promise((resolve, reject) => {
        $sharedPreferences.clear(() => {
          resolve()
        }, (e) => {
          reject(e)
        });
      })
    }
  },
  // 打开新的 webpage页面
  openWebPage(opt = {}) {
    opt = {
      showTitleBar: false,
      orientation: 'SCREEN_ORIENTATION_LANDSCAPE',
      url: '',
      title: '',
      ...opt
    }
    return new Promise((resolve, reject) => {
      $OpenWebActivityPlugin?.openWebPage(JSON.stringify(opt))
      resolve()
    })
  },
  // 打开系统浏览器
  openSystemWeb(opt = {}) {
    opt = {
      url: "",
      ...opt
    }
    if (!opt.url) {
      return new Promise.reject('url 必须传')
    }

    return new Promise((resolve, reject) => {
      windowApp?.openApp({ "appUrl": "", "appDownUrl": opt.url }, function (res) {
        resolve(res);
      }, function (res) {
        reject(res);
      });
    })
  }
}
// 下载文件
function MFileDownload({ file, type }) {
  return new Promise((resolve, reject) => {
    $MFile.download(
      {
        remoteFileAddr: window.location.origin + dsf.url.getWebPath(file.relativePath),
        target: '/' + file.originalFileName,
        type: type == 'photo' ? '2' : null,
      },
      function (resp) {
        resolve(resp)
      }, function (error) {
        reject(error);
      });
  })
}

let time;
let backClick;
let backReady = true;
function backbutton() {
  if (!backReady) {
    return
  }
  backReady = false
  setTimeout(function () {
    backReady = true
  }, 100)
  if (time == 0) {
    time = (new Date()).getTime(); // 2s内再次点击就退出
  }
  // 监听返回按钮
  const navs = $('#app')[0].__vue__.$root.navs
  const url = dsf.url.parse(window.location.href).hashKey
  if (url == '/' || url == '/mobile/login' || navs.findIndex(({ href }) => href == url) >= 0) {  // 处于app首页,满足退出app操作
    let tmpTime = (new Date()).getTime()
    let isShort = (tmpTime - time < 4000)
    if (isShort && backClick != 0) {
      navigator.app.exitApp()
      return
    } else if (isShort) {
      backClick++
    } else {
      backClick = 0
    }
    dsf.layer.message("再按一次退出程序")
    time = tmpTime
    return
  } else {
    backClick = 0
    history.back()
  }
}


export default drive