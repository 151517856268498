//错误类
function ValidateError(message, target, other) {
  this.message = message;
  this.target = target;
  if (other) {
    this.info = {
      ...other
    };
  } else {
    this.info = {};
  }
}
//开放全局变量，二次开发时无法使用import时可以使用该全局变量混入
if (window) {
  window.$$ValidateError = ValidateError;
}
export default ValidateError