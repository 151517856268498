// import { mmtype, dataType, getDataType, validate, addMetaData, getMetaDataByKey, getAllMetaData, useMetaData } from "../register/metadata";
// import platformMetadata from "../metadata";
// import validate from './validate'
let metadataMap = {};
let dataType = [
  { text: "文本", value: "string" },
  { text: "日期", value: "date" },
  { text: "数字", value: "number" },
  { text: "对象", value: "object" },
  { text: "时间", value: "time" },
  { text: "大对象", value: "longObject" },
];
let mmtype = function (num) {
  let v = {
    1: { value: "1", text: "基础元数据" },
    2: { value: "2", text: "系统元数据" },
    3: { value: "3", text: "业务元数据" },
  };
  return v[num];
};

let getDataType = function (type) {
  return _.find(dataType, (v) => {
    return v.value == type;
  });
};

let validate = function () {
  // return dsf.validate;
  return null;
};

function add(key, metadata) {
  // key = dsf.kebabCase(key);
  if (!metadataMap[key]) {
    metadataMap[key] = metadata;
  } else {
    dsf.warn(key + "元数据已经存在");
  }
  return metadataMap[key];
}

function get(key, obj) {
  // key = dsf.kebabCase(key);
  let m = metadataMap[key](dsf);
  m.ckey = key;
  return dsf.mix(m, obj || {});
}

function use(obj) {
  for (let k in obj) {
    add(k, obj[k]);
  }
}

function all() {
  return metadataMap;
}
function getField(metadata) {
  if (dsf.isObject(metadata)) {
    let arr = [];
    let tableName = metadata.at.replace(/\./g, "_");
    if (metadata.valueAttributes.length == 1) {
      let field = tableName + "." + metadata.code;
      let type = metadata.valueAttributes[0].type.value;
      arr.push({
        field,
        type,
      });
    } else {
      _.each(metadata.valueAttributes, (it) => {
        arr.push({
          field: tableName + "." + it.code,
          type: it.type.value,
        });
      });
    }
    return arr;
  } else if (dsf.isString(metadata)) {
    let field = metadata;
    let index = field.indexOf(".");
    if (index >= 0) {
      return field.substr(index + 1);
    }
    return field;
  }
}

// _.each(platformMetadata,(v,k)=>{
//   add(k,v);
// });
export default {
  add,
  get,
  all,
  use,
  mmtype,
  dataType,
  getDataType,
  validate,
  getField,
};
