import enums from '../utils/enums'

let dbKeyWords = {
  'TRIGGER': 1,
  'RANK': 1,
  'WHERE': 1,
  'REVOKE': 1,
  'THEN': 1,
  'CONNECT': 1,
  'GRANT': 1,
  'HAVING': 1,
  'NULL': 1,
  'ALL': 1,
  'INDEX': 1,
  'SMALLINT': 1,
  'RENAME': 1,
  'EXCLUSIVE': 1,
  'BETWEEN': 1,
  'SHARE': 1,
  'MODE': 1,
  'UNION': 1,
  'SET': 1,
  'START': 1,
  'VALUES': 1,
  'VIEW': 1,
  'WITH': 1,
  'CHAR': 1,
  'FROM': 1,
  'SELECT': 1,
  'BY': 1,
  'OR': 1,
  'ELSE': 1,
  'CHECK': 1,
  'VARCHAR2': 1,
  'CREATE': 1,
  'AS': 1,
  'TABLE': 1,
  'LONG': 1,
  'ASC': 1,
  'DESC': 1,
  'CLUSTER': 1,
  'FLOAT': 1,
  'NOT': 1,
  'DELETE': 1,
  'ALTER': 1,
  'IDENTIFIED': 1,
  'INTEGER': 1,
  'SIZE': 1,
  'NOWAIT': 1,
  'ANY': 1,
  'FOR': 1,
  'INTERSECT': 1,
  'SYNONYM': 1,
  'PCTFREE': 1,
  'UPDATE': 1,
  'DEFAULT': 1,
  'UNIQUE': 1,
  'PRIOR': 1,
  'RESOURCE': 1,
  'DATE': 1,
  'RAW': 1,
  'OPTION': 1,
  'OF': 1,
  'DECIMAL': 1,
  'PUBLIC': 1,
  'TO': 1,
  'INSERT': 1,
  'DROP': 1,
  'COMPRESS': 1,
  'LIKE': 1,
  'NOCOMPRESS': 1,
  'IS': 1,
  'ON': 1,
  'EXISTS': 1,
  'DISTINCT': 1,
  'LOCK': 1,
  'AND': 1,
  'GROUP': 1,
  'VARCHAR': 1,
  'IN': 1,
  'INTO': 1,
  'NUMBER': 1,
  'ORDER': 1,
  'MINUS': 1
}

let postForm = ['form', 'question', 'exam']
function getParentControl(el) {
  let target = $(el);
  let slotEl = $(target).closest("[slot-name]");
  let container = null;

  let mark = false;
  //判断插槽元素是否本身就是一个组件且不是一个被镶嵌在其他组件内部
  if (slotEl.hasClass("ds-control")) {
    let vueComponent = slotEl.get(0).__vue__;
    container = vueComponent;
    if (container.$$getComponent) {
      container = container.$$getComponent();
    }

  } else {
    mark = true;
  }
  if (mark) {
    container = slotEl.parents(".ds-control");
    if (container.get(0)) {
      let vueComponent = container.get(0).__vue__;
      container = vueComponent;
    }
  }
  if (container) {
    let layoutContainer = container;
    let layoutParent = layoutContainer.$layoutTree;
    // let ctrlType = layoutContainer.ctrlType;
    while (!layoutParent) {
      layoutContainer = layoutContainer.$parent;
      layoutParent = layoutContainer.$layoutTree;
    }
    if (layoutParent) {
      return layoutParent;
    }
  }
  // //插糟是个普通的html元素
  // else {
  //   mark = true;
  // }
  // if (mark) {
  //   container = slotEl.parents(".ds-control");
  //   if(container.get(0)){
  //     let vueComponent = container.get(0).__vue__;
  //     if(vueComponent){
  //       let layoutParent=vueComponent.$layoutParent;
  //       if(layoutParent){
  //         container=layoutParent.$$getComponent()
  //       }
  //     }
  //   }
  // } else {
  //   container = slotEl;
  // }
  // return container;
}

function getDsfComponent(comp, maxComponent) {
  let vueComponent = comp;
  if (vueComponent) {
    //如果组件不是按我们系统规范创建的组件则则继续向上查找
    while (!vueComponent.$props.ctrlName) {
      vueComponent = vueComponent.$parent;
      if (vueComponent.$el == maxComponent.$el) {
        vueComponent = null;
        break;
      }
    }
  }
  return vueComponent;
}


function createComponent(name, attrs, designer) {
  let comp = dsf.createComponent(name, attrs);
  if (!comp) {
    dsf.layer.message("该组件在当前客户端尚未被支持", false)
    return null;
  }
  if (!comp.designId) {
    comp.designId = dsf.uuid();
  }
  return comp;
}

function createMobileComponent(name, attrs, designer) {
  if (dsf.type(name) == 'string') {
    let arr = name.split(".");
    // let key = "mobile-";
    if (arr[1] != 'mobile') {
      arr.splice(1, 0, "mobile");
      name = arr.join(".")
    }
  }

  let comp = dsf.createComponent(name, attrs, designer);
  if (!comp) {
    dsf.layer.message("该组件在当前客户端尚未被支持", false)
    return null;
  }
  if (!comp.designId) {
    comp.designId = dsf.uuid();
  }
  return comp;
}

//获取触发事件的组件
function getComponentByTarget(target) {
  target = $(target);
  target = target.closest(".designer-ctrl-proxy");
  let component = target.get(0).__vue__;
  if (component) {
    component = component.$$getComponent ? component.$$getComponent() : component;
    return component;
  }
  return null;
}

function getRealComponent(target) {
  target = target.jquery ? target.get(0) : target;
  if (target.__vue__) {
    let com = target.__vue__;
    if (com.$$getComponent) {
      com = com.$$getComponent();
    }
    return com;
  }
  return null;
}

//是否允许拖放
function isAllowDrop(target, maxComponent) {
  let curr = null,
    count = 0,
    allow = true;
  if (!target) {
    return false;
  }
  if (target.jquery) {
    curr = target.length > 0 ? target.get(0) : null;
  } else {
    curr = target;
  }
  if (!curr) {
    return false;
  }
  do {
    if (curr == maxComponent.$el) {
      break;
    }
    if (curr.__vue__) {
      let vueComponent = getDsfComponent(curr.__vue__, maxComponent);
      if (vueComponent && !vueComponent.$props.isDesign) {
        allow = false;
        break;
      } else {
        break;
      }
    } else {
      curr = curr.parentNode;
    }
    count++;
  } while (count < 100);
  return allow;
}

//将组件转化为json
function controlToJson(ctrl) {
  return JSON.stringify(ctrl);
}

//向下传播设计器事件
function broadcast(sourceComponent, eventName, evt, params, excludeComponent) {
  sourceComponent.$children.forEach(child => {
    if (child.$options.design && child.$options.design[eventName]) {
      if (excludeComponent != child) {
        child.$options.design[eventName].call(child, evt, params);
      }
    }
    broadcast(child, eventName, evt, params, excludeComponent);
  });
}

//保存布局是被排除的属性
let layoutExclude = ["layoutRoot", "layoutParent", "isDesign", '$vm', 'local', 'staticValue'];

function getLayoutInfo(page, isPreView) {
  let arrs = [],
    root = null;
  recursionLayoutTree(page, null, null, (component, parent, slotName) => {
    let parentProps = arrs[arrs.length - 1];
    let designOptions = component.$options ? component.$options.design : null;
    if (designOptions) {
      let layoutNode = designOptions.layout || {};
      layoutNode.before && layoutNode.before.call(component);
    }
    let props = dsf.mix(true, {}, component.$props);
    if (component.$attrs["slot-name"]) {
      props["slot-name"] = component.$attrs["slot-name"];
    }
    let excludeProps = [...layoutExclude];
    if (component.$options && component.$options.design) {
      let layout = component.$options.design.layout;
      if (layout) {

        let exclude = layout.exclude || [];
        excludeProps = excludeProps.concat(exclude);
      }
    }
    if (component.isFormControl) {
      if (component.$parent.$parent && component.$parent.$parent.span) {
        //查找组件父亲，如果在dsfcol组件内则记录下组件占多少栅格，改属性可用于自动布局
        props.colSpan = component.$parent.$parent.span || 24;
      } else {
        props.colSpan = 24;
      }
    }
    _.each(excludeProps, exc => {
      if (isPreView) {
        if (!exc.startsWith("#")) {
          delete props[exc];
        }
      } else {
        if (exc.startsWith("#")) {
          delete props[exc.substr(1)];
        }
        else {
          delete props[exc];
        }
      }
    });

    arrs.push(props);
    if (!parentProps) {
      root = props;
    }
    let slots = props.slots;
    _.each(slots, slot => {
      slot.controls = [];
    });
    if (parentProps) {
      let slot = _.find(parentProps.slots, s => s.name == slotName);
      if (slot) {
        slot.controls.push(props);
      }
    }
  }, () => {
    arrs.pop();
  });
  return root;
}

//获取页面配置的所有静态数据
function getStaticValue(page) {
  let staticValue = {};
  recursionLayoutTree(page, null, null, (component, parent, slotName) => {
    if (component.staticValue && component.caption) {
      staticValue[component.caption] = component.staticValue
    }
  });
  return staticValue;
}

function getDoc(designer) {
  return designer.pageDocument;
}

//递归组件
function recursionLayoutTree(component, parent, slotName, callback, callbackAfter) {
  if (component) {
    while (!component.$$getComponent) {
      component = component.$parent;
    }
    if (!component) {
      return;
    }
    component = component.$$getComponent();
    let isContinue;
    if (callback) {
      isContinue = callback(component, parent, slotName);
    }
    if (isContinue !== false) {
      let designMethods = component.$options.design;
      let layoutNode = designMethods.layout;
      let isdeep = dsf.isFunction(layoutNode.isDeep) ? layoutNode.isDeep.call(component) : layoutNode.isDeep;
      if (isdeep !== false) {
        _.each(component.slots || [], slot => {
          _.each(slot.controls || [], control => {
            let desid = control.designId;
            let target = $("[des-id='" + desid + "']");
            if (target.length) {
              let child = target.get(0).__vue__;
              recursionLayoutTree(child, component, slot.name, callback, callbackAfter);
            }
          });
        });
      }
    }
    callbackAfter && callbackAfter(component, parent, slotName);
  }
}

//保存布局时验证
function saveLayoutValidate(page) {
  let errors = [];
  recursionLayoutTree(page, null, null, (el, parent, slotName) => {
    if (el.isFormControl) {
      let layout = el.$options.design ? el.$options.design.layout : {};
      let nameValidate = true;
      if (dsf.hasOwn(layout, "nameValidate") && layout.nameValidate === false) {
        nameValidate = false;
      }
      if (isPrototypePage(page)) {
        nameValidate = false;
      }
      if (nameValidate) {
        //验证表单组件的标识不能等于自己组件的名字开头，避免提交时数据库生成无意义的字段和表
        _.each(validateCaption(el), (error) => {
          errors.push(error);
        });
      }
      // 编号控件必须设置编号规则
      if (el.ctrlName == "DsfNumeration" && !el.rule) {
        errors.push("请为" + el.caption + "设置编号规则");
      }
    }
  });
  if (errors.length) {
    dsf.layer.pc.message(errors[0], false);
    return false;
  }
}

function validateCaption(el) {
  let errors = [];
  let reg_upper = /(^[a-z_])+([a-z0-9_])*$/g;
  let ctrlTypeArr = el.$props.ctrlType.split(".");
  let name = ctrlTypeArr[ctrlTypeArr.length - 1];
  if (el.$props.caption.startsWith(name)) {
    errors.push(el.$props.caption + "组件标识是无意义的，请修改");
  }
  if (!reg_upper.test(el.$props.caption)) {
    // if (el.$props.metadata && !el.$props.metadata.isFromMetaData) {
    if (el.$props.metadata && el.$props.metadata.$isValidate !== false) {
      errors.push(el.$props.caption + "组件标识必须是小写字母和数字");
    }
  }
  if (el.$props.metadata) {
    let fields = [];
    if (el.$props.metadata.valueAttributes.length > 1) {
      _.each(el.$props.metadata.valueAttributes, (it) => {
        let field = `${el.$props.caption}_${it.code}`;
        fields.push(field);
      });
    }
    else {
      fields.push(el.$props.caption);
    }
    _.each(fields, (it) => {
      if (dbKeyWords[it.toUpperCase()] === 1) {
        errors.push(it + "为特殊关键字不可使用");
      }
    })
  }

  return errors
}

//获取extjs路径
function getExtPath(b, m, pn, isMobile, isTpl) {
  let tplOrViews = isTpl ? 'tpl' : 'views';
  let folder = [b, m].join(".");
  let clientDirs = isMobile ? dsf.url.getWebPath("~/mobile/" + tplOrViews) : dsf.url.getWebPath("~/pc/" + tplOrViews);
  let extPath = `${clientDirs}/${folder}/${pn}.ext.js?t=${new Date().getTime()}`;
  return extPath;
}

function getPageDocumet(b, m, pn, isMobile) {
  let folder = [b, m].join(".");
  let clientDirs = isMobile ? dsf.url.getWebPath("~/mobile/views") : dsf.url.getWebPath("~/pc/views");
  let docPath = `${clientDirs}/${folder}/${pn}.doc.html?t=${new Date().getTime()}`;
  return docPath;
}

//获取layout文件路径
function getLayoutPath(b, m, pn, isMobile, isTpl) {
  let tplOrViews = isTpl ? 'temp' : 'views';
  let folder = [b, m].join(".");
  let clientDirs = isMobile ? dsf.url.getWebPath("~/mobile/" + tplOrViews) : dsf.url.getWebPath("~/pc/" + tplOrViews);
  let htmlPath = `${clientDirs}/${folder}/${pn}.layout.html?t=${new Date().getTime()}`;
  return htmlPath;
}

//创建页面元数据
function createViewMetaData(page, mobile, callback) {
  let md = {
    accessRole: page.dataAccessRole || [],
    isForm: (page.pageType == "form" || page.pageType == 'question') ? true : false,
    pageState: page.pageType,
    mode: page.mode || enums.viewMode.DEVELOP,
    xssConfig: page.xssConfig,
    init: { privilege: page.pagePrivilege || [] },
    query: {},
    export: {},
    browseScope: {},
    metadata: {},
    isMobile: mobile,
    attach: {}
  };
  let result = createComponentMetaData(page, page, callback);
  let orderNum = 0;
  _.each(result, item => {
    if (item.schema) {
      let scheam = item.schema;
      let list = dsf.type(scheam) != "array" ? [scheam] : scheam;
      _.each(list, el => {
        if (el.metadata) {
          md.metadata[el.key] = dsf.mix({}, el.metadata);
          md.metadata[el.key].order = ++orderNum;
          md.metadata[el.key].ref = el.self;
        }
        if (el.query) {
          md.query = el.query;
        }
        if (el.browseScope) {
          md.browseScope = el.browseScope;
        }
        if (el.export) {
          md.export = dsf.mix(md.export, el.export)
        }
        if (el.attach) {
          let attach = dsf.mix(true, {}, el.attach);
          dsf.mix(md.attach, attach);
        }
      });
    }
  });

  //为设置过拼音的字段创建拼音元数据
  _.forOwn(md.metadata, (it, key) => {
    let vattrs = it?.valueAttributes || [];
    let pyfiels = _.filter(vattrs || [], (it) => {
      return it.pinyin;
    })
    _.each(pyfiels, (va) => {
      let qpkey = va.pinyinFieldName;
      let jpkey = va.pyFieldName;
      let qpmeta = null;
      let jpmeta = null;
      if (!md.metadata[qpkey]) {
        qpmeta = dsf.mix(true, {}, it);
        qpmeta.content = "{}";
        qpmeta.name = (it.name || it.code) + "_全拼";
        qpmeta.cname = (it.cname || it.code) + "_全拼"
        qpmeta.ckey = "text-meta-data";
        qpmeta.code = qpkey.split('.')[1];
        qpmeta.id = it.at + "." + qpmeta.code;
        qpmeta.required = false;
        qpmeta.validate = null;
        qpmeta.pinyinField = true;
        qpmeta.order = ++orderNum;
        qpmeta.targetField = it.valueAttributes.length > 1 ? key + "_" + va.code : key;
        qpmeta.currentControl = {
          text: "文本框",
          value: mobile ? "dsf.mobile.textbox" : "dsf.textbox"
        }
        qpmeta.controls = [
          qpmeta.currentControl
        ];
        qpmeta.valueAttributes = [
          {
            code: "text",
            defaultValue: "@[pinyin('" + qpmeta.targetField + "')]",
            emptyValidate: false,
            encrypt: false,
            length: va.length,
            name: "文本",
            pinyin: false,
            type: { text: '文本', value: 'string' },
            unit: null
          }
        ]
      }
      if (!md.metadata[jpkey]) {
        jpmeta = dsf.mix(true, {}, it);
        jpmeta.content = "{}";
        jpmeta.name = (it.name || it.code) + "_简拼";
        jpmeta.cname = (it.cname || it.code) + "_简拼"
        jpmeta.ckey = "text-meta-data";
        jpmeta.code = jpkey.split('.')[1];
        jpmeta.id = it.at + "." + jpmeta.code;
        jpmeta.required = false;
        jpmeta.validate = null;
        jpmeta.pinyinField = true;
        jpmeta.order = ++orderNum;
        jpmeta.targetField = it.valueAttributes.length > 1 ? key + "_" + va.code : key;
        jpmeta.currentControl = {
          text: "文本框",
          value: mobile ? "dsf.mobile.textbox" : "dsf.textbox"
        }
        jpmeta.controls = [
          jpmeta.currentControl
        ];
        jpmeta.valueAttributes = [
          {
            code: "text",
            defaultValue: "@[py('" + qpmeta.targetField + "')]",
            emptyValidate: false,
            encrypt: false,
            length: va.length,
            name: "文本",
            pinyin: false,
            type: { text: '文本', value: 'string' },
            unit: null
          }
        ]
      }
      md.metadata[qpkey] = qpmeta;
      md.metadata[jpkey] = jpmeta;
    })
  });

  return md;
}


//创建组件元数据
function createComponentMetaData(component, root, callback, noCheck) {
  let arr = [];
  recursionLayoutTree(component, null, null, el => {
    let designOptions = el.$options.design || {};
    let metadataNode = designOptions.metadata || {};
    let result = metadataNode.create && metadataNode.create.call(el, root);
    result && arr.push({
      component: el,
      schema: result
    });
    let isDeep = dsf.isDef(metadataNode.isDeep) ? metadataNode.isDeep : true;
    if (!isDeep) {
      return false;
    }
  });
  let ns_arr = [root.context.B, root.context.M];
  let key = ns_arr.join("_").replace(/\./g, "_");
  for (let i = 0; i < arr.length; i++) {
    let com = arr[i].component;
    let result = arr[i].schema;
    //如果当前组件是子表或者列表
    if (com.childMetaDataCodes) {
      //清空子数据的keys数组
      dsf.array.removeAll(com.childMetaDataCodes);
      //子表
      if (com.isFormControl) {
        let subMetaDataKey = "";
        //先查看子表内部是否存在不同的元数据
        let existFromMeta = [],
          sourceKey = [];
        _.each(result, (el) => {
          if (el.metadata) {
            if (el.metadata.isFromMetaData || el.metadata.isuser) {
              existFromMeta.push(el.metadata);
              let key = getMetaDataPrefixById(el.metadata.id)
              dsf.array.ensure(sourceKey, key);
            }
          }
        });
        if (sourceKey.length > 1) {
          throw '子表中不能存在2组不同源的元数据'
        }
        //循环子表生成的元数据结构（类型为数组，包含子表中的子组件。且子表本身元数据对象排在数组第一个）
        _.each(result, (el, i) => {
          let ctrl = el.self;
          let currMetaDataKey = ""
          if (i == 0) {
            let code = "",
              id = "",
              at = "",
              name = "",
              fullCode = ""
            //如果子表中存在不同源的元数据，强制以已有元数据为主（强制同源）
            if (existFromMeta.length > 0) {
              let code_arr = existFromMeta[0].at.split(".");
              code = code_arr[code_arr.length - 1];
              id = code_arr.join(".");
              at = code_arr.join(".");
              name = ctrl.label;
              fullCode = code_arr.join("_");
              currMetaDataKey = subMetaDataKey = code_arr.join("_");
            } else {
              code = ctrl.caption;
              id = ns_arr.join(".") + "." + ctrl.caption;
              at = ns_arr.join(".");
              name = ctrl.label;//ctrl.metadata.name || ctrl.label;
              fullCode = key + "_" + ctrl.caption;
              currMetaDataKey = subMetaDataKey = key + "_" + ctrl.caption;
            }
            checkMetadataControlValue(el.metadata);
            el.metadata.code = code
            el.metadata.id = id;
            el.metadata.at = at;
            el.metadata.name = name
            el.metadata.cname = ctrl.caption;
            el.metadata.required = ctrl.required;
            el.key = fullCode
            createPinyinField(el.metadata, el.key);
            ctrl.metadata_fullcode = fullCode;
          } else {
            if (el.metadata) {
              checkMetadataControlValue(el.metadata);
              el.metadata.inSubTable = subMetaDataKey;
              if (ctrl) {
                el.metadata.code = ctrl.caption;
                el.metadata.cname = ctrl.caption;
                el.metadata.id = subMetaDataKey.replace(/\_/g, ".") + "." + ctrl.caption;
                el.metadata.at = subMetaDataKey.replace(/\_/g, ".");
                el.metadata.name = ctrl.metadata.name || ctrl.label;
                el.metadata.required = ctrl.required;
                currMetaDataKey = subMetaDataKey + "." + ctrl.caption;
                el.key = currMetaDataKey
                createPinyinField(el.metadata, el.key);
                ctrl.metadata_fullcode = currMetaDataKey
              }
              else {
                currMetaDataKey = subMetaDataKey + "." + el.metadata.code;
              }
              dsf.array.ensure(com.childMetaDataCodes, currMetaDataKey);
            }
          }
          el.metadata.level = 1;
          if (ctrl) {
            el.metadata.content = ctrl.metadata.content || "{}"
          }
          el.key = currMetaDataKey;
        });
      }
      //列表
      else {
        _.each(result, (el, i) => {
          let ctrl = el.self;
          if (el.metadata) {
            if (ctrl.isFormControl) {
              checkMetadataControlValue(el.metadata);
              if (!el.metadata.isFromMetaData && !el.metadata.isuser) {
                el.metadata.cname = ctrl.caption;
                el.metadata.code = ctrl.caption;
                el.metadata.id = ns_arr.join(".") + "." + ctrl.caption;
                el.metadata.at = ns_arr.join(".");
                el.metadata.name = ctrl.label;
                el.metadata.level = 0;
                el.metadata.required = ctrl.required;
                el.key = key + "." + ctrl.caption;
                el.metadata.inSubTableSlot = "";
                createPinyinField(el.metadata, el.key);
                ctrl.metadata_fullcode = el.key;
              }
              else {
                let code_arr = el.metadata.id.split(".");
                let prefix = code_arr.slice(0, code_arr.length - 1);
                prefix = _.map(prefix, (it) => {
                  return it.replace(/_/g, '.');
                });
                prefix = prefix.join(".").split(".");
                el.metadata.id = prefix.join(".") + "." + ctrl.caption;
                el.metadata.at = prefix.join(".");
                el.metadata.ctrlCaption = ctrl.caption;
                el.metadata.level = 0;
                el.metadata.name = ctrl.label || el.metadata.name
                el.metadata.inSubTable = "";
                el.metadata.required = ctrl.required;
                let fullCode = prefix.join("_") + "." + ctrl.caption;
                el.key = fullCode;
                createPinyinField(el.metadata, el.key);
                ctrl.metadata_fullcode = el.key;
              }
              dsf.array.ensure(com.childMetaDataCodes, el.key);
            }
          }
        })
      }
    }
    else {
      //表单组件、树组件元数据处理
      result = dsf.type(result) == 'array' ? result : [result];
      _.each(result, (el) => {
        let ctrl = el.self;
        if (el.metadata) {
          delete el.metadata.inColumnSlot;
          //表单组件元数据处理
          if (ctrl.isFormControl) {
            checkMetadataControlValue(el.metadata);
            /*isFromMetaData表示是由左侧元数据组拖动拖来的
            isuser表示是在表单保存时产生不同源时用户选择的
            以上两者元数据的key不会重新计算*/
            if (!el.metadata.isFromMetaData && !el.metadata.isuser) {
              el.metadata.cname = ctrl.caption;
              el.metadata.code = ctrl.caption;
              el.metadata.id = ns_arr.join(".") + "." + ctrl.caption;
              el.metadata.at = ns_arr.join(".");
              el.metadata.name = ctrl.label || ctrl.metadata.name;
              el.metadata.level = 0;
              el.metadata.inSubTable = "";
              el.metadata.required = ctrl.required;
              ctrl.metadata_fullcode = key + "." + ctrl.caption;
              el.key = key + "." + ctrl.caption;
              createPinyinField(el.metadata, el.key)
            } else {
              let code_arr = el.metadata.id.split(".");
              let prefix = code_arr.slice(0, code_arr.length - 1);
              prefix = _.map(prefix, (it) => {
                return it.replace(/_/g, '.');
              });
              prefix = prefix.join(".").split(".");
              el.metadata.id = prefix.join(".") + "." + ctrl.caption;
              el.metadata.at = prefix.join(".");
              el.metadata.ctrlCaption = ctrl.caption;
              el.metadata.level = 0;
              el.metadata.name = ctrl.label || ctrl.metadata.name;
              el.metadata.inSubTable = "";
              el.metadata.required = ctrl.required;
              let fullCode = prefix.join("_") + "." + ctrl.caption;
              el.key = fullCode;
              createPinyinField(el.metadata, el.key);
              ctrl.metadata_fullcode = fullCode
            }
            el.metadata.content = ctrl.metadata.content || "{}"
          }
          //树组件元数据处理
          else if (el.metadata.ckey == "nav-tree-meta-data") {
            let caption = ctrl.caption;
            // el.ref = ctrl;
            el.metadata.cname = caption;
            el.metadata.code = caption;
            el.metadata.name = ctrl.metadata.name || ctrl.label;
            el.metadata.level = 0;
            el.metadata.content = ctrl.metadata.content || "{}"
            if (ctrl.async && ctrl.asyncDataSource * 1 == 2 && ctrl.asyncChooseMetaData.code && ctrl.asyncChooseMetaData.code.id) {
              el.metadata.id = ctrl.asyncChooseMetaData.code.at + '.' + caption;
              el.metadata.at = ctrl.asyncChooseMetaData.code.at;
              key = ctrl.asyncChooseMetaData.code.at.split('.').join('_');
            } else {
              let _k = key;
              el.metadata.id = _k.replace(/\_/g, '.') + "." + caption;
              el.metadata.at = _k.replace(/\_/g, '.');
            }
            key += "." + caption;
            ctrl.metadata_fullcode = key;
            el.key = key;
            createPinyinField(el.metadata, el.key);
          } else {
            checkMetadataControlValue(el.metadata);
            //其他可能带有元数据的组件
            el.metadata.cname = ctrl.caption;
            el.metadata.code = ctrl.caption;
            el.metadata.id = ns_arr.join(".") + "." + ctrl.caption;
            el.metadata.at = ns_arr.join(".");
            el.metadata.name = ctrl.metadata.name || ctrl.label;
            el.metadata.level = 0;
            el.metadata.content = ctrl.metadata.content || "{}"
            el.metadata.required = ctrl.required;
            el.key = key + "." + ctrl.caption;
            createPinyinField(el.metadata, el.key);
          }
        }
      });
    }

  }
  return arr;
}

function createPinyinField(metadata, fullKey) {
  let vattrs = metadata?.valueAttributes || [];
  let pyfiels = _.filter(vattrs || [], (it) => {
    return it.pinyin;
  })
  _.each(vattrs, (it) => {
    delete it.pinyinFieldName;
    delete it.pyFieldName;
  });
  if (pyfiels.length > 0) {
    _.each(pyfiels, (it) => {
      let at = metadata.at.replace(/\./g, "_")
      let qpkey = at + "." + "pinyin_" + metadata.code + (vattrs.length > 1 ? "_" + it.code : "");
      let jpkey = at + "." + "py_" + metadata.code + (vattrs.length > 1 ? "_" + it.code : "");
      it.pinyinFieldName = qpkey;
      it.pyFieldName = jpkey;
    });
  }
}

// let innerControls = ['DsfCol', 'DsfTableRow', 'DsfTableCell'];

//转化vue模板
function convertTemplateAndControlData(layout, hasComment, isPreView, options) {
  layout = dsf.mix(true, {}, layout);
  let defaultOptions = {
    before: null,
    after: null
  };
  dsf.mix(defaultOptions, options);
  let parentNodes = [];
  /**
   * node：当前布局组建的props
   * parent：夫组件的props
   * slot：当前组件所在的插槽信息
   * isRepeat：是否在一个表格循环内
   * prefix：循环表格组件的caption前缀（暂时无用）
   * textProxy：遇到表单组件时是否显示一个<span>代理组件的输出（数据列表中使用，避免列表加载数据时每行都要实例化组件）
   */
  function createElement(node, parent, slot, slotScope, scopeDataKey, isRepeat, prefix, textProxy) {
    let arr = [],
      props = {},
      controlHash = "";
    defaultOptions.before && defaultOptions.before(node, parentNodes, slot, slotScope, scopeDataKey);
    if (node) {
      parentNodes.push(node);
    }
    let isFormControl = node.isFormControl;
    let metadataFullCode = node.metadata_fullcode;
    let modelExpress = "$viewData";
    let s = []
    if (slotScope) {
      s.push(slotScope);
    }
    if (scopeDataKey) {
      s.push(scopeDataKey)
    }
    if (s.length) {
      modelExpress = s.join(".")
    }
    // let modelExpress = (isRepeat) ? (slotScope ? slotScope + (scopeDataKey ? "." + scopeDataKey : "") : "") : "$viewData"
    let attachAttrs = {};
    if (isFormControl && metadataFullCode) {

      attachAttrs['v-model'] = modelExpress + "['" + metadataFullCode + "']";
      //如果是枚举型组件增加items属性的绑定
      if ((node.metadata.ckey == "items-meta-data" || node.metadata.dataSourceWatch) && !isPreView) {
        attachAttrs[":items"] = "$enum['" + metadataFullCode + "']";
      }

    }
    if (node.treeProxy) {
      _.each(node.slots, (s, i) => {
        if (s.controls.length > 0) {
          _.each(s.controls, (c, ii) => {
            let child = createElement(c, parent, { slotName: s.name, slotIndex: i, $index: ii }, slotScope, scopeDataKey, isRepeat, prefix, textProxy);
            arr.push(...child.tpl);
            dsf.mix(props, child.props);
          });
        }
      });
    }
    //特殊判断HTML组件，将html组建内容直接显示到模板 
    else if ((node.ctrlName == 'DsfHtml' || node.ctrlName == 'DsfMobileHtml') && !isPreView) {
      hasComment && arr.push("<!--html组件生成内容-->")
      if (node.html) {
        arr.push(dsf.unescapeHTML(node.html));
      }
    } else {
      let ref = "";
      if (!isRepeat) {
        attachAttrs['v-bind.sync'] = "$controls['" + node.designId + "']";
        if (node.caption) {
          ref = "'" + node.caption + "'"
        }
      } else {
        controlHash = `${modelExpress}.${'$hash'}`;
        attachAttrs['v-bind.sync'] = `$controls['${node.designId}-'+${controlHash}]`;
      }
      attachAttrs[':local'] = modelExpress;
      attachAttrs[':design-id'] = "'" + node.designId + "'";
      attachAttrs[":key"] = "'" + node.designId + "'";

      if (ref) {
        attachAttrs[':ref'] = ref;
        attachAttrs[':ctrl-id'] = ref;
      }
      if (node.isView) {
        // attachAttrs["v-if"] = "$isLoaded";
        if (node.ctrlType.startsWith("dsf.mobile.")) {
          attachAttrs["v-if"] = "$isLoaded";
          attachAttrs['v-mobile-loading'] = "loading";
        }
        else {
          attachAttrs["v-if"] = "$isLoaded";
          attachAttrs['v-loading.fullscreen'] = "loading";
        }
        // if(!isDesign&&!isPreView){
        //   attachAttrs[''] = "$isShow"
        // }
        // if (!isPreView) {
        //   attachAttrs['v-show'] = "$isShow"
        // }
      }
      let attrMap = getVuePropsAndEventMap(attachAttrs);
      let customAttrs = !isPreView ? getVuePropsAndEventMap(node.development['attrs'] || {}) : {};
      let mergeAttrs = { ...attrMap, ...customAttrs };

      let _textProxy = node.textProxy;
      if (_textProxy) {
        hasComment && arr.push("<!--" + (node.label || node.caption) + "-->")
        // arr.push(`<template>`);
        // let exp = `${modelExpress+ "['" + metadataFullCode + "']"}`;
        let ctrl = "$controls['" + node.designId + "']";
        let attrsArr = _.map(mergeAttrs, (attr) => {
          let name = attr.prefix + attr.propName + attr.modifier;
          if (attr.prefix == "v-bind" && attr.propName == "") {
            return `${name}="${ctrl}"`;
          } else if (attr.propName == "ref") {
            return ""
          } else {
            return `${name}=${attr.value}`;
          }
        });
        arr.push(`<DsfTextProxy ${attrsArr.join(" ")}></DsfTextProxy>`);
        props[node.designId] = node;
      } else {
        let attrsArr = _.map(mergeAttrs, (attr) => {
          let name = attr.prefix + attr.propName + attr.modifier;
          return name + "=" + attr.value;
        });
        hasComment && arr.push("<!--" + (node.label || node.caption) + "-->")
        let isUseRepeatProxy = false;
        if (isRepeat) {
          let mark = mergeAttrs?.['v-bind']?.value || "";
          if (mark.startsWith("\"$controls[")) {
            isUseRepeatProxy = true;
            arr.push(`<DsfRepeatProxy :proxy-props=${mergeAttrs?.['v-bind'].value} :design-id="'${node.designId}'" :hash="${modelExpress}.${'$hash'}">`)
          }
          arr.push("<" + node.ctrlName + " " + attrsArr.join(" ") + ">");
        }
        else {
          arr.push("<" + node.ctrlName + " " + attrsArr.join(" ") + ">");
        }
        // arr.push("<" + node.ctrlName + " " + attrsArr.join(" ") + ">");
        if (node.development['slots'] && !isPreView) {
          arr.push(node.development['slots']);
        }
        _.each(node.slots, (s, i) => {
          if (s.controls.length > 0) {
            let templateMark = true;
            if (s.name == "default" && !s.scope) {
              templateMark = false;
            }
            if (templateMark) {
              arr.push("<template v-slot:" + s.name + (s.scope ? "=" + JSON.stringify(s.scope) : "") + ">")
            }
            let repeat = s.repeat === true ? true : false;
            if (isRepeat) {
              repeat = true;
            }
            let prefix = s.repeat === true ? node.caption : "";
            let dataKey = s.dataKey || scopeDataKey;
            let scopeName = s.scope || slotScope;
            let textProxy = s.textProxy;
            _.each(s.controls, (c, ii) => {
              let child = createElement(c, node, { slotName: s.name, slotIndex: i, $index: ii }, scopeName, dataKey, repeat, prefix, textProxy);
              arr.push(...child.tpl);
              dsf.mix(props, child.props);
            });
            if (templateMark) {
              arr.push("</template>");
            }
          }
        });

        arr.push("</" + node.ctrlName + ">");
        if (isUseRepeatProxy) {
          arr.push("</DsfRepeatProxy>")
        }
        props[node.designId] = node;
      }
    }

    //如果是移动端页面外层增肌一个div,确保过场动画
    // if (node.isView) {
    //   arr.splice(0, 0, '<div v-if="!isLoaded"></div>');
    // }
    defaultOptions.after && defaultOptions.after(node, parentNodes, slot, slotScope, scopeDataKey);
    parentNodes.pop();
    return {
      props: props,
      tpl: arr
    };
  }
  let result = createElement(layout, null)
  return {
    props: result.props,
    tpl: result.tpl.join("\n")
  }
}

function getVuePropsAndEventMap(props) {
  let attrMap = {};
  _.each(props, (s, i) => {
    // let attr = attachAttrs[i];
    var propExpressArray = i.split(".");
    let rexpVueProp = /(:|v-bind:?|v-on:?|@)?([^\.:]*)(\..+)?/g;
    let propName = "",
      prefix = "",
      modifier = "";
    let group = rexpVueProp.exec(i)
    if (group && group.length > 1) {
      propName = group[2];
      prefix = group[1];
      modifier = group[3];
    }
    if (dsf.type(s) != "string" && (propExpressArray[0].startsWith(":") || propExpressArray[0].startsWith("v-bind:"))) {
      s = "" + s;
    }
    let attr = {
      propName: (propName || ""),
      prefix: (prefix || ""),
      modifier: (modifier || ""),
      value: JSON.stringify(s)
    }
    attrMap[propName || prefix] = attr;
  });
  return attrMap;
}

function getTableSlot(ctrl) {
  let columnEl = ctrl.$el.closest("[column-id]");
  if (columnEl) {
    return columnEl.getAttribute("column-id") || ""
  }
  return ''

}

//编译scss
function compileCss(scssStr, nameSpace) {
  return new Promise((resolve, reject) => {
    if ((scssStr || "").trim()) {
      var sass = new Sass();
      var scss = [
        ".ds-page" + (nameSpace ? '[module-name="' + nameSpace + '"]' : "") + "{",
        scssStr,
        "}",
      ];
      var files = dsf.config.designer.scss || [];
      const p = "~_platform/assets/styles/common/";
      sass.importer(function (request, done) {
        if (request.current.startsWith(p)) {
          done({
            path: "/sass/" + request.current.replace(p, "platform/"),
            // resolved:"/sass/" + request.current.replace(p, "platform/"),
          });
        } else {
          done();
        }
      });
      sass.preloadFiles(dsf.config.designer.scssDir + "scss", "", files, (dd) => {
        let scssFiles = _.map(files, (file) => `@import "${file}";`);
        scss = [...scssFiles, ...scss];
        sass.compile(scss.join("\n"), function (result) {
          if (result.status == 0) {
            let cssStr = dsf.beautify.css(result.text, {
              indent_size: "2",
              indent_char: " ",
            });
            resolve(cssStr);
          } else {
            let errorMsg = "编译错误:" + result.message;
            dsf.error(errorMsg);
            reject(errorMsg);
          }
        });
      });
    }
    else {
      resolve("");
    }
  });
}

function getMetaDataPrefixById(id) {
  let arr = id.split(".");
  arr = arr.slice(0, arr.length - 1);
  return arr.join("_");
}

//修复元数据中controls,currentControl中的值没有dsf.开头
function checkMetadataControlValue(metadata) {
  if (metadata.currentControl && metadata.currentControl.value) {
    if (!metadata.currentControl.value.startsWith('dsf.')) {
      metadata.currentControl.value = "dsf." + metadata.currentControl.value;
    }
  }
  _.each(metadata.controls, (ctrl) => {
    if (!ctrl.value.startsWith('dsf.')) {
      ctrl.value = "dsf." + ctrl.value;
    }
  })
}

//保存布局
function saveLayout(design, noCheck) {
  let page = design.$refs.viewProxy;
  try {
    let md = dsf.designer.createViewMetaData(page, design.mobile);
    if (!noCheck) {
      //检查元数据key是否是在不同源，将页面元数据key记录到页面中
      if (md.metadata && !dsf.isEmptyObject(md.metadata)) {
        let list = [];
        let metadataSource = [];
        let isExistNew = false;
        _.each(md.metadata, (m, i) => {
          let arr = i.split(".");
          let prefix = arr[0];
          let name = arr[1] ? arr[1] : "";
          //pinyinField表示该字段是个拼音字段
          if (m.ref && !m.pinyinField) {
            if (m.ref.isFormControl && !m.ref.isOneToMany) {
              //子表在创建元数据时就强制同源，所以此处只验证主表是否同源
              if (m.level == 0) {
                let obj = {
                  ctrl: m.ref,
                  source: prefix
                };
                if (m.$isNew) {
                  isExistNew = true;
                }
                dsf.array.ensure(metadataSource, prefix);
                list.push(obj);
              }
            }
          }
        });
        if (md.pageState == 'form') {
          if (metadataSource.length > 0) {
            let message = ""
            if (isExistNew) {
              if (metadataSource.length == 1) {
                message = `发现当前页面有新增的字段，默认属于<b style='color:blue'>${metadataSource[0]}</b>元数据下,确定提交吗?`;
              }
              // else if (metadataSource.length > 1) {
              //   message = `发现当前页面有多组元数据，分别是:<b style='color:blue'>${metadataSource.join("，")}</b>,确定提交吗?`;
              // }
              else if (metadataSource.length > 1) {
                message = `发现当前页面有多组元数据，分别是:<b style='color:blue'>${metadataSource.join("，")}</b>,确定提交吗?`;
              }
            }
            if (message) {
              dsf.layer.confirm({
                message: message + "<br/><div style='color:red;font-weight:bold'>注：提交后将创建数据表和字段，如提交了错误的元数据所产生的字段需人为处理！！！！</div>",
                confirmButtonText: "确定提交",
                cancelButtonText: "我要修改",
              })
                .then(() => {
                  saveLayout(design, true);
                })
                .catch(() => {
                  window.setTimeout(() => {
                    openMetaDataChangeDialog(list, metadataSource, () => {
                      saveLayout(design, true);
                    })
                  }, 500)

                })
              return;
            }
          }
          else {
            openMetaDataChangeDialog(list, metadataSource, () => {
              saveLayout(design, true);
            })
          }
        }
      }
    }
    design.$nextTick(() => {
      //编译公式
      compileFormulaCode(page);
      //提交页面数据
      postLayoutInfo(design, page, md);
    });
  } catch (ex) {
    let message = "出现了未知的错误";
    if (dsf.type(ex) == "string") {
      message = ex;
    }
    dsf.error(ex)
    dsf.layer.pc.message(message, false);
  }
}

function compileFormulaCode(page) {
  let allFormControls = {}
  dsf.recursionComponentProps(page, (it) => {
    if (it.isFormControl) {
      allFormControls[it.designId] = it;
    }
  })
  _.each(allFormControls, (it) => {
    //将公式表达式伪代码转换为真实可运行的代码
    convertFormulaCodeToRealCode(it, allFormControls);
  });
}

function convertFormulaCodeToRealCode(component, allFormControls) {
  if (component.isFormControl) {
    let f = component.formula.formula.trim();
    if (!f) {
      delete component.formula.realFormula;
      return;
    }
    let var_reg = /\{\{(.)+?\}\}/g;
    let desid_reg = /\^\^((.)+?)\^\^/g;
    let target = component.metadata.inSubTable;
    let targetInSubTable = component.metadata.inSubTable;
    console.time("-------创建语法树----");
    let astTree = dsf.ufunc.readFormulaExpress(f);
    console.timeEnd("-------创建语法树----");
    let code = dsf.ufunc.createFormulaCodeStr(astTree, (node, parentNode, isParams) => {
      if (node.type == 'block') {
        let exp = node.express;
        exp = exp.replace(var_reg, (s1, s2, s3, s4) => {
          let e = s1.replace(desid_reg, (s1, s2, s3, s4) => {
            let varb = s2.split(".");
            let designId = varb[0] || "";
            let attr = varb[1] || ""
            let metadata = (allFormControls[designId] && allFormControls[designId].metadata) || null;
            if (metadata.inSubTable) {
              return "TO_VALUE(__local__['" + allFormControls[designId].metadata_fullcode + "'],'" + attr + "')";
            }
            else {
              return "TO_VALUE($viewData['" + allFormControls[designId].metadata_fullcode + "'],'" + attr + "')";
            }
          })
          return e.replace(/\{\{|\}\}/g, '');
        });
        node.realCode = exp;
      }
      else if (node.type == 'function') {
        //解析函数中的参数，如果是聚合函数则且有依赖项中有子表字段则需要追加子表循环
        if (node.pol && node.args && node.args.length > 0) {
          _.each(node.args, (it) => {
            let relInSubTable = [];
            if (it.type == "block" || (it.type == 'function' && !it.pol)) {
              let argExp = it.express;
              argExp.replace(desid_reg, (s1, s2, s3, s4) => {
                let metadata = (allFormControls[s2] && allFormControls[s2].metadata) || null;
                if (metadata && metadata.inSubTable) {
                  relInSubTable.push(metadata.inSubTable)
                }
              });
            }
            if (relInSubTable.length > 0 && targetInSubTable != relInSubTable[0]) {
              let exp = "$loopSubTable('" + relInSubTable[0] + "',function(__local__){\r\n" +
                "return  " + it.realCode + ";\r\n" +
                "})\r\n";
              // return exp;
              it.realCode = exp;
            }
            else {
              it.realCode = it.realCode;
            }
          })
        }
      }
    });
    component.formula.realFormula = code;
  }
}

function openMetaDataChangeDialog(list, metadataSource, yes) {
  dsf.layer.openDialog({
    title: "元数据修正",
    content: "DesMetaDataSource",
    width: "1200px",
    height: "60vh",
    params: {
      source: list,
      dict: metadataSource
    },
    btns: [
      {
        text: "确定",
        handler: (res) => {
          let list = res.yes();
          if (list === false) {
            return false;
          }
          _.each(list, (el, i) => {
            if (el.isChange) {
              let ctrl = el.ctrl;
              ctrl.metadata.isuser = true;
              ctrl.metadata.id = el.mdkey.replace(/_/g, ".") + "." + ctrl.metadata.code;
              ctrl.metadata.at = el.mdkey.replace(/_/g, ".");
            }
          });
          yes && yes()

        }
      },
      { text: "取消" }
    ]
  });
  return;
}

//保存模板
function saveLayoutToTemplate(design) {
  if (design.tpl == 1) {
    //递归清空掉所有无用的元数据
    let page = design.$refs.viewProxy;
    dsf.designer.recursionLayoutTree(page, null, null, (child) => {
      if (child.metadata && child.metadata.isFromMetaData) {
        child.metadata_fullcode = "";
      }
    });
    design.$nextTick(() => {
      postTemplateLayoutInfo.call(design, page);
    });
  }
}

//获取页面提交的布局的接口参数
async function getPostLayoutParams(designer, page, md, isPreview = false, callback) {
  if (dsf.isDef(isPreview) && dsf.isFunction(isPreview)) {
    callback = isPreview;
    isPreview = false
  }
  //创建页面使用的元数据字典
  let mdDict = null;
  if (md) {
    let dict = setMetaDataDict.call(designer, md.metadata);
    if (!dsf.isEmptyObject(dict)) {
      mdDict = dict;
    }
  }
  // designer.loading = true;
  let staticValue = ""
  if (dsf.designer.isPrototypePage(page)) {
    staticValue = dsf.designer.getStaticValue(page);
  }
  else {
    staticValue = {};
  }
  let ns = (designer.page.context.B || "") + "." + (designer.page.context.M || "");
  let info = {
    layout: dsf.designer.getLayoutInfo(page, isPreview),
    doc: designer.pageDocument,
    css: "",
    scss: designer.extScssCode || "",
    nameSpace: ns,
    moduleName: ns + "." + (designer.page.name || ""),
    stateSetting: designer.pageStateSetting || [],
    staticValue: staticValue,
    mdDict: mdDict,
    designerNode: {
      metadataGroup: {},
      controlGroup: {},
      businessControlGroup: {},
      fromTemplateId: designer.fromTemplateId,
    }
  }
  callback && callback(info);
  info.designerNode["metadataGroup"] = _.map(designer.useMetaGroup.groups, (m) => {
    return { name: m.title || m.name, id: m.id, type: m.type };
  });
  info.designerNode["businessControlGroup"] = _.map(designer.useBusinessControlGroup.groups, (b) => {
    return { name: b.title, id: b.id, type: b.type };
  });
  info.designerNode["controlGroup"] = _.map(designer.useControlsGroup.groups, (b) => {
    return { name: b.title, id: b.id, type: b.type };
  });
  if (md && (postForm.indexOf(page.pageType) >= 0)) {
    let metadataGroup = [];
    _.each(md.metadata, (m, key) => {
      let arr = key.split(".");
      let metaDataKey = arr[0];
      let mID = metaDataKey.replace(/_/g, ".");
      let isExist = _.find(info.designerNode["metadataGroup"], (mg) => {
        return mg.id == mID;
      });
      if (!isExist) {
        info.designerNode["metadataGroup"].push({
          name: mID + "元数据",
          id: mID,
          type: 3
        });
      }
    });
  }
  let cssStr = await dsf.designer.compileCss(designer.extScssCode, info.moduleName);
  designer.extCssCode = cssStr;
  info.css = designer.extCssCode;
  let extJs = designer.extJsCode;
  extJs = dsf.beautify.js(extJs, {
    indent_size: "2",
    indent_char: " ",
    //保持数组压缩
    keep_array_indentation: false,
    //链式方法换行
    break_chained_methods: true,
    //换行长度
    wrap_line_length: 0,
    space_after_anon_function: true
  });
  let x = "/";

  let post = {
    isMobile: designer.mobile,
    md: JSON.stringify(md, (k, v) => ((k == "ref" || k.startsWith("$")) ? undefined : v)),
    stateSetting: JSON.stringify(info.stateSetting || []),
    layout: [
      `<script type="text/template" id="layout">\n${controlToJson(info.layout)}\n<${x}script>`,
      `<script type="text/template" id="css">\n${info.css}\n<${x}script>`,
      `<script type="text/template" id="scss">\n${info.scss}\n<${x}script>`,
      `<script type="text/template" id="designer">\n${JSON.stringify(info.designerNode)}\n<${x}script>`,
      `<script type="text/template" id="staticValue">\n${JSON.stringify(info.staticValue)}\n<${x}script>`,
      `<script type="text/template" id="doc">\n${info.doc ? dsf.escapeFilter(info.doc.trim()) : ""}\n<${x}script>`,
      `<script type="text/template" id="metadatedict">\n${info.mdDict ? JSON.stringify(info.mdDict) : ""}\n<${x}script>`,
      // `<script type="text/template" id="onLineComponents">\n${mdDict ? JSON.stringify(this.online) : ""}\n<${x}script>`
    ].join("\n"),
    extJsCode: extJs,
    evJsCode: "",
    templateid: designer.fromTemplateId,
    pageName: designer.page.name,
    namespace: ns,
    pageTitle: designer.page.title,
    // pageDoc: designer.pageDocument
  };
  Object.defineProperty(post, "$info", {
    enumerable: false,
    get() {
      return info
    }
  });
  return post;
}

//提交布局信息
async function postLayoutInfo(designer, page, md, api, callback) {
  try {
    designer.loading = true;
    let post = await getPostLayoutParams(designer, page, md, callback);
    let designerNode = post.$info.designerNode;
    api = api || designer.$webAPI.pageBuild;
    await api(post).done((result) => {
      if (result.state == "20000") {
        designer.$message("保存成功", true);
        designer.saved = true;
        _.forOwn(md.metadata, (it, key) => {
          if(it?.ref?.metadata){
            delete it.ref.metadata.$isNew;
          }
        });
      } else {
        designer.$message(result.message, false);
      }
    }).error((error) => {
      designer.$message("保存失败", false);
    }).always(() => {
      designer.loadMetaDataGroup(designerNode["metadataGroup"]);
      designer.loading = false;
    });
  }
  catch (ex) {
    dsf.httpError(ex);
  }
  finally {
    designer.loading = false;
  }
}

//提交模板布局信息
function postTemplateLayoutInfo(page) {
  this.loading = true;
  let layout = dsf.designer.getLayoutInfo(page);
  layout = dsf.designer.controlToJson(layout);
  let css = "";
  let scss = this.extScssCode || "";
  let ns = (this.page.context.B || "") + "." + (this.page.context.M || "");
  let moduleName = ns + "." + (this.page.name || "");
  let designer = {
    metadataGroup: {},
    controlGroup: {},
    businessControlGroup: {}
  };
  designer["metadataGroup"] = _.map(this.useMetaGroup.groups, (m) => {
    return { name: m.name, id: m.id, type: m.type };
  });
  designer["businessControlGroup"] = _.map(this.useBusinessControlGroup.groups, (b) => {
    return { name: b.title, id: b.id, type: b.type };
  });
  designer["controlGroup"] = _.map(this.useControlsGroup.groups, (b) => {
    return { name: b.title, id: b.id, type: b.type };
  });
  let x = "/";
  let post = {
    // isMobile: this.mobile,
    type: page.queryString.templateType ? page.queryString.templateType : (this.mobile ? 2 : 1),
    layout: [
      `<script type="text/template" id="layout">\n${layout}\n<${x}script>`,
      `<script type="text/template" id="css"><${x}script>`,
      `<script type="text/template" id="scss">\n${scss}\n<${x}script>`,
      `<script type="text/template" id="designer">\n${JSON.stringify(designer)}\n<${x}script>`,
      `<script type="text/template" id="metadatedict"><${x}script>`
      // `<script type="text/template" id="buttonsRules">${JSON.stringify(this.buttons)}<${x}script>`,
    ].join("\n"),
    desButtonRules: JSON.stringify(this.buttons),
    pageName: this.page.name,
    namespace: ns
    // pageTitle: this.page.title,
  };

  this.$webAPI
    .pageSaveTemplate(post)
    .done((result) => {
      result.state == 20000 ? this.$message("保存成功", true) : this.$message(result.message, false);
    })
    .error((error) => {
      this.$message("保存失败", false);
    })
    .always(() => {
      this.loading = false;
    });
}

//保存到业务组件组
function saveToBusinessComponentGroup(design, controlList) {
  let _this = design; //ddd
  dsf.layer.openDialog({
    title: "保存到",
    fullscreen: false,
    content: "DesBusinessComponent",
    width: "500px",
    height: "500px",
    btns: [
      {
        text: "确定",
        handler: (res) => {
          let data = res.radioData;
          let sublist = _.map(controlList, (el, i) => {
            let metadataInfo = el.metadata;
            let metadataList = [];
            metadataInfo = dsf.type(metadataInfo) == "array" ? metadataInfo : [metadataInfo];
            _.each(metadataInfo, (m) => {
              m.metadata.id = "";
              m.metadata.at = "";
              metadataList.push(m.metadata);
            });
            return {
              code: el.props.caption,
              name: el.props.label,
              version: "5.0",
              terminalText: _this.mobile ? "mobile" : "pc",
              terminalValue: _this.mobile ? "1" : "0",
              modeText: "原型模式^研发模式",
              modeValue: "0^研发模式",
              origin: _this.business + "." + _this.module + "." + _this.pageName,
              md: metadataList ? JSON.stringify(metadataList) : "",
              groupId: data._id,
              content: JSON.stringify(el.props, (key, val) => {
                //去除掉无效提交的项
                if (key === "designId") {
                  return "";
                } else {
                  return val;
                }
              }),
            };
          });
          _this.loading = true;
          _this.$http.post("component/saveBC", { bcStrs: JSON.stringify(sublist) }).then(({ data }) => {
            if (data.state == "20000") {
              dsf.layer.pc.message("保存成功", true);
              _this.loadBusinessComponetGroup([{ id: data.data["dsfa_rm.dsfa_rm_id"] }]);
            } else {
              dsf.layer.pc.message(data.message, false);
            }
          }).catch((ex) => {
            dsf.httpError(ex, { message: "保存出现错误" });
          }).finally(() => {
            _this.loading = false;
          });
        }
      },
      {
        text: "取消"
      }
    ]
  });
}

function setMetaDataDict(metadastas) {
  let dict = {};
  if (!metadastas) {
    return dict;
  }
  for (let key in metadastas) {
    let arr = key.split(".");
    let prefix = arr[0];
    let name = arr[1] ? arr[1] : "";
    if (metadastas[key].level == 1 && !name) {
      dict[key] = {
        id: key,
        name: metadastas[key].name || "",
        type: "subtable",
        fieldsLength: 0,
        valueAttributesDetail: {},
        valueAttributes: [],
        level: 1,
        order: metadastas[key].order,
        rmId: metadastas[key].rmId || ""
      };
    } else {
      let va = metadastas[key].valueAttributes;
      let validate = metadastas[key]?.validate?.rules || [];
      let valueAttributesDetail = _.keyBy(va, "code");
      let dataSource = metadastas[key]?.dataSource || null;
      dict[key] = {
        id: key,
        name: metadastas[key].name,
        type: "field",
        level: 0,
        hidden: metadastas[key].hidden,
        // valueAttributesRemark: valueAttributesRemark,
        fieldsLength: metadastas[key].valueAttributes && metadastas[key].valueAttributes.length,
        valueAttributes: metadastas[key].valueAttributes && _.map(metadastas[key].valueAttributes, "code"),
        valueAttributesDetail: valueAttributesDetail,
        order: metadastas[key].order,
        required: metadastas[key].required,
        rmId: metadastas[key].rmId || "",
        dataSource: dataSource
      };
      if (dict[prefix]) {
        dict[key].level = 1;
        dict[key].inSubTable = prefix;
      }
    }
  }
  return dict;
}

function isFormPage(page) {
  let t = ""
  if (page) {
    if (dsf.type(page) == 'object') {
      t = page.pageType;
    }
    else {
      t = page;
    }
  }
  return postForm.indexOf(t) >= 0
}

function isPrototypePage(page) {
  let t = ""
  if (page) {
    if (dsf.type(page) == 'object') {
      t = page.mode;
    }
    else {
      t = page;
    }
  }
  return t === enums.viewMode.PROTOTYPE;
}

//获取组件属性的json配置
function getComponentPropries(designer, ctrlType, folder) {
  return designer.$http.get(`$/pd_attributes/${folder}/${ctrlType}.json`)
}

function beginEditTextConent(target) {
  target = $(target);
  if (!target.hasClass("ds-text-content")) {
    target = target.children(".ds-text-content");
  }
  if (target) {
    setTimeout(() => {
      target?.focus?.();
      target?.select?.();
    }, 200);
  }

}

function triggerDialogPropty(prop) {
  let propel = $(".designer-props").find("[rel='" + prop + "']");
  propel.find('.ds-button')?.get?.(0)?.click?.()
}



export default {
  getParentControl,
  isAllowDrop,
  controlToJson,
  broadcast,
  getStaticValue,
  getLayoutInfo,
  getDsfComponent,
  recursionLayoutTree,
  createViewMetaData,
  createComponentMetaData,
  getComponentByTarget,
  createComponent,
  createMobileComponent,
  convertTemplateAndControlData,
  saveLayoutValidate,
  saveToBusinessComponentGroup,
  compileCss,
  validateCaption,
  getExtPath,
  getPageDocumet,
  getLayoutPath,
  saveLayout,
  saveLayoutToTemplate,
  compileFormulaCode,
  getPostLayoutParams,
  postLayoutInfo,
  isFormPage,
  isPrototypePage,
  getComponentPropries,
  getRealComponent,
  beginEditTextConent,
  triggerDialogPropty
};