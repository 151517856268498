import dsf from "../utils";
import ValidateError from '../output/validateError'
import validate from './validate';
import * as common from '../output/layoutViewCommon'


let noopHooks = function (data, next) {
  next();
};
let createWatcher = function ($vm, key, callback, options) {
  return $vm.$watch(
    function () {
      try {
        return dsf.express.eval("{{" + key + "}}", $vm.$viewData);
      } catch (ex) {
        return undefined;
      }
    },
    callback,
    options
  );
};
let createformWatch = function ($vm) {
  // 注册二次开发的监听器
  // 监听的表单值
  let formWatch = $vm.$options.formWatch || {};
  for (let key in formWatch) {
    let item = formWatch[key];
    let fn,
      options = {};
    if (dsf.isFunction(item)) {
      fn = item.bind($vm);
    } else if (dsf.isObject(item)) {
      if (!item.handler || !dsf.isFunction(item.handler)) {
        break;
      }
      fn = item.handler.bind($vm);
      if (item.deep) {
        options.deep = true;
      }
      if (item.immediate) {
        options.immediate = true;
      }
    } else {
      break;
    }
    let unwatch = createWatcher($vm, key, fn, options);
    if (!$vm.$formWatchs) {
      $vm.$formWatchs = []
    }
    $vm.$formWatchs.push(unwatch);
  }
}

//初始化数据
function init($vm, data) {
  if (data) {
    let _custom = {};
    try {
      _custom = data._custom ? JSON.parse(data._custom) : '{}';
    }
    catch (ex) {
      dsf.error("custom信息转换错误")
    }
    if (dsf.type(_custom) != 'object') {
      _custom = {};
    }
    data._custom = _custom;
    $vm.context.$$viewInitData = data;
  }
  //初始化枚举信息
  initEnum.call($vm);
  //初始化流程信息
  initWorkFlow.call($vm);
  //初始化页面状态信息
  initStateMangae.call($vm);

}
//初始化控件数据源(下拉框、单选多选等)
function initEnum() {
  for (let k in this.context.$$viewInitData) {
    let d = this.context.$$viewInitData[k];
    if (d && d.dataSource) {
      this.$set(this.context.$$enum, k, d.dataSource);
    }
  }
}

//将流程的组件数组信息解构成对象，方便后续查找
function initWorkFlow() {
  if (this.$viewInitData?.workflow) {
    if (this.$viewInitData.workflow.ctrls) {
      this.workFlow.ctrls = _.keyBy(this.$viewInitData.workflow.ctrls, (el) => {
        return dsf.isDef(el.metadataCode) ? el.metadataCode : el.caption;
      });
    }
    if (this.$viewInitData.workflow.btns) {
      this.workFlow.btns = _.keyBy(this.$viewInitData.workflow.btns, (el) => {
        return el.id;
      });
    }
  }
}
//将状态管理的组件数组信息解构成对象，方便后续查找
function initStateMangae() {
  if (this.$viewInitData?.state) {
    let data = this.$viewInitData?.state?.data;
    if (data.ctrls) {
      this.stateManage.ctrls = _.keyBy(data.ctrls, (el) => {
        return el.designId
      });
    }
    if (data.buttons) {
      this.stateManage.buttons = _.keyBy(data.buttons, (el) => {
        return el.id;
      });
    }
  }
}
//将方法转换为promise方法
function promiseFn(fn) {
  return function () {
    let _array = Array.prototype.slice.call(arguments);
    let self = this;
    return new Promise((resolve, reject) => {
      let next = (mark) => {
        if (mark === false) {
          reject("next返回了false,停止执行");
        } else {
          if (dsf.isError(mark)) {
            reject(mark);
          }
          else {
            resolve(_array);
          }
        }
      };
      fn.apply(self, _array.concat([next]));
    });
  };
}
function UFUNCWatch(ufunc) {
  let _this = this;
  let uw = this.$watch(function () {
    let fn = ufunc.computed;
    let local = ufunc.self.local;
    let val = fn.call(this, _this, local);
    let valStr = val + "";
    if (valStr.indexOf('NaN') >= 0 || valStr.indexOf('Infinity') >= 0 || valStr.indexOf('undefined') >= 0) {
      return null
    }
    return val;
  }, function (v, ov) {
    ufunc.model.callback.call(this, v)
  }, {
    // deep: true,
    immediate: ufunc.immediate
  });
  return uw;
}
var div = document.createElement("DIV");
export default {
  inheritAttrs: false,
  inject: {
    $app: {
      default: null
    },
    $parentView: {
      default: null
    },
    $frame: {
      default: null
    },
    $dialog: {
      default: null
    },
    $isMobile: {
      default: false
    }
  },
  provide() {
    return {
      $vm: this,
      $parentView: this,
      $choiceParams: this.choiceParams
    };
  },
  mixins: [validate],
  props: {
    nameSpace: {
      type: String,
      default: ""
    },
    pageName: {
      type: String,
      default: ""
    },
    queryString: {
      type: Object,
      default() {
        return {};
      }
    },
    choiceParams: {
      type: Object,
      default() {
        return null;
      }
    },
    pageProps: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      context: {
        $$viewInitData: {},
        $$viewRequestsData: {},
        $$viewData: {},
        $$enum: {},
        $$notAllowPostKey: [],
        $$postNullKeys: [],
        $$saved: false
      },
      _isLoaded: false,
      _isForm: false,
      _postState: "",
      _isReady: false,
      _viewDataBak: null,
      _dataKey: this.queryString.id,
      _isLayoutView: true,
      _fromDesigner: true,
      _pageContentHeight: "auto",
      _viewRequests: { 'inited': [], "dataLoaded": [], 'ready': [] },

      staticData: {},
      frames: {},
      metadataDict: {},
      metadataKeyList: [],
      loading: false,
      flows: {},
      $forceReadOnly: false,
      $choice: null,
      $isDestroyed: false,
      allComponents: [],
      _errors: {},
      workFlow: {
        ctrls: {},
        btns: {}
      },
      stateManage: {
        ctrls: {},
        buttons: {}
      },
    };
  },
  computed: {
    $dataKey: {
      get() {
        return this.$data._dataKey;
      },
      set(v) {
        this.$data._dataKey = v;
      }
    },
    $isPrototype() {
      return this.$data.$$mode == dsf.enums.viewMode.PROTOTYPE;
    },
    $staticValue() {
      return this.$data.$$staticValue;
    },
    $localParams() {
      return this.$frame.localParams;
    },
    $viewData() {
      return this.context.$$viewData;
    },
    $viewInitData() {
      return this.context.$$viewInitData;
    },
    $viewRequestsData() {
      return this.context.$$viewRequestsData;
    },
    $enum() {
      return this.context.$$enum;
    },
    $isLoaded() {
      return this.$data._isLoaded;
    },
    $isReady() {
      return this.$data._isReady;
    },
    //页面是否被keepAlive,返回page组件的isKeepAlive属性
    $keepAlive() {
      return this.$data.$$keepAlive || false;
    },
    $pageType() {
      return this.$data.$$pageType || "";
    },
    $isForm() {
      return this.$data._isForm;
    },
    $servicePrefix() {
      let servicePrefix = this?.$baseUrl || "";
      return servicePrefix;
    },
    $pageContentHeight: {
      get() {
        return this.$data._pageContentHeight;
      },
      set(v) {
        this.$data._pageContentHeight = v;
      }
    }
  },
  // 页面复用时触发数据更新
  // beforeRouteUpdate(to, from, next) {
  //   // 如果页面类型为form、question、exam
  //   if (this.$data._postState == 'FORMSAVE') {
  //     next();
  //     return;
  //   }
  //   // 其他情况的页面复用
  //   this.reset();
  //   this.reloadData();
  //   next();
  // },
  beforeCreate() {
    let _this = this;
    this.$dialogArgs = null;
    this.$openerVm = null;
    Object.defineProperty(this, '$page', {
      get() {
        return this.$refs.page;
      },
      // configurable: false,
      // writable: false,
      enumerable: false
    });

    Object.defineProperty(this, '$topView', {
      get() {
        let dom = $(_this.$el)
        let pages = dom.parents('.ds-control.ds-page');
        let top = pages.get(pages.length - 1);
        if (top) {
          let page = top.__vue__;
          if (page.$data._isLayoutView) {
            return page;
          }
          return page?.$vm || {}
        }
        return this
      },
      // configurable: false,
      // writable: false,
      enumerable: false
    })
  },
  created() {
    if (this.i18nMessage) {
      for (let k in this.i18nMessage) {
        let message = this.i18nMessage[k];
        this.$i18n.mergeLocaleMessage(k, {
          ["$" + this.$options.name]: message
        })
      }
    }
    if (this.$frame) {
      this.$frame.content = this;
    }
    this.$on("page-resize", this.$pageSizeChange);
    this.$computeds = {};
  },
  updated() { },
  activated() { },
  deactivated() { },
  mounted() {
    this.$init();
  },
  beforeDestroy() {
    this.$data.$isDestroyed = true;
    this.context.$$viewData = null;
    this.context.$$viewInitData = null;
    this.context.$$notAllowPostKey = null;
    this.context.$$enum = null;
    this.$data.$choice = null;
    for (let k in this.frames) {
      this.$delete(this.frames, k);
    }
    if (this.$frame) {
      delete this.$frame.content;
    }
    if (this?.$formWatchs) {
      _.each(this.$formWatchs || [], (it, i) => {
        it && it();
      });
      this.$formWatchs = null;
    }
    if (this?.$ufuncWatchs) {
      _.each(this.$ufuncWatchs, (it) => {
        it && it();
      })
      this.$ufuncWatchs = null
    }
  },
  destroyed() {
    this.$computeds = null;
    this.$dialogArgs = null;
    this.$openerVm = null;
  },
  methods: {
    $setComponentProp(key, propKey, value, hash) {
      let keyStr = key + (hash ? "-" + hash : "");
      keyStr = this.$controlsMapping[keyStr];
      let c = this.$controls[keyStr];
      c && (c[propKey] = value)
    },

    $loopSubTable(key, callback) {
      let arr = [];
      _.each(this.$viewData[key] || [], (row) => {
        let result = callback && callback(row);
        arr.push(result)
      });
      return arr;
    },
    $pageSizeChange() {
      var el = this.$el;
      if (el && el.nodeType == 1) {
        var content = this.$page.getContentHeight();
        // this.pageContentHeight = content;
        this.$pageContentHeight = content;
        // console.log(el, this.$pageContentHeight)
      }
    },
    $addCustom(caption, props) {
      dsf.custom.add(this, caption, props);
    },
    $removeCustom(caption) {
      dsf.custom.remove(this, caption);
    },
    $clearCustom(caption) {
      dsf.custom.clear(this, caption);
    },
    getControl(ref, hash) {
      return this.$refs[ref];
    },
    //初始化页面
    async $init(callback) {
      let viewData = null;
      let initRequestData = null;
      let dataLoadedRequestData = null;
      let loadding = null;
      return new Promise((resolve) => {
        resolve();
      }).then(() => {
        if (!this.$data.$isDestroyed) {
          //获取页面初始化数据
          return this.$initData();
        }
      }).then((response) => {
        if (!this.$data.$isDestroyed) {
          //执行初始化数据完成的钩子函数
          initRequestData = response ? response.data : {};
          return common.mergeExecCycleFunctions(this, 'inited', [initRequestData], common.loadPromiseFn);
        }
      }).then(() => {
        if (!this.$data.$isDestroyed) {
          let _this = this;
          initRequestData && init(this, initRequestData);
          //初始化数据，顺便获取相关组件的默认值
          viewData = this.$initControls();
          //将页面显示出来
          this.$data._isLoaded = true;
          //原型设计界面加载静态数据
          if (this.$isPrototype) {
            return this.$loadFormStaticData();
          }
          //调用表单(问卷)加载数据
          else if (this.$loadData) {
            //如果存在主键参数则加载表单数据
            this.loading = true;
            // loadding = dsf.layer.loading()
            return this.$loadData(this.nameSpace);
          }
        }
      }).then((response) => {
        if (!this.$data.$isDestroyed) {
          //执行查询表单数据完成的钩子
          dataLoadedRequestData = response ? response.data : {};
          return common.mergeExecCycleFunctions(this, 'dataLoaded', [dataLoadedRequestData], common.loadPromiseFn);
        }
      }).then(() => {
        if (!this.$data.$isDestroyed) {
          let _this = this;
          // dsf.log("开始为页面进行赋值了");
          //将数据更新给viewData变量，如果data有数据则覆盖初始化时的默认值
          if (dataLoadedRequestData) {
            dsf.mix(viewData, dataLoadedRequestData);
          }
          _this.context.$$viewData = viewData;
        }
      }).catch((err) => {
        dsf.layer.message(err, false)
        // dsf.httpError(err, { isSilent: true });
      }).finally(() => {
        this.loading = false;
        //提取公式编辑器中的依赖项目
        // this.ufuncWatchs = getUFUNCWatch.call(this);
        this.$nextTick(() => {
          try {
            //重置页面和下级组件的宽高
            let ready = this.$options.ready;
            ready && ready.call(this);
            let isReadyFirst = false;
            if (!this.$data._isReady) {
              isReadyFirst = true;
            }
            this.$data._isReady = true;
            createformWatch(this);
            if (isReadyFirst) {
              this.$data._viewDataBak = dsf.mix(true, {}, this.context.$$viewData)
            }
            callback && callback();
          }
          catch (ex) {
            dsf.error(ex)
          }
          finally {
            //
          }

        });
      });
    },
    //调用初始化接口
    async $initData() {
      try {
        let params = {
          pageName: this.pageName,
          namespace: this.nameSpace,
          ...this.queryString,
        }
        if (this.nameSpace && this.pageName) {
          if (this.$dataKey) {
            params.id = this.$dataKey;
          }
          let result = await this.$getInitDataAPI(params);//this.$webAPI.getInitData(params, true);
          return result.data;
        }
        else {
          await dsf.noop();
          return {};
        }
      }
      catch (err) {
        throw err;
      }
    },
    $getInitDataAPI(params) {
      return this.$webAPI.getInitData(params, true);
    },
    $getDefaultValue(key) {
      if (dsf.isDef(this.$viewInitData[key]) && dsf.isDef(this.$viewInitData[key].defaultValue)) {
        let v = this.$viewInitData[key].defaultValue;
        let type = dsf.type(v);
        if (type == "object" || type == "array") {
          v = dsf.mix(true, type == "object" ? {} : [], v);
        }
        return v;
      }
    },
    $dataFormat(k, params, v) {
      if (params) {
        let filter = Vue.filter(k);
        if (filter) {
          return filter(v, params);
        }
      }
      return v;
    },
    $clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    //设置组件初始化状态
    $setControlInitProps(ctrlProps, type) {
      let flowdebugger = this.queryString._wfdebug == 1;
      let initData = this.$viewInitData;
      //如果有流程就使用流程，否则使用状态管理配置
      if (!dsf.isEmptyObject(initData.workflow)) {
        if (type == "formControl") {
          let flowProps = ["readOnly", "visible", "required"];
          if (this.workFlow.ctrls) {
            let key = ctrlProps.isFormControl ? ctrlProps.metadata_fullcode : "";
            let workFlowCtrl = this.workFlow.ctrls[key];
            if (workFlowCtrl) {
              _.each(flowProps, (prop) => {
                if (dsf.isDef(workFlowCtrl[prop])) {
                  if (flowdebugger) {
                    dsf.log(`"组件：${ctrlProps.caption + (ctrlProps.label ? "(" + ctrlProps.label + ")" : "")}的${prop}属性被流程设置为${workFlowCtrl[prop]}`);
                  }
                  ctrlProps[prop] = workFlowCtrl[prop];
                  if (ctrlProps.isFormControl && prop == 'required') {
                    workFlowCtrl[prop] ? this.$setRequired(ctrlProps) : this.$setNotRequired(ctrlProps)
                  }
                }
              });
            }
          }
        } else if (type == "buttons") {
          if (initData.workflow && initData.workflow.btns) {
            _.each(ctrlProps.buttons, (btn) => {
              let workFlowBtn = this.workFlow.btns[btn.id];
              if (workFlowBtn && dsf.isDef(workFlowBtn.visible)) {
                if (flowdebugger) {
                  dsf.log(`按钮：${btn.caption}的visible属性被流程设置为${workFlowBtn.visible}`);
                }
                btn.visible = workFlowBtn.visible;
              }
            });
          }
        } else if (type == "block") {
          let flowProps = ["readOnly", "visible"];
          let key = ctrlProps.caption || "";
          let workFlowCtrl = this.workFlow.ctrls[key];
          if (workFlowCtrl) {
            _.each(flowProps, (prop) => {
              if (dsf.isDef(workFlowCtrl[prop])) {
                if (flowdebugger) {
                  dsf.log(`"组件：${ctrlProps.caption}的${prop}属性被流程设置为${workFlowCtrl[prop]}`);
                }
                ctrlProps[prop] = workFlowCtrl[prop];
              }
            });
          }
        }
      }
      //页面状态管理
      else if (!dsf.isEmptyObject(initData.state)) {
        let state = this.stateManage;
        if (!dsf.isEmptyObject(state)) {
          if (type == "formControl" || type == "block") {
            let stateProps = ["readOnly", "visible", "required"];
            if (state.ctrls) {
              let key = ctrlProps.designId;
              let currentCtrl = this.stateManage.ctrls[key];// _.find(state.ctrls, (it) => it.designId == key);
              if (currentCtrl) {
                _.each(stateProps, (prop) => {
                  if (dsf.isDef(currentCtrl[prop]) && dsf.isDef(ctrlProps[prop])) {
                    ctrlProps[prop] = currentCtrl[prop]
                    if (ctrlProps.isFormControl && prop == 'required') {
                      currentCtrl[prop] ? this.$setRequired(ctrlProps) : this.$setNotRequired(ctrlProps)
                    }
                  }
                });
              }
            }
          }
          else if (type == "buttons") {
            if (state.buttons) {
              let key = ctrlProps.designId;
              let buttons = _.filter(state.buttons, (it) => {
                return it.ownerId == key;
              });
              _.each(buttons, (btn) => {
                let _btn = _.find(ctrlProps.buttons, (it) => {
                  return btn.id == it.actionName + (it.alias ? "." + it.alias : "");
                });
                _btn && this.$set(_btn, 'visible', btn.visible);
              });
            }
          }
        }
      }
    },
    $initControls() {
      let vd = {};
      let controls = this.$controls;
      let initData = this.$viewInitData;
      for (let k in controls) {
        let ctrl = controls[k];
        if (ctrl.metadata) {
          let key = ctrl.metadata_fullcode;
          if (ctrl.metadata.level == 0) {
            if (initData && initData[key]) {
              //将默认值赋予viewData
              if (dsf.isDef(this.$getDefaultValue(key))) {
                vd[ctrl.metadata_fullcode] = this.$getDefaultValue(key);
              }
            }
          } else if (ctrl.metadata.level == 1 && ctrl.isOneToMany) {
            if (dsf.isDef(this.$getDefaultValue(key))) {
              vd[ctrl.metadata_fullcode] = this.$getDefaultValue(key);
            }
          }
          //如果isview=1页面只读
          if (ctrl.isFormControl && this.$data.$forceReadOnly) {
            ctrl.readOnly = true;
            continue;
          }
          this.$setControlInitProps(ctrl, "formControl");
        } else if (ctrl.isButtonBar) {
          this.$setControlInitProps(ctrl, "buttons");
        } else if (ctrl.isBlockControl) {
          this.$setControlInitProps(ctrl, "block");
        }
        if (this.$staticValue[ctrl.caption]) {
          ctrl.staticValue = this.$staticValue[ctrl.caption];
        }
        let cust = this.$viewInitData._custom || {};
        dsf.mix(ctrl, cust[ctrl.caption] || {});
      }
      return vd;
    },
    reset() {
      for (let k in this.$refs) {
        if (this.$refs[k] && this.$refs[k].reset) {
          this.$refs[k].reset();
        }
      }
    },
    reloadData() {
      for (let k in this.$refs) {
        if (this.$refs[k] && this.$refs[k]._isVue && this.$refs[k].reloadData) {
          this.$refs[k].reloadData();
        }
      }
    },
    //获取表单静态数据
    $loadFormStaticData() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          let data = {};
          for (let k in this.$refs) {
            let ctrl = this.$refs[k];
            if (!ctrl.designId) {
              continue;
            }
            let staticValue = this.$staticValue[ctrl.caption];
            if (staticValue && ctrl.isFormControl && !ctrl.$dataHash) {
              staticValue = ctrl.$getRealStaticValue(staticValue);
              data[ctrl.metadata_fullcode] = staticValue;
            }
          }
          resolve({ data });
        })
      })

    },
    reloadParentViewData() {
      //刷新父页面函数，由pcLayoutView.js和mobileLayoutView.js重写
    },
    //设置组件为必填
    $setRequired(ctrlProps) {
      if (ctrlProps.isFormControl) {
        let validate = ctrlProps?.metadata?.validate;
        let requiredList = [];
        if (!validate) {
          ctrlProps.metadata.validate = {
            _rules: []
          }
          validate = ctrlProps.metadata.validate;
        }
        else {
          if (!validate._rules) {
            validate._rules = dsf.mix(true, [], validate.rules);
          }
          requiredList = _.filter(validate._rules, (it) => it.type == 'required')
        }
        if (requiredList.length <= 0) {
          let validateRules = ctrlProps.metadata.validate._rules;
          _.each(ctrlProps.metadata.valueAttributes, (va) => {
            let rule = dsf.validate.fn.getMetaDataValidateObject();
            rule.type = 'required'
            validateRules.push(rule)
          });
        }
        else {
          //如果校验规则中必填有被设置过则使用用户设置的必填规则
          ctrlProps.metadata.validate._rules = dsf.mix(true, [], ctrlProps.metadata.validate.rules || []);
        }
      }

    },
    //设置为非必填
    $setNotRequired(ctrlProps) {
      if (ctrlProps.isFormControl) {
        let validate = ctrlProps?.metadata?.validate;
        let requiredList = [];
        if (!validate) {
          ctrlProps.metadata.validate = {
            _rules: []
          }
          validate = ctrlProps.metadata.validate;
        }
        else {
          if (!validate._rules) {
            validate._rules = dsf.mix(true, [], validate.rules);
          }
          requiredList = _.filter(validate._rules, (it) => it.type == 'required')
        }
        if (requiredList.length > 0) {
          _.eachRight(validate._rules, (it) => {
            if (it.type == 'required') {
              dsf.array.remove(validate._rules, it);
            }
          })
        }
      }
    },
    //返回$choice中组件的选中对象
    yes() {
      let result = this.$data.$choice.getReturnValue();
      return result;
    },
    //添加表单组件
    $addFiled(ctrl) {
      this.allComponents.push(ctrl);
      this.$nextTick(() => {
        this.updateMetaDataDict(ctrl);
      });
    },
    //表单组件被销毁
    $removeFiled(ctrl) {
      if (this.metadataDict[ctrl.formName]) {
        //refs用来存储组件实例，组件移除时将refs中的实例也移除掉
        dsf.array.remove(this.metadataDict[ctrl.formName].refs || [], ctrl)
      }
      dsf.array.removeWith(this.allComponents, (it) => it == ctrl);
    },
    // 更新metadataDict，因为页面中有存在动态创建的组件（子表，动态布局）
    updateMetaDataDict(ctrl) {
      if (this.metadataDict) {
        let formName = ctrl.formName;
        let fullCode = ctrl.metadata_fullcode;
        let metadata = ctrl.metadata;
        if (!metadata || !formName) {
          return;
        }
        let arr = formName.split(".");
        let prefix = arr[0];
        let name = arr[1] ? arr[1] : "";
        let isUser = false;
        if (!this.metadataDict[formName]) {
          this.metadataDict[formName] = {
            type: "field",
            fieldsLength: metadata.valueAttributes && metadata.valueAttributes.length,
            level: 0,
            valueAttributes: _.map(metadata.valueAttributes, "code"),
            refs: []
          };
          isUser = true;
        }
        this.metadataDict[formName].refs = this.metadataDict[formName].refs || [];
        this.metadataDict[formName].valueIsHtml = ctrl.valueIsHtml === true ? true : false
        this.metadataDict[formName].validate = metadata.validate;
        this.metadataDict[formName].refs.push(ctrl);
        dsf.array.ensure(this.metadataKeyList, formName);
        if (this.metadataDict[formName]) {
          this.metadataDict[formName].isUser = isUser;
          if (ctrl.validateRule) {
            this.metadataDict[formName].validateRule = ctrl.validateRule;
          }
        }
      }
    },

    //获取所在作用域的元数据字典
    getScopeMetaDataDict(component) {
      if (dsf.isEmptyObject(this.metadataDict)) {
        return {};
      }
      let metadata = component.metadata;
      if (!metadata || !component.metadata_fullcode) {
        return {};
      }
      let fullCode = component.metadata_fullcode;
      if (this.metadataDict[fullCode]) {
        if (this.metadataDict[fullCode].inSubTable) {
          let result = {};
          _.each(this.metadataDict, (el, i) => {
            if (el.inSubTable === this.metadataDict[fullCode].inSubTable) {
              result[i] = el;
            }
          });
          return result;
        } else {
          let result = {};
          _.each(this.metadataDict, (el, i) => {
            if (dsf.isUnDef(el.inSubTable)) {
              result[i] = el;
            }
          });
          return result;
        }
      }
    },
    resize() {
      this.$page && dsf.resizeComponent(this.$page);
    },
    //获取html类组件内容（排除html标签）
    $getEditorConent(value) {
      div.innerHTML = value;
      //是否存在贴图
      let isImg = div.querySelector("img");
      if (isImg) {
        return div.innerHTML;
      }
      else {
        return div.innerText.trim();
      }
    },
    $viewDataReset(callback) {
      this.context.$$viewData = {};
      this.context.$$viewInitData = {};
      this.$init()
      // this.context.$$viewData = dsf.mix(true,{},this.$data._viewDataBak)
    },
    $pushUFUNC(designId, ufunc) {
      this.$computeds[designId] = ufunc;
      if (!this.$ufuncWatchs) {
        this.$ufuncWatchs = {}
      }
      let uw = UFUNCWatch.call(this, ufunc);
      this.$ufuncWatchs[designId] = uw;
    },
    $removeUFUNC(designId, ufunc) {
      this.$data.$computeds?.[designId] && (delete this.$data.$computeds[designId])
      this.$ufuncWatchs?.[designId] && (delete this.$ufuncWatchs[designId])
    },

  },
  watch: {
    $isLoaded: {
      handler(nv, ov) {
        if (nv != ov && nv === true) {
          this.$nextTick(() => {
            //调用布局resize
            this.resize();
            this.$emit("loaded", this);
          });
        }
      }
    },
    $isReady: {
      handler(o, v) {
        this.$nextTick(() => {
          this.$emit('ready', this);
        })
      }
    }
  }
};
// function execViewRequests(vm, cycle,data) {
//   let httpConfig = vm._data._viewRequests[cycle];
//   let https = [];
//   _.each(httpConfig, (it) => {
//     let c=Object.create(vm,{
//       value:data
//     })
//     let url = vm.$replace(it.url, c)
//     if (cycle == 'dataLoaded') {
//       https.push(vm.$http.get(url, {
//         "_id": vm.$dataKey,
//         ...vm.queryString
//       }));
//     }
//     else if (cycle == 'inited') {
//       https.push(vm.$http.get(url, vm.queryString));
//     }
//   })
//   return Promise.all(https)
//     .then(res => {
//       // debugger
//       let returnVal = [];
//       _.each(httpConfig, (it, i) => {
//         let result = res[i];
//         if (it.callback) {
//           if (result.data.state == 20000) {
//             it.callback.call(vm, result.data, it);
//           }
//           else {
//             dsf.error(result.data.message);
//             throw result.data.message;
//           }
//         }
//         returnVal.push(result.data.data);
//       })
//       return Promise.resolve(returnVal);
//     })
//     .catch(ex => {
//       throw ex;
//     })
// }

// let fn=_.debounce(
