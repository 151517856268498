import control from "./control";

const VALUE_CHANGE = "model-value-mutation";
const VALUE_UPDATE_SYNC = "update:value";
//表单组件在列表中触发变化
const triggerDataGridRowChange = _.debounce(function (ctrl) {
  ctrl.$list.rowDataChange(ctrl.local)
}, 100)

export default {
  model: {
    prop: "value",
    event: VALUE_CHANGE
  },
  props: {
    //是否是表单组件
    isFormControl: {
      type: Boolean,
      default: true
    },
    //表单项的名称
    label: {
      type: String,
      default: ""
    },
    //是否显示label
    showLabel: {
      type: Boolean,
      default: true
    },
    //是否只读
    readOnly: {
      type: Boolean,
      default: false
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    //保存时是否提交到后端
    allowPost: {
      type: Boolean,
      default: true
    },
    //是否提交一个null值给服务器(废弃)
    isPostNull: {
      type: Boolean,
      default: true
    },
    //是否有必填标识
    required: {
      type: Boolean,
      default: false
    },
    //值，根据组件内容不同可能有不同类型，文本框为字符串，下拉框为{text:'',value:''}对象
    value: {
      type: [String, Number, Object, Array, Boolean, Date],
      default() {
        return null;
      }
    },
    //提示文本（移动端）
    dataCapion: {
      type: String,
      default: ""
    },
    //是否通过图标显示描述
    showDataCaptionIcon: {
      type: Boolean,
      default: true
    },
    //描述触发方式 值：click,hover
    showDataCaptionTrigger: {
      type: String,
      default: "click"
    },
    //描述实现方式，弹窗或者浮动层 值：popover,dialog
    showDataCaptionMode: {
      type: String,
      default: "popover"
    },
    //提示语音mp3地址（移动端）
    dataAudioUrl: {
      type: String,
      default: ""
    },
    //提示视频mp4地址（移动端）
    dataVideoUrl: {
      type: String,
      default: ""
    },
    //元数据
    metadata: {
      type: Object,
      default() {
        return null;
      }
    },
    //只读状态显示内容
    readOnlyShowText: {
      type: String,
      default: "text"
    },
    //元数据代码
    metadata_fullcode: {
      type: String,
      default: ""
    },
    //是否简易模式
    simple: {
      type: Boolean,
      default: false
    },
    //元数据等级，主表为0子表或者子表组件为1
    metaDataLevel: {
      type: Number,
      default: 0
    },
    //组件在列表或者子表中简易状态下数据格式化
    formatter: {
      type: String,
      default: ""
    },

    //validateRule、isValidate已过时后续不要再使用==================
    //验证规则名称
    validateRule: {
      type: String,
      default: ""
    },
    //是否需要验证
    isValidate: {
      type: Boolean,
      default: true
    },
    //validateRule、isValidate已过时后续不要再使用==================

    //所在栅格布局占比
    colSpan: {
      type: Number,
      default: 24
    },
    labelWidth: {
      type: Number,
      default: 120
    },
    //产生文本代理,生成模板时不创建组件标记对，使用<span>标签代替
    textProxy: {
      type: Boolean,
      default: false
    },
    //文本代理模式下前缀图标
    textProxyIcon: {
      type: String,
      default: ""
    },
    //文本代理模式下前缀图标颜色
    textProxyIconColor: {
      type: String,
      default: ""
    },
    // 是否即时触发审核
    immediatelyAudit: {
      type: Boolean,
      default: false
    },
    //组件模式
    controlMode: {
      type: String,
      default: "normal"
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    // 公式对象
    formula: {
      type: Object,
      default() {
        return {
          //公式表达式
          formula: '',
          //自定义函数
          func: '',
          //真实的公式
          realFormula: ''
        }
      }
    },
    //初始化时就监听公式计算
    formulaImmediate: {
      type: Boolean,
      default: false
    },
    //输入区域内容水平对齐方式
    inputAlign: {
      type: String,
      default: "left"
    },
    //隐藏忽略校验
    hideIgnoreValidate: {
      type: Boolean,
      default: false
    },
    //禁用忽略校验
    disabledIgnoreValidate: {
      type: Boolean,
      default: false
    },
    //隐藏时提交空值
    hidePostNullValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      gridRowChangeKey: "change",
      _errors: [],
      formulaExpression: null
    };
  },
  mixins: [control],
  computed: {
    validating() {
      return this?.$form?.validating
    },
    errors: {
      get() {
        if (this?.$vm?.$errors) {
          let errors = this.$vm.$errors[this.$formName] || [];
          return errors;
        }
        else {
          return this.$data._errors;
        }

      },
      set(value) {
        if (this?.$vm?.pushError) {
          if (value && value.length > 0) {
            this.$delete(this.$vm.$errors, this.$formName);
            this.$vm.pushError(value);
          }
          else if (!value || value.length <= 0) {
            this.$delete(this.$vm.$errors, this.$formName);
          }
        }
        else {
          this.$data._errors = value;
        }
      }
    },
    getCss() {
      return [
        ...this.$getClass(),
        this.errors.length > 0 ? "ds-error" : "",
        this.showLabel ? "ds-has-label" : "",
        this.required ? "ds-required" : "",
        this.readOnly ? "ds-readonly" : "",
        !this.showLabel && this.required ? "ds-nolabel-required" : '',
        this.disabled ? "ds-disabled" : "",
        !this.showBorder ? "ds-no-border" : "",
        (this.inputAlign != 'left') ? "input-align-" + this.inputAlign : ""
      ];
    },
    errorsMsg() {
      return _.map(this.errors, "message").join(",");
    },
    $labelWidth() {
      let systemFontSize = dsf.themeConfig.setting.fontSize;
      let width = 0;
      if (this.$page) {
        width = (this.$page.formItemLabelWidth || this.labelWidth || 120);
      }
      else {
        width = this.labelWidth;
      }
      let offset = {
        'bigFont': 10,
        'bigFont_1': 20,
        'bigFont_2': 30,
        'bigFont_3': 40
      }
      width += (offset[systemFontSize] || 0);
      return width;
    },
    formName() {
      return this.$attrs['form-name'] || this.metadata_fullcode || ""
    },
    $formName() {
      let formName = this.formName;
      if (formName) {
        return this.formName + (this.$dataHash ? "-" + this.$dataHash : '');
      }
      return "";
    }
  },
  created() {
    if (!this.isDesign && this.$vm) {
      this.$vm.$addFiled(this);
    }
    if (!this.isDesign && this.$dynamicForm) {
      this.$dynamicForm.$addFiled(this);
    }
    //如果是保存过的元数据一定会存在id、at、code
    if (this.isDesign && this.metadata) {
      if ((this.metadata.id && this.metadata.code && this.metadata.at) || this.metadata.isFromMetaData) {
        this.metadata.$isValidate = false;
      }
      else {
        this.metadata.$isValidate = true;
      }
    }
    metadataValidateConfigToRules.call(this);
    pushUFUNC.call(this)

  },
  mounted() {
    updateElAttr.call(this)
  },
  updated() {
    updateElAttr.call(this)
  },
  beforeDestroy() {
    if (!this.isDesign && this.$vm) {
      this.$vm.$removeFiled(this);
      $removeUFUNC.call(this);
    }
    if (!this.isDesign && this.$dynamicForm) {
      this.$dynamicForm.$removeFiled(this);
    }
  },
  methods: {
    showHelper() {
      this.$openDialog({
        title: "填写说明",
        template: `<div v-html="dsf.safe.xss(params.value)"></div>`,
        width: "30vw",
        height: "50vh",
        params: {
          value: this.dataCapion
        }
      });
    },
    getLabelWidth(isToRem) {
      return isToRem ? this.pxToRem(this.$labelWidth + "px") : this.$labelWidth + "px";
    },
    getLabelWidthStyle(isToRem) {
      if (!isToRem) {
        if (this.$labelWidth && this.showLabel) {
          return {
            width: this.$labelWidth + "px"
          };
        }
        return {};
      } else {
        if (this.$labelWidth && this.showLabel) {
          return {
            width: this.pxToRem(this.$labelWidth + "px")
          };
        }
        return {};
      }
    },
    getFormItemBlockMarginLeft() {
      if (this.$labelWidth && this.showLabel) {
        return {
          "margin-left": this.$labelWidth + "px"
        };
      }
      return {};
    },
    getShowContent() {
      if (dsf.type(this.value) == "object") {
        return this.value[this.readOnlyShowText];
      } else if (dsf.type(this.value) == "array") {
        return _.map(this.value, this.readOnlyShowText).join(",");
      } else {
        return this.value;
      }
    },
    $emitValueChange(v) {
      v = this.$filterInValidMetaDataValueAttrs(v);
      //让组件既支持v-model绑定值又支持:value.sync绑定
      let listeners = this.$listeners || {};
      //表示v-model绑定
      if (listeners[VALUE_CHANGE]) {
        this.$emit(VALUE_CHANGE, v);
      }
      //表示value.sync绑定
      else if (listeners[VALUE_UPDATE_SYNC]) {
        this.$emit(VALUE_UPDATE_SYNC, v);
      }
    },
    async emitFormValidate(v, i) {
      //判断如果是表单或者在列表中
      if (this.$form) {
        let localIndex = null;
        if (this.$list) {
          localIndex = this.local.$index;
        }
        return await this.$vm.validateField(this.value, (dsf.isDef(i) ? i : localIndex), this);
      }
      return [];
    },
    emitValueChange(v) {
      this.$emitValueChange(v);
      this.$dispatch("value-change", v);
    },
    $dispatch(name, args) {
      control.methods.$dispatch.call(this, name, args);
      //如果数据在列表
      if (!this.isDesign && !this.$isPrototype) {
        if (name == this.gridRowChangeKey && this.$list && this.local?.$hash) {
          if (this.metaDataLevel != 1 && this.$list.isRowDataChangePost) {
            this.$nextTick(() => {
              this.$vm.removeErrorsByRowHash(this.local?.$hash);
              this.emitGridRowChange();
            });
          }
        }
      }
    },
    emitGridRowChange() {
      triggerDataGridRowChange(this);
    },
    //过滤值中的非法元数据
    $filterInValidMetaDataValueAttrs(v) {
      let vas = _.map(this?.metadata?.valueAttributes || [], (it) => {
        return it.code;
      });
      if (vas.length > 1) {
        if (dsf.isObject(v)) {
          for (let k in v) {
            if (!~vas.indexOf(k)) {
              delete v[k];
            }
          }
        } else if (dsf.isArray(v)) {
          _.each(v, (it) => {
            this.$filterInValidMetaDataValueAttrs(it);
          });
        }
      }
      return v;
    }
  },
  watch: {
    caption: {
      handler(v, ov) {
        //如果表单组件caption变化后，元数据必须重新校验命名规则
        if (this.isDesign && this.metadata) {
          this.metadata._isValidate = true;
        }
      }
    }
  },
  design: {
    infoButton: true,
    settingButton: true,
    settingMenus: [
      {
        text: "校验规则",
        command: "validate-rules",
        handler() {
          dsf.designer.triggerDialogPropty('metadata.validate')
        },
        hidden() {
          return this.isOneToMany;
        }
      },
      {
        text: "默认值",
        command: "default-value",
        handler() {
          dsf.designer.triggerDialogPropty('metadata.defaultValue')
        },
        hidden() {
          return this.isOneToMany;
        }
      },
      {
        text: "静态数据维护",
        command: "static-editor",
        handler: staticEditor,
        hidden() {
          return !this.$isPrototype;
        }
      },
    ],
    showCaption: true,
    settingButtonHandler: null,
    layout: {
      exclude: ["value"]
    },
    metadata: {
      include: null,
      exclude: [
        'ctrlType',
        'ctrlName',
        'value',
        'items',
        'readOnly',
        'metadata',
        'metadata_fullcode',
        'textProxy',
        'disabled',
        'validateRule',
        'labelWidth',
        'metaDataLevel',
        'visible',
        'noPadding',
        'slots',
        'tbNoPadding',
        'lrNoPadding',
        'local',
        'development',
        'designProxy',
        'designOnlySlot',
        'designId',
        'isDesign',
        'classList',
        'showLabel',
        'visible',
        'staticData'
      ],
      create(root) {
        if (!this.metadata) {
          this.metadata = {};
        }
        this.metadata.hidden = !this.visible;
        this.metadata.required = this.required;
        let mixins = dsf.getComponentMixins(this.$options);
        let designMetaDataNode = this?.$options?.design?.metadata;
        let excludeArr = [];
        let includeArr = [];
        if (designMetaDataNode.include) {
          includeArr.push(...designMetaDataNode.include)
        }
        if (designMetaDataNode.exclude) {
          excludeArr.push(...designMetaDataNode.exclude)
        }
        _.each(mixins, (it) => {
          let arr = it?.design?.metadata?.exclude;
          if (arr && arr.length > 0) {
            excludeArr = arr.concat(excludeArr);
          }
          let arr2 = it?.design?.metadata?.include;
          if (arr2 && arr2.length > 0) {
            includeArr = arr2.concat(includeArr);
          }
        })
        includeArr = _.uniq(includeArr);
        excludeArr = _.uniq(excludeArr);
        let attrs = {};
        let props = Object.keys(this.$props);
        _.each(props || [], (it) => {
          if (dsf.hasOwn(this.$props, it)) {
            attrs[it] = this.$props[it];
          }
        });
        _.each(excludeArr || [], (it) => {
          if (includeArr.indexOf(it) < 0) {
            delete attrs[it];
          }
        });
        this.metadata.content = !dsf.isEmptyObject(attrs) ? JSON.stringify(attrs) : "{}";
        return {
          self: this,
          init: null,
          metadata: this.metadata
        };
      }
    },
    dataPropKey: "value",
    staticDataPropKey: "staticValue",
  }
};

function updateElAttr() {
  if (this?.$el?.nodeType == 1) {
    this.$el.setAttribute("form-name", this.$formName);
  }
}

function metadataValidateConfigToRules() {
  if (this?.metadata?.validate) {
    let config = this.metadata.validate.config;
    let rules = this.metadata.validate.rules;
    let nowVersion = 1
    let forcibly = false;
    if (this.metadata.validate.version && this.metadata.validate.version >= nowVersion) {
      return
    } else {
      this.metadata.validate.version = nowVersion
      forcibly = true;
    }
    if (config && (!rules || rules.length <= 0)) {
      let rules = [];
      for (let k in config) {
        let cig = config[k];
        if (cig.required) {
          let rule = {
            attr: k
          };
          rule.type = "required";
          rule.minLength = "";
          rule.maxLength = "";
          rule.numberMax = "";
          rule.numberMin = "";
          rule.precision = "";
          rule.arrayMin = "";
          rule.arrayMax = "";
          rule.express = "";
          rule.expressName = "";
          rule.errorMsg = cig.errorMsg["required"];
          rules.push(rule);
        }
        if (cig.express) {
          let rule = {
            attr: k
          };
          rule.type = "express";
          rule.minLength = "";
          rule.maxLength = "";
          rule.numberMax = "";
          rule.numberMin = "";
          rule.precision = "";
          rule.arrayMin = "";
          rule.arrayMax = "";
          rule.express = cig.express;
          rule.expressName = cig.expressName?.[0] || '';
          rule.errorMsg = cig.errorMsg['express'];
          rules.push(rule);
          continue;
        }
        if (cig.isNumber) {
          let rule = {
            attr: k
          };
          rule.type = "number";
          rule.minLength = "";
          rule.maxLength = "";
          rule.numberMax = "";
          rule.numberMin = "";
          rule.precision = "";
          rule.arrayMin = "";
          rule.arrayMax = "";
          rule.express = "";
          rule.expressName = "";
          rule.errorMsg = cig.errorMsg["isNumber"];
          rules.push(rule);
          let arr = ["precision", "numberMax", "numberMin"];
          _.each(arr, (it) => {
            if (cig[it]) {
              let r = {
                attr: k
              };
              r.type = "number";
              r.minLength = "";
              r.maxLength = "";
              r.numberMax = ""; //cig.numberMax;
              r.numberMin = ""; //cig.numberMin;
              r.precision = ""; //cig.precision;
              r[it] = cig[it];
              r.arrayMin = "";
              r.arrayMax = "";
              r.express = "";
              r.expressName = "";
              r.errorMsg = cig.errorMsg[it];
              rules.push(r);
            }
          });
        } else {
          let arr = ["min", "max"];
          _.each(arr, (it) => {
            let r = {
              attr: k
            };
            r.type = "string";
            r.minLength = it == "min" ? cig[it] : "";
            r.maxLength = it == "max" ? cig[it] : "";
            r.numberMax = "";
            r.numberMin = "";
            r.precision = "";
            r.arrayMin = "";
            r.arrayMax = "";
            r.express = "";
            r.expressName = "";
            r.errorMsg = cig.errorMsg[it];
            rules.push(r);
          });
        }
      }
      delete this.metadata.validate.config;
      this.metadata.validate.rules = rules;
    } else if (forcibly && rules && rules.length) {
      rules.forEach(rule => {
        if (rule.type == "express" && dsf.type(rule.errorMsg) != 'string') {
          rule.errorMsg = rule.errorMsg[rule.type]
        }
        if (dsf.type(rule.expressName) == 'array' && rule.expressName.length) {
          rule.expressName = rule.expressName[0]
        }
      });
    }
  }
}

function staticEditor() {
  let _this = this;
  this.$openDialog({
    title: "数据维护",
    content: "DesStaticDataFormItem",
    width: "70vw",
    height: "60vh",
    // overflow: "hidden hidden",
    params: {
      designer: this.$designer,
      value: _this.staticValue || [],
      owner: this
    },
    btns: [
      {
        text: "确定",
        handler(res) {
          let data = res?.yes();
          if (_this.caption && data) {
            let vas = _this.metadata.valueAttributes;
            if (vas.length > 0) {
              if (vas.length == 1) {
                let va = vas[0]
                let value = data[va.code];
                _this.staticValue = value
              }
              else {
                _this.staticValue = data
              }
            }
          }
        }
      },
      {
        text: "清除",
        handler() {
          _this.staticValue = null;
        }
      },
      {
        text: "取消"
      }
    ]
  });
}


var pool = {}
function pushUFUNC() {
  //将公式计算提交到vm的计算属性上
  if (!this.isDesign && this?.$vm && this?.formula?.formula?.trim?.()) {
    let computed = dsf.ufunc(this.formula);
    let { expression, callback, value } = this?.$vnode?.data?.model || {};
    if (expression) {
      this.formulaExpression = {
        immediate: this.formulaImmediate,
        self: this,
        // scope: this.local,
        model: {
          callback,
          expression
        },
        computed
      }
      this.$vm.$pushUFUNC(this.$designId, this.formulaExpression);
    }

  }
}
function $removeUFUNC() {
  if (!this.isDesign && this.$vm) {
    this.$vm.$removeUFUNC(this.$designId, this.formulaExpression);
  }
}
