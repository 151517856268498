
import enums from '../utils/enums'
export default {
  // inheritAttrs:false,
  inject: {
    $designer: {
      default: null
    },
    $app: {
      default: null
    },
    $vm: {
      default: null
    },
    $page: {
      default: null
    },
    $form: {
      default: null
    },
    $dialog: {
      default: null
    },
    $list: {
      default: null
    },
    $sublistContent: {
      default: null
    },
    $dynamicForm: {
      default: null
    },
    $layoutTree: {
      default: null
    },
    $isMobile: {
      default: false
    },
    $homePanel: {
      default: null
    },
  },
  comments: true,
  props: {
    isDsf: {
      type: Boolean,
      default: true
    },
    //是否由业务组件创建
    isBusiness: {
      type: Boolean,
      default: false
    },
    //是否是设计模式
    isDesign: {
      type: Boolean,
      default: false
    },
    //设计模式产生的id
    designId: {
      type: String,
      default: ""
    },
    //组件唯一名称
    caption: {
      type: String,
      default: ""
    },
    //组件是否可见
    visible: {
      type: Boolean,
      default: true
    },
    //强制隐藏 visable对其无效
    forceHidden: {
      type: Boolean,
      default: false
    },
    //组件元数据
    metadata: {
      type: Object,
      default() {
        return null;
      }
    },
    //组件样式列表
    classList: {
      type: Array,
      default() {
        return [];
      }
    },
    noPadding: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //组件插槽
    slots: {
      type: Array,
      default() {
        return [];
      }
    },
    isMergeHttp: {
      type: Boolean,
      default: false
    },
    //取消上下边距
    tbNoPadding: {
      type: Boolean,
      default: false
    },
    //取消左右边距
    lrNoPadding: {
      type: Boolean,
      default: false
    },
    //所在作用域的数据
    local: {
      type: Object,
      default() {
        return null;
      }
    },
    //静态值，用户原型设计
    staticValue: {
      type: [String, Boolean, Number, Date, Array, Object],
      default() {
        return null
      }
    },
    // staticValueConver:{
    //   type:Function,
    //   default(){
    //     return ()=>{

    //     }
    //   }
    // },
    // 背景图片/背景色
    backgroundConfig: {
      type: Object,
      default: null
    },
    //是否是一个块级组件，块级组件可以被流程或者状态控制显示隐藏
    isBlockControl: {
      type: Boolean,
      default: false
    },
    //是否在子表或者列表模板中
    // inSubTemplate: {
    //   type: Boolean,
    //   default: false
    // },
    //二次开发配置
    development: {
      type: Object,
      default() {
        return {
          // events: {},
          attrs: {}
        };
      }
    },
    //定位锚点
    anchorKey: {
      type: String,
      default: ""
    },
    /* ==================设计状态下,相关配置属性 ==================*/
    //设计器可否对他进行编辑
    designProxy: {
      type: Boolean,
      default: false
    },
    //设计模式下当前组件仅仅起到一个插槽的作用，不会将子组件存放到该组件下
    designOnlySlot: {
      type: Boolean,
      default: false
    },
    //允许设计器感应插槽
    designInductionSlot: {
      type: [Function, Array, Boolean],
      default() {
        return true
      }
    },
    /* ==================设计状态下,相关配置属性 ================== */
  },
  computed: {
    getCss() {
      return this.$getClass();
    },
    getBackgroundStyle() {
      return this.$colorFormat(this.backgroundConfig);
    },
    props() {
      let _vm = this.$vm;
      if (_vm && !this.isDesign) {
        let key = "";
        if (this.designId) {
          key = this.$designId;
          return _vm.$controls[key];
        }
        return null;
      }
    },
    $designId() {
      return this.designId + (this.$dataHash ? "-" + this.$dataHash : '');
    },
    queryString() {
      if (this.$vm) {
        return this?.$vm?.queryString || {};
      }
      else {
        return this?.$route?.query || {};
      }
    },
    $dataHash() {
      if (this.local && dsf.hasOwn(this.local, "$hash")) {
        return this.local["$hash"];
      }
      return null;
    },
    $servicePrefix() {
      let servicePrefix = this?.$vm?.$baseUrl || "";
      return servicePrefix;
    },
    $isPrototype() {
      return this?.$page?.mode == enums.viewMode.PROTOTYPE;
    },
    $isUseStaticData() {
      return this.$isPrototype && this.staticValue;
    }
  },
  data() {
    return {};
  },
  beforeCreate() {
  },
  created() {
    // 设计模式下,代理所有组件的props,使props可以通过组件自生改变
    if (this.isDesign) {
      if (this.$props) {
        for (let k in this.$props) {
          propsProxy.call(this, this.$props, k);
        }
      }
    }
    this.$dispatch("created", this);
  },
  mounted() {
    updateElAttrs.call(this)
    this.$dispatch("mounted", this);
  },
  updated() {
    updateElAttrs.call(this)
    this.$dispatch("updated", this);
  },
  beforeDestroy() {
    if (!this.isDesign && isListRepeat(this)) {
      clearCloneComponet.call(this, this.local.$hash);
    }
    this.$dispatch("before-destroy", this);
  },
  destroyed() {
    if (this.viewContext && this.viewContext.viewData) {
      this.viewContext.viewData = null;
    }
    if (this.viewContext && this.viewContext.viewInitData) {
      this.viewContext.viewInitData = null;
    }
    this.$dispatch("destroy", this);
  },
  methods: {
    $addCustom(caption, props) {
      this?.$vm?.$addCustom?.(caption, props);
    },
    $removeCustom(caption) {
      this?.$vm?.$removeCustom?.(caption);
    },
    $clearCustom(caption) {
      this?.$vm?.$clearCustom?.(caption);
    },
    //构建元数据
    // createMetaData() {},
    //事件触发
    $dispatch(name, args) {
      let event = { target: this, name: name, args: args };
      this.$emit(name, event);
    },
    $dispatchTo(componentName, name, args) {
      let event = { target: this, name: name, args: args };
      eventTrigger.dispatch.call(this, componentName, name, event);
    },
    removeClass(keys) {
      keys = dsf.type(keys) == "array" ? keys : [keys];
      _.each(keys, (k) => dsf.array.remove(this.classList, k));
    },
    addClass(keys) {
      keys = dsf.type(keys) == "array" ? keys : [keys];
      _.each(keys, (k) => dsf.array.ensure(this.classList, k));
    },
    clearClass() {
      _.eachRight(this.classList, (k, i) => {
        dsf.array.removeAt(this.classList, i);
      });
    },
    closest(componentName) {
      var parent = this.$parent || this.$root;
      var name = parent.$options._componentTag;
      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;
        if (parent) {
          name = parent.$options._componentTag;
        }
      }
      if (parent) {
        return parent;
      }
      return null;
    },
    postBefore() {
      return new Promise((resolve, reject) => {
        resolve();
      });
    },
    $getClass() {
      return [
        ...this.classList,
        !this.visible ? (this.isDesign ? "ds-design-hide" : "ds-hide") : "",
        this.tbNoPadding ? "ds-top-bottom-no-padding" : "",
        this.lrNoPadding ? "ds-left-right-no-padding" : "",
        this.forceHidden ? (this.isDesign ? "ds-design-hide" : "ds-hide") : ""
      ];
    },
    $getSlot(name) {
      let result = _.filter(this.slots, (s) => s.name == name);
      return result[0] ? result[0] : null;
    },
    $childrenReloadData() {
      _.each(this.$children, (children) => {
        children?.reloadData && children.reloadData();
      });
    },
    $colorFormat(opt) {
      if (!opt) {
        return {};
      }
      // 以前的写法
      if (!opt.active) {
        let { color, image, size, repeat, position } = opt;
        image = this.$replace(image, this.local);
        return {
          'background-color': color,
          'background-image': `url('${dsf.url.getWebPath(image)}')`,
          'background-size': size,
          'background-repeat': repeat,
          'background-position': position,
        }
      } else {
        let { active, color, pattern, linear, radial } = opt;
        switch (active) {
        case 'color':
          let {isTheme, theme, value} = color;
          return {
            'background-color': isTheme ? dsf.themeConfig.currentTheme[theme] : value
          };
        case 'pattern':
          return {
            'background-image': `url('${dsf.url.getWebPath(pattern.image)}')`,
            'background-size': pattern.size,
            'background-repeat': pattern.repeat,
            'background-position': pattern.position,
          };
        case 'linear':
          let str1 = linear.point.map(({length, color}) => `${color} ${length}%`).join(',');
          return {
            'background': `linear-gradient(${linear.angle}deg, ${str1})`
          };
        case 'radial':
          let str2 = radial.point.map(({length, color}) => `${color} ${length}%`).join(',');
          let position = radial.position.map(l => l + '%').join(' ');
          return {
            'background': `radial-gradient(${radial.shape} ${radial.size} at ${position}, ${str2})`
          };
        }
      }
    },
    // 获取组件实例
    $getCaptionCtrl() {
      let node = this;
      while (!node.caption && node.$parent) {
        node = node.$parent;
      }
      if (node.caption) {
        return node;
      }
    }
  },
  watch: {
    $dataHash: {
      handler(v, ov) {
        if (v) {
          //如果组件是子表、数据列表中，克隆一份配置的数据到$controls;
          //当列表数据刷新_id相等时由于组件不会销毁且$hash产生变化时调用clearCloneComponet清理掉老数据
          if (!this.isDesign && this.designId && isListRepeat(this)) {
            if (ov) {
              clearCloneComponet.call(this, ov);
            }
            cloneComponent.call(this);
          }
        }
      },
      immediate: true
    }
  },
  design: {
    dataPropKey: "value",
    staticDataPropKey: "staticValue",
    //设计器是是否在组件上产生遮罩
    isMask: true,
    //是否显示右上角的code
    showCaption: false,
    //设计器是是否在组件左上角是否有查看按钮
    infoButton: false,
    //设计器是否在组件左上角显示设置按钮
    dragButton: true,
    //设计器是是否在组件左上角是否有移除按钮
    removeButton: true,
    //设计器是是否在组件左上角是否有设置
    settingButton: false,
    settingMenus: undefined,
    settingButtonHandler: null,
    //组件加载到设计器上时组件高度默认在父容器铺满
    fit: false,
    layout: {
      isDeep: true,
      exclude: [],
      //保存布局时不做命名验证
      nameValidate: true,
      //创建布局时可以将作用域数据传入
      accpetContextData: false,
      attachAttributes: {},
      attachSlot: []
    },
    metadata: {
      isDeep: true,
      create() { }
    }
  }
};

function updateElAttrs() {
  if (this?.$el?.nodeType == 1) {
    if (this.$options.ctrlType) {
      this.$el.setAttribute("ctrl_type", this.$options.ctrlType);
    }

    if (!this.isDesign) {
      if (this.anchorKey) {
        this.$el.setAttribute("anchor-key", this.anchorKey);
      }
      else {
        this.$el.removeAttribute("anchor-key")
      }
    }
  }
  if (this.isDesign && this?.$el?.nodeType == 1 && this.$designId) {
    this.$el.setAttribute("des-id", this.$designId);
  }
}

function cloneComponent() {
  let key = this.$designId;// + "-" + this.local.$hash;
  if (this.$vm) {
    // if (!this.$vm.$controls[key]) {
    //   let props = dsf.mix(true, {}, this.$vm.$controls[this.designId]);
    //   if (this.$list && this.$list.updateSubControls) {
    //     let ctrlProps = this.$list.updateSubControls(props, this);
    //     if (ctrlProps) {
    //       props = ctrlProps;
    //     }
    //   }
    //   this.$vm.$set(this.$vm.$controls, key, props);
    // }
    let props = this.$vm.$controls[key];
    if (!props) {
      props = dsf.mix(true, {}, this.$vm.$controls[this.designId]);
      this.$vm.$set(this.$vm.$controls, key, props);
    }
    if (this.$list && this.$list.updateSubControls) {
      let ctrlProps = this.$list.updateSubControls(props, this);
      if (ctrlProps) {
        props = ctrlProps;
      }
      this.$vm.$set(this.$vm.$controls, key, props);
    }
    // this.$vm.$set(this.$vm.$controls, key, props);
    if (!this.$vm.$controlsRef) {
      this.$vm.$controlsRef = {};
    }
    if (!this.$vm.$controlsRef[key]) {
      this.$vm.$controlsRef[key] = [];
    }
    this.$vm.$controlsRef[key].push(this);
  }

}

function clearCloneComponet(hash) {
  let key = this.designId + "-" + (hash || this.local.$hash);
  if (this.$vm && this.$vm.$controlsRef) {
    dsf.array.remove(this.$vm.$controlsRef[key], this);
    if (this.$vm.$controlsRef[key].length <= 0) {
      this.$vm.$delete(this.$vm.$controls, key);
      delete this.$vm.$controlsRef[key];
    }
  }
  if (this.$list && this.$list.removeSubControls) {
    this.$list.removeSubControls(this, hash);
  }
}

function isListRepeat(ctrl) {
  if (ctrl.$vm && ctrl.local && dsf.hasOwn(ctrl.local, "$hash") && ctrl.designId) {
    return true;
  }
  return false;
}

function propsProxy(props, key) {
  let _this = this;
  Object.defineProperty(this, key, {
    set: function (v) {
      _this.$emit("update:" + key, v);
    },
    get: function () {
      return _this.$props[key];
    }
  });
}

// eslint-disable-next-line no-unused-vars
let eventTrigger = {
  broadcast(componentName, eventName, params) {
    this.$children.forEach((child) => {
      var name = child.$options._componentTag;

      if (name === componentName) {
        child.$emit.apply(child, [eventName].concat(params));
      } else {
        broadcast.apply(child, [componentName, eventName].concat([params]));
      }
    });
  },
  dispatch(componentName, eventName, params) {
    var parent = this.$parent || this.$root;
    var name = parent.$options._componentTag;
    while (parent && (!name || name !== componentName)) {
      parent = parent.$parent;

      if (parent) {
        name = parent.$options._componentTag;
      }
    }
    if (parent) {
      parent.$emit.apply(parent, [eventName].concat(params));
    }
  }
};
