export default {
  getSystem(key) {
    let system_global = dsf.cookies.get('system_global');
    if (!system_global) {
      return key ? undefined : {};
    }
    system_global = JSON.parse(system_global);
    if (key) {
      return system_global[key];
    }
    return system_global;
  },
  addSystem(key, value) {
    let system_global = this.getSystem();
    system_global[key] = value;
    let content = JSON.stringify(system_global);
    dsf.http.post('/custom/add', {
      namespace: 'system',
      pageName: 'global',
      content
    }).done((success) => {
      if (success) {
        dsf.cookies.set('system_global', content);
      }
    }).catch((ex) => {
      dsf.httpError(ex, { isSilent: true });
    })
  },
  add(vm, caption, props) {
    let nameSpace = vm.nameSpace;
    let pageName = vm.pageName;
    let custom = vm?.$viewInitData?._custom;
    custom = custom || {};
    let newInfo = null;
    if (custom[caption]) {
      dsf.mix(custom[caption], props)
    }
    else {
      dsf.mix(custom, {
        [caption]: props
      });
    }
    dsf.http.post('/custom/add', {
      namespace: nameSpace,
      pageName: pageName,
      content: JSON.stringify(custom)
    }).catch((ex) => {
      dsf.httpError(ex, { isSilent: true });
    })
  },
  remove(vm, caption, propNames) {
    let nameSpace = vm.nameScope;
    let pageName = vm.pageName;
    let custom = vm?.$viewInitData?._custom;
    custom = custom || {};
    if (propName && custom[caption]) {
      for (let k in propNames) {
        if (custom[caption]) {
          delete custom[caption][k]
        }
      }
      if (dsf.isEmptyObject(custom[caption])) {
        delete customer[caption]
      }
    }
    else {
      delete customer[caption]
    }

    dsf.http.post('/custom/add', {
      namespace: nameSpace,
      pageName: pageName,
      content: JSON.stringify(custom)
    }).catch((ex) => {
      dsf.httpError(ex, { isSilent: true });
    })
  },
  clear(vm, caption) {
    let nameSpace = vm.nameSpace;
    let pageName = vm.pageName;
    let custom = vm?.$viewInitData?._custom;
    custom = custom || {};
    delete custom[caption]
    dsf.http.post('/custom/add', {
      namespace: nameSpace,
      pageName: pageName,
      content: ""
    }).catch((ex) => {
      dsf.httpError(ex, { isSilent: true });
    })
  }
}