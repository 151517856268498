import control from "./control";

export default {
  mixins: [control],
  props:{
    //是否是文字类组件
    isTextContent:{
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    defaultValue: {
      type: [String, Number],
      default: ''
    },
    // 提示语
    dataCapion: {
      type: String,
      default: ''
    },
    // 提示语展触发元素
    dataCapionTarget: {
      type: String,
      default: 'icon'
    },
    // 提示语展示方式
    dataCapionType: {
      type: String,
      default: 'hoverPop'
    }
  },
  design: {
    //设计器是否在组件上产生遮罩
    isMask: false
  },
  data () {
    return {
      $value: '文本'
    }
  },
  watch: {
    'value' (to) {
      if (!to && this.defaultValue) {
        // todo Express表达式暂未实现
        // let exp = dsf.Express;
        // this.$value = exp.replace(this.defaultValue.text);
        this.$value = this.defaultValue;
      }
    }
  },
  methods: {
    showHelper() {
      this.$openDialog({
        title: "详细描述",
        template: `<div v-html="params.value"></div>`,
        width: "30vw",
        height: "50vh",
        params: {
          value: this.dataCapion
        }
      });
    },
  }
};
