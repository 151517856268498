const mime={
  "wps":"application/kswps",
  "et":"application/kset,application/ocelet-stream",
  "dps":"application/ksdps",
  "zip":"application/x-zip-compressed",
  "7z":"application/x7zcompressed",
  "gz":"application/x-gzip",
  "rar":"application/octet-stream",
  "bmp":"image/bmp",
  "jpeg":"image/jpeg",
  "jpg":"image/jpeg",
  "png":"image/png",
  "gif":"image/gif",
  "avi":"video/avi,video/msvideo,video/x-msvideo,application/x-troff-msvideo",
  "qt":"video/quicktime",
  "mov":"video/quicktime",
  "mp4":"video/mp4",
  "rmvb":"audio/x-pn-realaudio",
  "asf":"video/x-ms-asf",
  "rm":"application/vnd.rn-realmedia,audio/x-pn-realaudio",
  "mpeg":"video/mpeg",
  "mp3":"audio/mpeg3,audio/x-mpeg-3,video/mpeg,video/x-mpeg",
  "wav":"audio/wav,audio/x-wav",
  "midi":"application/x-midi,audio/midi,audio/x-mid,audio/x-midi,music/crescendo,x-music/x-midi",
  "xls":"application/vnd.ms-excel",
  "xlsx":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "doc":"application/msword",
  "docx":"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "ppt":"application/vnd.ms-powerpoint",
  "pptx":"application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "pdf":"application/pdf"
}

export default mime;